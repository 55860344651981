import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/services/api.service';
import { EventProviderService } from 'src/services/event-provider.service';
import { IdService } from 'src/services/id.service';
import { SlugService } from 'src/services/slug.service';

@Component({
  selector: 'app-general-search',
  templateUrl: './general-search.component.html',
  styleUrls: ['./general-search.component.scss'],
})
export class GeneralSearchComponent implements OnInit {
  searchData: any=[];
  isSearch: boolean = false;
  is_wordpress_enable: boolean;
  appPages: any;
  pagination:any;
  searchingword:any;
  ch_id: any;
  constructor(public modalController: ModalController, private router: Router,
    public idservice: IdService, public apicallService: ApiService, public slug: SlugService,
    public location: Location, public event_provider: EventProviderService) { }

  ngOnInit() {
    this.event_provider.getChurchData.subscribe(async (res: any) => {
      if ((Object.keys(res).length === 0)) {
        return;
      }
      this.appPages = res.apppages;
      this.ch_id = res.church.ch_id;
      if (res.is_wordpress_enable === '1') {
        this.is_wordpress_enable = true;

      } else if (res.is_wordpress_enable === '2') {
        this.is_wordpress_enable = false;
      }
    })

  }


  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }


  searchContent(event) {
    if (event.detail.value.length > 1) {
      this.pagination = 1;
      this.searchingword = event.detail.value
      let body = JSON.stringify({
        "search": event.detail.value,
        "church_id": this.ch_id,
        "page_no": "1"
      });
      this.apicallService.searchingWords(body).subscribe((res: any) => {
        if(res.status == true){
          this.searchData = res.search_data
        }
        this.isSearch = true;
      })

    } else {
      this.isSearch = false;
      this.searchData = []
    }
  }



  openSearch(data) {
    var page_id = this.appPages.findIndex(x => x.name.toLowerCase() === data.pageName.toLowerCase())
    if (data.pageName == "Custom Post") {
      var detail = {
        ch: this.ch_id,
        id: data.id,
        page_id: this.appPages[page_id].id,
        lc: this.is_wordpress_enable

      }
      this.slug.setCustomPost(detail)
      // this.router.navigate(['custom-post-detail']);
      this.slug.setSlugName("custom-post-detail", data.slug)
      this.router.navigate(['custom-post-detail'], { skipLocationChange: true, replaceUrl: true });
      this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([data.slug])));
      this.dismiss()

    }
    else if (data.pageName == "Events") {
      var eventDetail = {
        event_id: data.id,
        mode: "dashboard",
        page_id: this.appPages[page_id].id,
      }
      this.slug.setEventDetail(eventDetail);
      this.slug.setSlugName("event-detail", data.slug)
      this.router.navigate(['event-detail'], { skipLocationChange: true, replaceUrl: true });
      this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([data.slug])));
      this.dismiss()
    }
    else if (data.pageName === "Sermons") {
      var id = {
        id: data.id
      }
      this.slug.setSermonDetail(id)
      this.slug.setSlugName("sermon-detail", data.slug)
      this.router.navigate(['sermon-detail'], { skipLocationChange: true, replaceUrl: true });
      this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([data.slug])));
      this.dismiss()
    }
    else if(data.pageName ===  "Sermon Notes"){
    //  sermon notes paused  

      // if(!data.url){
      // var details={
      //     id: data.id,
      //     local: true,
      //     ch: data.church_id
      // }
      // this.slug.setSermonNotes(details)
      // // this.router.navigate(['sermon-notes-detail']);
      // this.slug.setSlugName( "sermon-notes-detail" , data.slug )
      // this.router.navigate(['sermon-notes-detail'], { skipLocationChange: true, replaceUrl: true });
      // this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([data.slug])));
      // this.dismiss()
      // }
      // else{
      //    var detailsData={
      //     title: data.title,
      //     pubDate: data.date,
      //     url: data.url,
      //     page_id: data.id,
      // }
      // this.slug.setSermonNotes(detailsData)
      // // this.router.navigate(['sermon-notes-detail']);
      // this.slug.setSlugName( "sermon-notes-detail" , data.slug )
      // this.router.navigate(['sermon-notes-detail'], { skipLocationChange: true, replaceUrl: true });
      // this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([data.slug])));
      // this.dismiss()
      // }
    }

  }

  doMoreData(event) {
    this.pagination = this. pagination + 1 ;  
    let body = JSON.stringify({
      "search": this.searchingword,
      "church_id": this.ch_id,
      "page_no": this.pagination
    });
    this.apicallService.searchingWords(body).subscribe((res: any) => {
      event.target.complete();
      this.searchData = this.searchData.concat(res.search_data)
      this.isSearch = true;
    })
  }

}
