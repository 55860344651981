import {Component, OnInit } from '@angular/core';
import {IdService} from '../../services/id.service';
import {ActivatedRoute, NavigationEnd, NavigationExtras, Router, RoutesRecognized} from '@angular/router';
import {LoaderService} from '../../services/loader.service';
import {Storage} from '@ionic/storage';
import {SermonService} from '../../services/sermon.service';
import {EventsService} from '../../services/events.service';
import {ApiService} from '../../services/api.service';
import {ToastService} from '../../services/toast.service';
import {Platform} from '@ionic/angular';
import * as moment from 'moment';
import 'moment-timezone';
import { CommonService } from '../../services/common.service';
import {conditionallyCreateMapObjectLiteral} from '@angular/compiler/src/render3/view/util';
import { AnalyticsService } from "../../services/analytics.service";
import { EventProviderService } from "../../services/event-provider.service";
import { Device } from "@ionic-native/device/ngx";
import { InAppBrowser, InAppBrowserOptions } from "@ionic-native/in-app-browser/ngx";
import { TranslateService } from "@ngx-translate/core";
import {
    DomSanitizer,
    SafeStyle
} from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import {Location} from "@angular/common";
import {environment} from "../../environments/environment.prod";
import { SlugService } from 'src/services/slug.service';
import { time } from 'console';

@Component({
    selector: 'app-home',
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss'],
})

export class HomePage implements OnInit {
    userLoggedIn: boolean = false;
    latest_sermon: any;
    sermon_img: any = '../../assets/img/sermon_bg.png';
    ch_id: any;
    liveStreamData: any;
    timeLeft: any;
    user: any;
    theme: any;
    app_pages: any;
    sermons: boolean = false;
    sermons_url: any;
    home_cards: any = [];
    home_page: any;
    social_links: any;
    side_menu_data: any;
    start_time_analytics: any;
    page_name: any;
    custom_name: any;
    home_tabs: any;
    upload_video: any;
    alertMessage: any;
    path_origin: any = '';
    cardFullWidth: boolean = false;
    banner_attachment: any;
    banner_title: any;
    grad_color: any;
    opacity_color: any;
    banner_description: any;
    is_video: boolean = false;
    options : InAppBrowserOptions = {
        closebuttoncaption : 'Close',
        closebuttoncolor: '#FFFFFF',
        disallowoverscroll: 'no',
        hidenavigationbuttons: 'no',
        toolbar: 'yes',
        toolbarposition: 'top',
        location: 'yes'
    };
    single_color: any = '#0000ff';
    gradient_color_one: any = '#b4ff7b';
    gradient_color_two: any = '#b4ff7b';

    constructor(
        private platform: Platform,
        private device: Device,
        private idService: IdService,
        private route: ActivatedRoute,
        private loader: LoaderService,
        private storage: Storage,
        private sermonsService: SermonService,
        private router: Router,
        private eventsService: EventsService,
        private apiService: ApiService,
        private toast: ToastService,
        private common: CommonService,
        private analytics: AnalyticsService,
        private event_provider: EventProviderService,
        private iab: InAppBrowser,
        private translate: TranslateService,
        private dom: DomSanitizer,
        private metaTagService: Meta,
        private titleService: Title,
        private location: Location,
        public slug: SlugService
    ) {
        // get translated alert message
        let interval = setInterval(() => {
            this.translate.get('ALERTS').subscribe( (res: any) => {
                this.alertMessage = res;
                clearInterval(interval);
            });
        }, 2000);

        this.event_provider.refreshHome.subscribe(value => {              // listening event
            this.getUser();
        });

        let current_url = JSON.parse(localStorage.getItem("url"));
        this.path_origin = current_url.origin;

        router.events
    .pipe(filter(event => event instanceof RoutesRecognized))
        .subscribe((event: NavigationEnd) => {
            if(event.url == '/'){
                this.router.navigate(['/home-tabs/home'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree(['/'])));
            }


        });

    }
    getGradient(color_one, bg_color){
        if(bg_color === ''){
            return this.dom.bypassSecurityTrustStyle(`linear-gradient(to bottom,rgba(255,0,0,0), ${color_one}`);
        } else {
            return `${bg_color}`;
        }
    }

    gotobackPage() {
        this.slug.goback();
    }

    async ngOnInit() {
        // assigning data from URL Params
        this.ch_id = this.slug.ch_id;

        // setting latest church ID
        this.idService.setId(this.ch_id);

        // saving app_pages
        this.event_provider.getChurchData.subscribe(async (res: any) => {
            if (!(Object.keys(res).length === 0)) {
                this.theme = res;
                this.ch_id = this.theme.church.ch_id;
                if (this.theme.church.social_links) {
                    this.social_links = JSON.parse(this.theme.church.social_links);
                    this.cardFullWidth = (this.social_links.length > 0);

                    // to check which tab is active
                    if (this.theme.church.home_tabs){
                        this.home_tabs = JSON.parse(this.theme.church.home_tabs);
                    }

                    // =============== setting data for side social links =====================
                    this.social_links.map(res => {
                        if (res.icon_name === 'logo-facebook' && res.name === "") {
                            res.name = 'Facebook';
                        }
                        if (res.icon_name === 'logo-twitter' && res.name === "") {
                            res.name = 'Twitter';
                        }
                        if (res.icon_name === 'logo-youtube' && res.name === "") {
                            res.name = 'Youtube';
                        }
                        if (res.icon_name === 'logo-linkedin' && res.name === "") {
                            res.name = 'Linkedin';
                        }
                        if (res.icon_name === 'logo-instagram' && res.name === "") {
                            res.name = 'Instagram';
                        }
                    });
                    this.social_links = this.social_links.filter(res => res.icon_name !== 'mail-outline' && res.icon_name !== 'call')

                    // =============== setting data for side social links =====================
                }
                if (res.apppages) {
                    this.common.store_app_pages(res.apppages, 'HOME');

                    // saving sidemenu data
                    let sidemenu = res.apppages.filter(val => val.name === 'Side Menu');
                    if (sidemenu.length > 0) {
                        if (sidemenu[0].side_menu_data) {
                            this.side_menu_data = JSON.parse(sidemenu[0].side_menu_data);
                            if( this.side_menu_data.title || this.side_menu_data.subtitle ){
                                this.cardFullWidth = true;
                            }
                        }
                    }

                    // checking if home page is enabled
                    this.home_page = res.apppages.filter(x => x.page_type === 'home');
                    if(this.home_page[0].show_pwa === "false"){
                        for(let x=0; x<res.apppages.length; x++){
                            if (res.apppages[x].page_type !== "home" && res.apppages[x].name !== "Side Menu") {
                                if (res.apppages[x].activated === "true") {
                                    this.goToPage(res.apppages[x].name, res.apppages[x].id, '', '');
                                    break;
                                }
                            }
                        }
                    }
                    this.home_cards = this.home_page[0].cards;
                    this.home_cards.map( x => {
                        if (x.show_in_app === 'true' && x.show_in_pwa === 'false'){
                            if (this.home_cards.indexOf(x) === 0){
                                x['div_class'] = "show-mobile";
                            } else if (x.card_type === 'quote'){
                                x['div_class'] = "ion-margin-bottom show-mobile";
                            } else if (x.card_type === 'standard' || x.card_type === 'countdown'){
                                x['div_class'] = "ion-no-padding show-mobile";
                            }
                        } else if (x.show_in_app === 'false' && x.show_in_pwa === 'true'){
                            if (this.home_cards.indexOf(x) === 0){
                                x['div_class'] = "hide-mobile";
                            } else if (x.card_type === 'quote'){
                                x['div_class'] = "ion-margin-bottom hide-mobile";
                            } else if (x.card_type === 'standard' || x.card_type === 'countdown'){
                                x['div_class'] = "ion-no-padding hide-mobile";
                            }
                        } else if (x.show_in_app === 'true' && x.show_in_pwa === 'true'){
                            if (this.home_cards.indexOf(x) === 0){
                                x['div_class'] = "";
                            } else if (x.card_type === 'quote'){
                                x['div_class'] = "ion-margin-bottom";
                            } else if (x.card_type === 'standard' || x.card_type === 'countdown'){
                                x['div_class'] = "ion-no-padding";
                            }
                        } else if (x.show_in_app === 'false' && x.show_in_pwa === 'false'){
                            if (this.home_cards.indexOf(x) === 0){
                                x['div_class'] = "ion-hide";
                            } else if (x.card_type === 'quote'){
                                x['div_class'] = "ion-margin-bottom ion-hide";
                            } else if (x.card_type === 'standard' || x.card_type === 'countdown'){
                                x['div_class'] = "ion-no-padding ion-hide";
                            }
                        }
                    });
                    // setting for analytics
                    this.page_name = this.home_page[0].name;
                    this.custom_name = this.home_page[0].title;

                    if (this.home_page.length > 0){
                        this.is_video = this.home_page[0].upload_video === 'true';
                        this.banner_attachment =  this.home_page[0].website_banner;
                        this.banner_title =  this.home_page[0].banner_title;
                        this.banner_description =  this.home_page[0].banner_description;
                        this.grad_color =  this.home_page[0].grad_color_1;
                        this.opacity_color =  this.home_page[0].opacity_color;
                    }

                    // filtering countdown cards
                    let countdown_card = this.home_cards.filter(x => x.card_type === 'countdown');
                    countdown_card.forEach(function (card) {
                        card.count = 0;
                    });

                    // =====================  setting TIMER on countdown Cards and updating every second ==================================
                    setInterval(() => {
                        let countdown_card = this.home_cards.filter(x => x.card_type === 'countdown');

                        countdown_card.map(x => {
                            let temp = [];
                            if (x.time) {
                                for (let i = 0; i < JSON.parse(x.time).length; i++) {
                                    temp.push(this.diff_minutes(JSON.parse(x.time)[i].time, JSON.parse(x.time)[i].timezone));
                                }
                                x.timeleft = temp;
                                x.current_timeline = temp[x.count];
                                if (x.current_timeline) {
                                    
                                    if(x.current_timeline.days > 0){
                                        x.current_timeline.hours = x.current_timeline.hours;
                                    }
                                    if (x.current_timeline.days < 1 && x.current_timeline.hours < 1 && x.current_timeline.minutes < 1 && x.current_timeline.seconds < 1) {
                                        if (x.count < temp.length) {
                                            x.count = x.count + 1;
                                        }
                                    }
                                }
                            }
                        });
                    }, 1000);
                    // =====================  setting TIMER on countdown Cards and updating every second ==================================

                    this.app_pages = res.apppages.filter(x => x.activated === 'true');
                }
                // adding meta tag for facebook share
                this.metaTagService.addTags([
                    {
                    property: 'og:image', content: this.theme.church.logo
                    },
                    {
                        property: 'og:title', content: this.theme.church.church_name
                    }
                    ]
                )
            }
            await this.getUser();

        }, (error: any) => {
        }, () => {
        });
        this.common.getSEO(this.ch_id).subscribe((res: any) => {
            if(res.status){
                this.metaTagService.addTag({name: 'description', content:res.fields.description });
                this.metaTagService.addTag({property: 'og:description', content:res.fields.description });
                this.metaTagService.addTag({property: 'og:title', content:res.fields.title });
                this.titleService.setTitle(res.fields.title);
            }
        })
        
    }

    // calculating time left in Hrs, Mins, Secs
    diff_minutes(date, timezone) {
        let myMoment: moment.Moment = moment(date, "YYYY-MM-DD HH:mm:ss");
        // let myMoment: moment.Moment = moment.tz(date, "YYYY-MM-DD HH:mm:ss", timezone);
        // let ok = moment().format("YYYY-MM-DD HH:mm:ss");
        let ok = moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss");
        var duration = moment.duration(myMoment.diff(ok));
        var total = duration.asMilliseconds();
        var t = total;
        var seconds = Math.floor((t / 1000) % 60);
        var minutes = Math.floor((t / 1000 / 60) % 60);
        var hours = Math.floor(t / (1000 * 60 * 60) % 24);
        var days = Math.floor(t / (1000 * 60 * 60 * 24));
        return {
            'total': t,
            'days': days,
            'hours': hours,
            'minutes': minutes,
            'seconds': seconds
        };
    }

    getUser() {
            // getting user from local storage
            this.storage.get('user').then((val: any) => {
                if (val != null && val != 'guest') {
                    this.userLoggedIn = true;
                    this.user = val;
                }
            });
    }

    // navigating CARDS to Pages
    goToPage(pageName, pageId, event:any, stEvent) {
        if (stEvent && stEvent !== '') {
            stEvent.stopPropagation();
        }
        if (pageName && pageId) {
            if (pageName === 'Audio') {
                pageName = 'audio-archive';
                this.slug.setPage_id("audio-archive",pageId);
                this.slug.setSlugName( "audio-archive" , event.slug );
                this.router.navigate(['audio-archive'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
            } else if (pageName === 'Community') {
                this.slug.setPage_id("community",pageId);
                this.slug.setSlugName( "community" , event.slug);
                this.router.navigate(['/community'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
            }
             else if (pageName === 'Events') {
                this.slug.setPage_id("events",pageId);
                this.slug.setSlugName( "events" , event.slug );
                this.router.navigate(['events'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
            } else if (pageName === 'Video') {
                pageName = 'video-archive';
                this.slug.setPage_id("video-archive",pageId);
                this.slug.setSlugName( "video-archive" , event.slug );
                this.router.navigate(['video-archive'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
            } else if (pageName === 'Home') {
                if (this.ch_id === '1000'){
                    pageName = 'ism-home/' + this.ch_id;
                    this.router.navigate([pageName]);
                } else {
                    pageName = 'home-tabs/home/' + this.ch_id;
                    this.router.navigate([pageName],{ skipLocationChange: true, replaceUrl:false });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                }
            } else if (pageName === 'Feed') {
                if ((this.home_tabs && this.home_tabs[1].status === 'true') || this.ch_id === '1000'){
                    this.router.navigate(['/feed-tab']);
                } else {
                        this.router.navigate(['home-tabs/feed-tab']);
                }
            } else if (pageName === 'Chat') {
                if ((this.home_tabs && this.home_tabs[2].status === 'true') || this.ch_id === '1000'){
                    this.router.navigate(['/chat']);
                } else {
                    this.router.navigate(['home-tabs/chat']);
                }
            } else if (pageName === 'Donations') {
                let link_page = this.app_pages.find(x => x.id === pageId);
                if (link_page) {
                let open_external = link_page.open_external;
                    if (open_external === 'true') {
                        let url = JSON.parse(localStorage.getItem("url"));
                        let donations_url = url.origin + "/give-new/" + this.ch_id;

                        if(url.host.includes("pwabuilder.churchbase.com")){
                            this.slug.setIframe(donations_url);
                            this.slug.setSlugName( "iframeheader" , event.slug);
                            this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
                        } else {
                            this.openurlinbrowser(donations_url);
                        }
                    } else {
                        this.router.navigateByUrl('/my-contribute/'+link_page.html);
                    }
                }
            } else if (pageName === 'Custom Post') {
                this.slug.setPage_id("custom-post",pageId);
                this.slug.setSlugName( "custom-post" , event.slug);
                this.router.navigate(['custom-post/'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
            } else if (pageName === 'Live Stream') {
                this.slug.setCh_Id(this.ch_id);
                this.slug.setSlugName( "live-stream" , event.slug);
                this.router.navigate(['live-stream'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
            } else if (pageName === 'Folder') {
                this.slug.setFolder(pageId);
                this.event_provider.folderPage(pageId);
                this.slug.setSlugName( "folder" , event.slug);
                this.router.navigate(['/folder'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
            } else if (pageName === 'Custom Page') {
                console.log(pageName, pageId, event , "added");
                this.slug.setPage_id("custom-page",pageId);
                this.event_provider.customePage(pageId);
                this.slug.setSlugName( "custom-page" , event.slug? event.slug : event.title)
                this.router.navigate(['/custom-page'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug? event.slug : event.title])));
            } else if (pageName === 'Form') {
                if(localStorage.getItem('is_wordpress_enable')) {
                    let form_page = this.app_pages.filter(x => x.id === pageId);
                    if (form_page.length > 0) {
                        this.router.navigate(['/forms/' + form_page[0].html]);
                    }
                } else {
                    this.slug.setForm(pageId);
                    this.slug.setSlugName( "form" , event.slug);
                    this.router.navigate(['/form'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
                }
            } else if (pageName === 'iFrame') {
                this.slug.setPage_id("in-app-iframe",pageId);
                this.slug.setSlugName( "in-app-iframe/" , event.slug);
                this.router.navigate(['in-app-iframe/'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
            } else if (pageName === 'Link') {
                let link_page = this.app_pages.find(x => x.id === pageId);
                if (link_page) {
                    let open_external = link_page.open_external;
                    if (open_external === 'true') {
                        let url = JSON.parse(localStorage.getItem("url"));
                        if (url.host.includes("pwabuilder.churchbase.com")) {
                            let navigationExtras: NavigationExtras = {
                                queryParams: {
                                    url: link_page.url
                                }
                            };
                            this.slug.setIframe(link_page.url);
                            this.slug.setSlugName( "iframeheader" , event.slug);
                            this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
                        } else {
                            this.openurlinbrowser(link_page.url);
                        }
                    } else {
                        this.openurlinapp(link_page.url);
                    }
                }
            } else if(pageName === 'Bible'){
                if(this.ch_id == 1000){
                    this.router.navigate(['/bible-chapter']);
                } else {
                    pageName = pageName.replace(' ', '-');
                    pageName = pageName.toLowerCase();

                    this.router.navigate([pageName]);
                }

            }else if (pageName === 'Contact Us') {
                this.slug.setCh_Id(this.theme.ch_id)
                //  this.slug.setSlugName( "contact-us" , pageData.slug)
                this.router.navigate(['/contact-us/' , {page_id: pageId}]);
            } 
            else if (pageName === 'Newsletter') {
                this.slug.setCh_Id(this.ch_id)
                // this.router.navigate(['/newsletter/' + this.ch_id]);
                this.slug.setSlugName( "newsletter" , event.slug);
                this.router.navigate(['/newsletter/' + this.ch_id], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
            } else {
                if(pageName === 'Sermon Series'  ){
                    var data={
                        id:JSON.parse(event.series_id),
                        ch_id:this.ch_id,
                        page_id: pageId
                    }
                    this.slug.setAllSermon(data);
                    this.event_provider.allsermonSeries(data);
                    this.slug.setSlugName( "all-sermons" , event.slug )
                    this.router.navigate(['all-sermons'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
                }else if( pageName === 'Sermons' ){
                    this.event_provider.sermonPage(pageId)
                    this.slug.setPage_id("sermons",pageId)
                    this.slug.setSlugName( "sermons" , event.slug)
                    this.router.navigate(['/sermons'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
                }else{
                pageName = pageName.replace(' ', '-');
                pageName = pageName.toLowerCase();
                this.slug.setSlugName( pageName , event.slug)
                this.router.navigate([pageName], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([event.slug])));
                }
            }
        }
    }


    // inside app on page with done button
    openurlinapp(url){
        if(this.platform.is('android') || this.platform.is('iphone')) {
            this.iab.create(url, '_blank', this.options);
        } else {
            this.iab.create(url, '_self', this.options);
        }
    }

    // in external safari/chrome
    openurlinbrowser(url){
        this.iab.create(url, '_system');
    }

    setAnalytics() {
        let dev_id = '';
        let device = 'web';
        // preparing device data
        if(this.platform.is('android') || this.platform.is('iphone')) {
            dev_id = this.device.uuid;
            if (dev_id){
                device =  this.platform.platforms()[0];
            }
        }

        // preparing duration data
        let end_time_analytics = moment();
        var duration:any = moment.duration(end_time_analytics.diff(this.start_time_analytics)).asSeconds();

        // preparing user data
        let userId = this.userLoggedIn ? this.user.user.id : 'guest';

        // preparing data for analytics
        const body = JSON.stringify({
            church_id: this.ch_id,
            latitude: this.theme ? this.theme.latitude : "",
            longitude: this.theme ? this.theme.longitude : "",
            timespent: duration,
            module: this.page_name,
            custom_name: this.custom_name,
            ip_address: "",
            device: device,
            device_id: dev_id,
            client_type: userId,
            event_type: ""
        });
        // calling function from service to update Analytics Data
        this.analytics.setAnalytics(body).subscribe((res: any) => {

        })
    }

    ionViewDidLeave(){
        this.setAnalytics();
    }

    ionViewDidEnter(){
        // setting start time for analytics
        this.start_time_analytics = moment();
    }

}
