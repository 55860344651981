export const environment = {
    production: false,
     api_end_point: 'https://manage.churchbase.com/index.php?api/Builder',
    image_base_url: 'https://manage.churchbase.com/uploads/logo/',
    domain_name: 'https://pwabuilder.churchbase.com',
    devdomain_name: 'https://builder.churchbase.com',
    campus_image_url: 'https://manage.churchbase.com/uploads/campus/',
    profile_img_base_url: 'https://manage.churchbase.com/uploads/client_image/comm_profile/',
    saveNewsUrl : "https://manage.churchbase.com/index.php?api/Builder/newswall/savenews/en",
    youtube_api_key: 'AIzaSyBkfOeeKNXIZiv6L_Jza3vd7YVc_ScwMvA',
    vimeo_access_token: '691b7c3ec98036571b366cc6c8d5b037',
    church_id: 1000,
    stripe_key: 'pk_live_hIhOFwMgHgvMsn7jlVmKF6ga'    // new key
};
