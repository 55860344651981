import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ApiService} from "../../../services/api.service";
import {LoaderService} from "src/services/loader.service";
import {Storage} from "@ionic/storage";
import {ToastService} from "../../../services/toast.service";
import {QrScannerComponent} from 'angular2-qrscanner';
import { NgQrScannerModule } from 'angular2-qrscanner';
import {ViewChild, ViewEncapsulation} from '@angular/core';
import {DatePipe} from '@angular/common';
import {TranslateService} from "@ngx-translate/core";



@Component({
  selector: 'app-qrcode-modal',
  templateUrl: './qrcode-modal.component.html',
  styleUrls: ['./qrcode-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [QrcodeModalComponent],
})
export class QrcodeModalComponent implements OnInit{
  @ViewChild(QrScannerComponent, {static: false}) qrScannerComponent: QrScannerComponent;


  constructor(  private translate: TranslateService,) { }

  ngOnInit() {
    this.qrScannerComponent.getMediaDevices().then(devices => {
      const videoDevices: MediaDeviceInfo[] = [];
      for (const device of devices) {
        if (device.kind.toString() === 'videoinput') {
          videoDevices.push(device);
        }
      }
      if (videoDevices.length > 0) {
        let choosenDev;
        for (const dev of videoDevices) {
          if (dev.label.includes('front')) {
            choosenDev = dev;
            break;
          }
        }
        if (choosenDev) {
          this.qrScannerComponent.chooseCamera.next(choosenDev);
        } else {
          this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
        }
      }
    });

    this.qrScannerComponent.capturedQr.subscribe(result => {
    });

  }

}
