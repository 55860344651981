import {Injectable} from '@angular/core';
import {environment} from "../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Storage} from "@ionic/storage";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    _headers: HttpHeaders;

    constructor(
        private http: HttpClient,
        private storage: Storage
    ) {
        this._headers = new HttpHeaders();
        this._headers = this._headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }

    setAnalytics(body) {
        // let api = environment.api_end_point + '/churchs/analytics';
        // return this.http.post(api, JSON.parse(body), {headers: this._headers});
        var newBody = JSON.parse(body);
        if(newBody.module && newBody.module !== ''){
            let api = environment.api_end_point + '/churchs/analytics';
            return this.http.post(api, JSON.parse(body), {headers: this._headers});
        }else{
           return Observable.create(observer => { 
                   var result = "false"
                   observer.next(result);
                   observer.complete();
           });
        }
    }

    saveOnNotificationClick(body) {
        let api = environment.api_end_point + '/churchs/saveonnotificationclick';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }
}
