import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-emoji',
  templateUrl: './emoji.component.html',
  styleUrls: ['./emoji.component.scss'],
})
export class EmojiComponent implements OnInit {
  toggled = true;
  constructor(private navParams: NavParams,
              private popoverController: PopoverController,) {
                this.toggled = this.navParams.data.show_emoji
               }
  handleSelection(event) {
    this.toggled = false;
    this.popoverController.dismiss({
      event: event,
  });
  }
  ngOnInit() {}

}
