import {Injectable} from '@angular/core';
import {ToastController} from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(public toastController: ToastController) {}

    async presentToast() {
        const toast = await this.toastController.create({
            message: 'Your settings have been saved.',
            duration: 2000
        });
        toast.present();
    }

    async presentToastWithOptions(message: string, header: string, color: string) {
        const toast = await this.toastController.create({
            header: header,
            message: message,
            position: 'bottom',
            color: color,
            duration: 3000
        });
        toast.present();
    }
}

// buttons: [
//     {
//         text: 'Ok',
//         role: 'cancel',
//         handler: () => {
//         }
//     }
// ]
