import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, ModalController} from "@ionic/angular";
import {BibleService} from "../../../services/bible.service";
import {Storage} from "@ionic/storage";
import {IonContent} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bible-translations',
  templateUrl: './bible-translations.component.html',
  styleUrls: ['./bible-translations.component.scss'],
  providers: []
})
export class BibleTranslationsComponent implements OnInit {
  @ViewChild(IonContent, {read: false, static: false}) content: IonContent;

  filtered_volumes;
  selected_lang;
  selected_vol;
  all_langs;
  search_lang: any;
  languages_section: any;
  ch_id: any;

  app_lang;

  alphabets_array: any = [];
  atozstring: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  languages_array = [
      {name: 'English', code: 'en'},
      {name: 'Spanish', code: 'es'},
      {name: 'Hindi', code: 'hin'},
      {name: 'Chinese', code: 'chi'},
      {name: 'Arabic', code: 'ar'},
      {name: 'Azerbaijani', code: 'az'},
      {name: 'Uzbek', code: 'uzb'},
      {name: 'Russian', code: 'rus'}
  ];

//  'English', 'Spanish', 'Hindi', 'Chinese', 'Arabic', 'Azerbaijani', 'Uzbek', 'Russian'

  constructor(
      public modalController: ModalController,
      private _bibleService: BibleService,
      private storage: Storage,
      public alertController: AlertController,
      public translate: TranslateService,

  ) { }

  ngOnInit() {
    let finalArray = [];

    var otherthanalpha = false;
    if (this.all_langs){
      this.all_langs.map( x => {
        if (finalArray.indexOf(x.language_family_name[0]) === -1){
          if (this.atozstring.indexOf(x.language_family_name[0]) === -1){
            otherthanalpha = true;
          } else {
            finalArray.push(x.language_family_name[0]);
          }
        }
      });
      this.alphabets_array = finalArray.sort();
      if (otherthanalpha){
        this.alphabets_array.push('#');
      }
    }

    if (this.selected_lang && this.filtered_volumes){
      if (this.selected_lang.language_family_code == 'ENG'){
        this.filtered_volumes = this.filtered_volumes.filter( x => x.version_code == 'ESV');
      }
    }

    this.storage.get("theme").then(res => {
      if (res) {
        this.ch_id = res.ch_id;
      }
    });


  }

  dismiss() {
    this.modalController.dismiss({
      check: true,
      selected_dam_id: this.selected_vol.dam_id,
      selected_lang: this.selected_lang,
      selected_vol: this.selected_vol
    });
  }

  set_dam_id_lang(lang) {
    this.selected_lang = lang;
// getting Volumes from API through Service
    this._bibleService.getVolumes(this.selected_lang.language_family_code).subscribe(res => {
      if (res) {
        let volumes: any = [];
        volumes = res;
        let filtered_volumes: any = [];

        // ======   filtering volumes according to DAM Id ====================
        if (volumes && volumes.length > 0) {
          let current_obj = volumes[0];
          let current_dam_id = volumes[0].dam_id;
          current_dam_id = current_dam_id.substring(0, 6);
          current_obj.dam_id = current_dam_id;
          filtered_volumes.push(current_obj);

          for (let i = 0; i < volumes.length; i++) {
            if (volumes[i].dam_id.includes(current_dam_id)) {

            } else {
              current_dam_id = volumes[i].dam_id.substring(0, 6);
              current_obj = volumes[i];
              current_obj.dam_id = current_dam_id;
              filtered_volumes.push(current_obj);
            }
          }
          this.filtered_volumes = filtered_volumes;
          if (this.selected_lang.language_family_code == 'ENG') {
            this.filtered_volumes = this.filtered_volumes.filter(x => x.version_code == 'ESV');
          }

          if (this.filtered_volumes.length > 1){
            this.selected_vol = this.filtered_volumes[1];
          } else if (this.filtered_volumes.length === 1){
            this.selected_vol = this.filtered_volumes[0];
          }
        } else {
          this.filtered_volumes = [];
          this.novolumealert();
        }
        // ============================================================
      }
      this.languages_section = false;
      let bible_obj = {
        language: this.selected_lang,
        volume: this.selected_vol,
      };
      localStorage.setItem("bible", JSON.stringify(bible_obj));
      // storing bible lang and volume in storage
    });
  }

  volume_selected( vol ) {
    this.selected_vol = vol;
    let bible_obj = {
      language: this.selected_lang,
      volume: this.selected_vol
    };
    localStorage.setItem("bible", JSON.stringify(bible_obj));
    this.dismiss();
    // storing bible lang and volume in storage
  }

  async novolumealert( ) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Translation',
      message: 'No Volume for this Language',
      buttons: ['OK']
    });

    await alert.present();
  }

  scrollTo(element) {
    if (element === '#'){
        this.content.scrollToBottom(500);
    } else {
        this.content.scrollToTop(0);
        let yOffset = document.getElementById(element).offsetTop;
        this.content.scrollByPoint(0, yOffset, 500);
    }
  }

  changeLang(lang){
    this.translate.setDefaultLang(lang.code);
    let app_language = {
      code: lang.code,
      name: lang.name
    };
    localStorage.setItem('app_language', app_language.code);
    localStorage.setItem('ism_app_language', JSON.stringify(app_language));

    this.storage.set('app_language', app_language);

    this.modalController.dismiss({
      check: true,
      language: lang
    });
}

}
