import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SermonService {
    _headers: HttpHeaders;
    vimeochannelheader: HttpHeaders;

    constructor(private http: HttpClient, private storage: Storage) {
        this._headers = new HttpHeaders();
        this._headers = this._headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

        this.vimeochannelheader = new HttpHeaders();
        this.vimeochannelheader = this.vimeochannelheader.append('Authorization', 'Bearer 691b7c3ec98036571b366cc6c8d5b037');
        this.vimeochannelheader = this.vimeochannelheader.append('Content-Type', 'application/json');
    }

    getAllSermons(base_url) {
        let external_link = base_url;
        let api = environment.api_end_point + '/event/externalLinkData';
        let body = {
            url: external_link,
            method: 'getAllSermons'
        };
        let _body = JSON.stringify(body);
        return this.http.post(api, JSON.parse(_body), {headers: this._headers});
        // let api = base_url;
        // let api = "https://s3729.churchbase.site/feed/?post_type=wpfc_sermon&full=1";
        // -> let api = 'https://s2818.churchbase.io/feed/?post_type=wpfc_sermon&full=1';
        // let api = environment.api_end_point + '/event/externalLinkData/' + base_url + '/' + 'getAllSermons';
        // return this.http.get(api, {responseType: 'text'});
    }

    getFeed(body) {
        let api = environment.api_end_point + '/event/externaldata';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getYoutubeChannel(base_url) {
        let external_link = base_url;
        let api = environment.api_end_point + '/event/externalLinkData';
        let body = {
            url: external_link,
            method: 'getYoutubeChannel'
        };
        let _body = JSON.stringify(body);
        return this.http.post(api, JSON.parse(_body), {headers: this._headers});
        // let api = "https://www.googleapis.com/youtube/v3/channels?id=" + base_url + "&key=" + environment.youtube_api_key + "&part=contentDetails";
        // return this.http.get(api);
    }

    getYoutubePlaylist(base_url) {
        let external_link = "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=50&playlistId=" + base_url + "&key=" + environment.youtube_api_key;
        let api = environment.api_end_point + '/event/translateExternalLink';
        let body = {
            url: external_link,
            method: 'getYoutubePlaylist'
        };
        let _body = JSON.stringify(body);
        return this.http.post(api, JSON.parse(_body), {headers: this._headers});
    }

    getYoutubeUserPlaylist(user_id){
        let external_link = user_id;
        let api = environment.api_end_point + '/event/externalLinkData';
        let body = {
            url: external_link,
            method: 'getYoutubeUserPlaylist'
        };
        let _body = JSON.stringify(body);
        return this.http.post(api, JSON.parse(_body), {headers: this._headers});
        // let api = `https://www.googleapis.com/youtube/v3/channels?part=contentDetails&forUsername=${user_id}&key=${environment.youtube_api_key}`;
        // return this.http.get(api);
    }

    getVimeoChannel(base_url) {
        // return this.http.get(base_url,{headers: this.vimeochannelheader});
        let external_link = base_url;
        let api = environment.api_end_point + '/event/translateExternalLink';
        let body = {
            url: external_link,
            method: 'getVimeoVideo'
        };
        let _body = JSON.stringify(body);
        return this.http.post(api, JSON.parse(_body));
    }

    getVimeoUser(base_url) {
        let external_link = base_url;
        let api = environment.api_end_point + '/event/translateExternalLink';
        let body = {
            url: external_link,
            method: 'getVimeoUser'
        };
        let _body = JSON.stringify(body);
        return this.http.post(api, JSON.parse(_body), {headers: this._headers});
    }

    async storeRecentSermons(data: any) {
        let done = await this.storage.set('recent_sermons', data);
        if (done) {
        }
    }

    getRecentSermons() {
        return this.storage.get('recent_sermons').then(res => {
            if (res) {
                return res;
            } else {
                return 0;
            }
        });
    }

    getSermonNotes( url, page_id = '' ) {
        // REAL ---->  let api = 'https://s2462.churchbase.site/feed/?post_type=sermonnotes&full=1';
        // let api = 'https://church.churchbase.site/feed/?post_type=sermonnotes&full=1';
        let external_link = url;
        let api = environment.api_end_point + '/event/translateExternalLink';

        let body = {
            url: external_link,
            method: 'getSermonNotes',
            page_id: page_id
        };
        let _body = JSON.stringify(body);
        return this.http.post(api, JSON.parse(_body), {headers: this._headers});
    }

    getCMSSermonNotes(body) {
        let api = environment.api_end_point + '/Contentcms/getNotes';
        return this.http.post(api, body, {headers: this._headers});
    }

    getCMSSermonNote(body) {
        let api = environment.api_end_point + '/Contentcms/note';
        return this.http.post(api, body, {headers: this._headers});
    }

    getCMSSermonPosts(body) {
        let api = environment.api_end_point + '/Contentcms/getPosts';
        return this.http.post(api, body, {headers: this._headers});
    }

    getCMSSermonPost(body) {
        let api = environment.api_end_point + '/Contentcms/post';
        return this.http.post(api, body, {headers: this._headers});
    }

    create_Audio_Feed_comment_DB(body) {
        let api = environment.api_end_point + '/contentcms/addContentSermonAudioComment';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getCategoryNotes( url, page_id ) {
        let external_link = "";
        let method = 'getCategoryNotes';
        if(url.includes(".rss")){
            external_link = url;
        } else if(url.includes("feed")){
            external_link = url;
        } else {
            external_link = url + "feed/";
        }
        let api = environment.api_end_point + '/event/translateExternalLink';

        let body = {
            url: external_link,
            method: method,
            page_id: page_id
        };
        let _body = JSON.stringify(body);
        return this.http.post(api, JSON.parse(_body), {headers: this._headers});
    }

    create_Audio_Feeds(body) {
        let api = environment.api_end_point + '/churchs/addfeed';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getPodsDataWithPagintion(body) {
        let api = environment.api_end_point + '/event/podcastPagination';
        return this.http.post(api, body, {headers: this._headers});
    }

    getCMSAudioVideo(body) {
        let api = environment.api_end_point + '/Contentcms/sermonAudioVideo';
        return this.http.post(api, body, {headers: this._headers});
    }

    create_Audio_Feeds_array(body) {
        let api = environment.api_end_point + '/churchs/addfeedarray';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    create_Audio_Feed_Rss(body) {
        let api = environment.api_end_point + '/churchs/addfeedrss';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    create_Audio_Feed_comment(body) {
        let api = environment.api_end_point + '/churchs/addcomment';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    create_Video_Feeds(body) {
        let api = environment.api_end_point + '/churchs/addfeed';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    create_Video_Feed_comment(body) {
        let api = environment.api_end_point + '/churchs/addcomment';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    get_feeds(apppage_id, feed_type) {
        let api = environment.api_end_point + '/churchs/comments/' + feed_type + '/' + apppage_id;
        return this.http.get(api);
    }

    get_feedsrss(body) {
        let api = environment.api_end_point + '/churchs/commentsrss/' + body;
        return this.http.get(api);
    }

    create_sermon(body) {
        let api = environment.api_end_point + '/churchs/addsermon';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }
    create_sermon_comment_DB(body) {
        let api = environment.api_end_point + '/contentcms/addSermonComment';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }
    create_sermon_comment(body) {
        let api = environment.api_end_point + '/churchs/addsermoncomment';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    get_sermons(body) {
        let api = environment.api_end_point + '/churchs/sermoncomments/' + body;
        return this.http.get(api);
    }

    get_rss(id, church_id) {
        let api = environment.api_end_point + '/event/sermonexternal/' + id + "/" + church_id;
        return this.http.get(api);
    }

    get_one_rss(body) {
        let api = environment.api_end_point + '/churchs/getonefeed';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getrss(url){
        let external_link = url;
        let api = environment.api_end_point + '/event/externalLinkData';
        let body = {
            url: external_link,
            method: 'getrss'
        };
        let _body = JSON.stringify(body);
        return this.http.post(api, JSON.parse(_body), {headers: this._headers});
        // let api = "https://api.rss2json.com/v1/api.json?rss_url=" + url;
        // return this.http.get(api);
    }

    getHomeTopPosts( url ) {
        let api = 'https://' + url + '/category/hometop/feed/';
        return this.http.get(api, {responseType: 'text'});
    }

    getHomeBottomPosts( url ) {
        let api = 'https://' + url + '/category/homebottom/feed/';
        return this.http.get(api, {responseType: 'text'});
    }

    create_custom_post_comment(body) {
        let api = environment.api_end_point + '/event/addCustomPostComment';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    create_custom_post_comment_cms(body) {
        let api = environment.api_end_point + '/Contentcms/addContentCustomPostComment';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

}
