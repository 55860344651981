import { Injectable } from '@angular/core';
import { Firebase } from '@ionic-native/firebase/ngx';
import { Platform } from '@ionic/angular';
import { AngularFirestore } from 'angularfire2/firestore';
import {environment} from "../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

    _headers: HttpHeaders;

  constructor(
      private http: HttpClient,
      private firebase: Firebase,
      private afs: AngularFirestore,
      private platform: Platform
  ) {
      this._headers = new HttpHeaders();
      this._headers = this._headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  }

  async getToken() {
    let token;

    if (this.platform.is('android')) {
      token = await this.firebase.getToken();
    }

    if (this.platform.is('ios')) {
      token = await this.firebase.getToken();
      await this.firebase.grantPermission();
    }

    this.saveToken(token);
  }

  private saveToken(token) {
    if (!token) return;

    const devicesRef = this.afs.collection('devices');

    const data = {
      token,
      userId: 'testUserId'
    };

    return devicesRef.doc(token).set(data);
  }

    sendToken(body) {
        let api = environment.api_end_point + '/auth/device';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }


    onNotifications() {
    return this.firebase.onNotificationOpen();
  }

}
