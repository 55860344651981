import {Component, OnInit, OnDestroy, HostListener, NgZone, Inject} from '@angular/core';
import {Platform, ToastController, AlertController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Storage} from '@ionic/storage';
import {LoaderService} from 'src/services/loader.service';
import {IdService} from 'src/services/id.service';
import {CommonService} from 'src/services/common.service';
import {environment} from '../environments/environment';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {ToastService} from '../services/toast.service';
import {DarkModeService} from '../services/dark-mode.service';
import {FirebaseService} from '../services/firebase.service';
import {ApiService} from "../services/api.service";
import {Firebase} from '@ionic-native/firebase/ngx';
import {Device} from '@ionic-native/device/ngx';
import {EventProviderService} from "../services/event-provider.service";
import {AnalyticsService} from "../services/analytics.service";
import {TranslateService} from "@ngx-translate/core";
import { DOCUMENT, Location} from "@angular/common";
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { observable} from "rxjs";
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SlugService } from 'src/services/slug.service';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { AuthService } from 'src/services/auth.service';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    themeStored: boolean = false;
    theme: any;
    darkmode: boolean = false;
    app_pages: any;

    hideHeader: boolean = false;
    current_domain: any;
    church_id: any;
    device_id: any = "";
    default_theme_color: any;
    default_app_style: any;
    alertMessage: any;
    app_favicon = "assets/icons/churchbase_logo.png";
    is_lang_selected: string;

    options : InAppBrowserOptions = {
        closebuttoncaption : 'Close',
        closebuttoncolor: '#FFFFFF',
        disallowoverscroll: 'no',
        hidenavigationbuttons: 'no',
        toolbar: 'yes',
        toolbarposition: 'top',
        location: 'yes'
    };
    selected_item_id: any = '';
    selected_item_obj: any = {
        id: '',
        url: ''
    };
    web_app_pages:any;
    home_tabs:any;
    new_path:any=[];

    constructor(
        @Inject(DOCUMENT) private _document: HTMLDocument,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private storage: Storage,
        private idService: IdService,
        private loader: LoaderService,
        private common: CommonService,
        private router: Router,
        private toast: ToastService,
        private firebase: Firebase,
        private firebaseService: FirebaseService,
        private darkModeService: DarkModeService,
        public toastController: ToastController,
        private apiService: ApiService,
        private device: Device,
        private event_provider: EventProviderService,
        private analytics: AnalyticsService,
        private translate: TranslateService,
        public route:ActivatedRoute,
        public alertController: AlertController,
        private keyboard: Keyboard,
        zone: NgZone,
        public slug:SlugService,
        public location:Location,
        public iab: InAppBrowser,
        private auth:AuthService
    ) {

        // get translated alert message
        let interval = setInterval(() => {
            this.translate.get('ALERTS').subscribe( (res: any) => {
                this.alertMessage = res;
                clearInterval(interval);
            });
        }, 2000);

        window['openInNewBrowser'] = (new_bypassLink) => {
                window.open(new_bypassLink.replace('"', ''), '_blank');
          };

        // ======== checking if domain exists for current url =====================================
        var  url:any = new URL(window.location.href);

        var saveUrl:any={
            hash: url.hash,
            host: url.host,
            hostname: url.hostname,
            href: url.href,
            origin: url.origin,
            password: url.password,
            pathname: url.pathname,
            port: url.port,
            protocol: url.protocol,
            search: url.search,
            searchParams: url.searchParams,
            username: url.username,
        };
        // var saveUrl:any={
        // hash: "",
        // host: "https://libertychurchofjacksonville.churchbase.com",
        // hostname: "https://libertychurchofjacksonville.churchbase.com",
        // href: "https://libertychurchofjacksonville.churchbase.com/forms/683",
        // origin: "https://libertychurchofjacksonville.churchbase.com",
        // password: "",
        // pathname: "/forms/683",
        // port: "8100",
        // protocol: "https:",
        // search: "",
        // searchParams: "",
        // username: ""
        // }
        // if(!saveUrl.pathname.includes("console")){
        //     (<any>window).DisableDevtool();
        // }
        localStorage.setItem("url",JSON.stringify(saveUrl));

        if(localStorage.getItem('is_lang_selected')){
            this.is_lang_selected = localStorage.getItem('is_lang_selected');
        } else {
            this.is_lang_selected = 'false';
            localStorage.setItem('is_lang_selected', this.is_lang_selected)
        }
        // ---------------------------

        this.current_domain = saveUrl.host;
        let path = url.pathname.replace("/", "");
        let new_path = url.pathname.split('/');
        this.new_path = url.pathname.split('/');
        // this.church_id = this.slug.ch_id
        // idService.setId(this.church_id);
        // console.log('path 1 is: ', new_path[1]);
        // console.log('path 2 is: ', new_path[2]);
        if(new_path[1] !== 'delete-user-account' && new_path[1] !== 'newsletter' && new_path[1] !== 'sermon' && !new_path[1].includes('my-contribute') && new_path[1] !== 'newsletter-detail' && new_path[1] !== 'newsletter' && new_path[1] !== 'campaign-registration' && new_path[1] !== 'event-giving-pwa' && new_path[1] !== 'login' && new_path[1] !== 'give-new' && new_path[1] !== 'campus-giving' && new_path[1] !== 'home-tabs' && new_path[2] !== 'home' && new_path[1] !== 'ism-home'  && new_path[1] !== 'forms'){
            const body = JSON.stringify({ domain_name: this.current_domain });
            this.apiService.get_domain(body).subscribe(async (res: any) => {
                if (await res.status) {
                    this.church_id = res.church_id;
                    idService.setId(this.church_id);
                    this.getChurchDetails(this.church_id);
                    if(new_path[1].includes('locations')|| new_path[1].includes('location-detail')|| new_path[1] == "custom-posts" || !this.new_path[1].includes('custom-post') || this.new_path[1] == "prayer-wall" || new_path[1] == "sermon" || new_path[1] == 'event' || new_path[1]== 'notes' || new_path[1]== "forms" ){
                        // var resol:any={
                        //     church:{
                        //         church_id : this.church_id
                        //     }
                        // };
                        // this.event_provider.getchurchdata(resol);
                    }else{
                        this.getslugPage(res.church_id, new_path[1])
                    }
                }
            })
        }

        if(new_path[1] == 'delete-user-account'){
            this.church_id = new_path[2];
            this.slug.ch_id = this.church_id;
            idService.setId(this.church_id);
        }
        if(new_path[1] === 'ism-home'){
            this.church_id = new_path[2];
            this.slug.ch_id = this.church_id;
            idService.setId(this.church_id);
        }
        if(new_path[1] === 'home-tabs' && new_path[2] === 'home'){
            this.church_id = new_path[3];
            this.slug.ch_id = this.church_id;
            idService.setId(this.church_id);
        } else if(new_path[1] === 'campus-giving'){
            this.church_id = new_path[2];
            this.slug.ch_id = this.church_id;
            idService.setId(this.church_id);
        }else if(new_path[1] === 'give-new'){
            this.church_id = new_path[2];
            this.slug.ch_id = this.church_id;
            idService.setId(this.church_id);
        }else if(new_path[1] === 'event-giving-pwa'){
            this.church_id = new_path[2];
            this.slug.ch_id = this.church_id;
            idService.setId(this.church_id);
        }else if(new_path[1] === 'campaign-registration'){
            this.church_id = new_path[2];
            this.slug.ch_id = this.church_id;
            idService.setId(this.church_id);
        }else if(new_path[1] === 'newsletter'){
            this.church_id = new_path[2];
            this.slug.ch_id = this.church_id;
            idService.setId(this.church_id);
        }else if(new_path[1] === 'newsletter-detail') {
            this.church_id = new_path[2];
            this.slug.ch_id = this.church_id;
            idService.setId(this.church_id);
        } else if (new_path[1].includes('my-contribute')) {
            if(new_path[2] === '2637' || new_path[2] === '2644' || new_path[2] === '2796') {
                this.church_id = '957';
                this.slug.ch_id = this.church_id;
                idService.setId(this.church_id);
            } else {
                this.church_id = new_path[2];
                this.slug.ch_id = this.church_id;
                idService.setId(this.church_id);
            }

        }else if (new_path[1] === 'login') {
            if (new_path[2]) {
                this.church_id = new_path[2];
                this.slug.ch_id = this.church_id;
                idService.setId(this.church_id);
            }
        }else{
            var thm = JSON.parse(localStorage.getItem('church_data'));
            if(thm && thm.church && thm.church.ch_id){
                this.slug.ch_id = thm.church.ch_id;
                this.church_id = thm.church.ch_id;
                idService.setId(this.church_id);
            }
        }

        if (url.host === "builder.churchbase.com" && url.pathname === "/") {

            saveUrl.href = saveUrl.host+"/home-tabs/home/"+this.slug.ch_id;
            saveUrl.pathname ="/home-tabs/home/"+this.slug.ch_id;
            this.router.navigate(['/home-tabs/home/54'],{ skipLocationChange: true, replaceUrl:false });
        } else if (url.host === "localhost:8100" && url.pathname === "/") {

            saveUrl.href = saveUrl.host+"/home-tabs/home/"+this.slug.ch_id;
            saveUrl.pathname ="/home-tabs/home/"+this.slug.ch_id;
            localStorage.setItem("url",JSON.stringify(saveUrl));
            this.router.navigate(['/home-tabs/home/54'],{ skipLocationChange: true,replaceUrl:true});
            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
        } else{
            if(saveUrl.pathname == '/'){
                saveUrl.href = saveUrl.host+"/home-tabs/home/"+this.slug.ch_id;
                saveUrl.pathname ="/home-tabs/home/"+this.slug.ch_id;
                localStorage.setItem("url",JSON.stringify(saveUrl));
                this.router.navigate(['/home-tabs/home/'+this.slug.ch_id],{ skipLocationChange: true,replaceUrl:true});
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
            } else if(saveUrl.pathname.includes("home-tabs/home")){
                saveUrl.href = saveUrl.host+"/home-tabs/home/"+this.slug.ch_id;
                saveUrl.pathname ="/home-tabs/home/"+this.slug.ch_id;
                localStorage.setItem("url",JSON.stringify(saveUrl));
                this.router.navigate(['/home-tabs/home/'+this.slug.ch_id],{ skipLocationChange: true,replaceUrl:true});
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
            }
        }


        if (path === "") {
            const body = JSON.stringify({domain_name: this.current_domain});
            this.apiService.get_domain(body).subscribe(async (res: any) => {
                if (res.status) {
                    this.church_id = res.church_id;
                    idService.setId(this.church_id);
                    if (this.church_id === '1000'){
                        this.router.navigate(['bible-chapter'],{ skipLocationChange: true,});
                    } else {
                        this.slug.ch_id = this.church_id;
                        this.router.navigate(['/home-tabs/home/' + this.church_id],{ skipLocationChange: true, replaceUrl:false});
                        this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                    }
                }
            });
        } else {
            const body = JSON.stringify({domain_name: this.current_domain});
            this.apiService.get_domain(body).subscribe(async (res: any) => {
                if (res.status) {
                    this.church_id = res.church_id;
                    idService.setId(this.church_id);
                    this.getChurchDetails(this.church_id);
                    if(path.includes(';')){
                        let new_path = path.split(';');
                        let new_var = new_path[1].split('=');
                        let new_object: object;
                        new_object = {};
                        new_object[new_var[0]] = new_var[1];
                        this.router.navigate([new_path[0],
                            new_object
                        ],{ skipLocationChange: true, replaceUrl:false});
                        this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                    } else {
                        this.slug.ch_id = this.church_id;
                        // this.router.navigate(['/home-tabs/home/' + this.church_id],{ skipLocationChange: true, replaceUrl:false});
                        // this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                        this.router.navigate([path]);
                        // this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                    }
                }
            });
        }
        // ===========================================================================================

        this.initializeApp();

        // event subscription to change mode
        this.event_provider.changeDarkMode.subscribe(value => {              // listening event
            if (this.darkmode === false) {
                this.darkmode = true;
            } else {
                this.darkmode = false;
            }
        });

        // event subscription to hide header
        this.event_provider.hideHeader.subscribe(value => {              // listening event
            if (value.color1){
                document.documentElement.style.setProperty('--primary-color', value.color1);
            }
            if (value.color2) {
                document.documentElement.style.setProperty('--secondary-color', value.color2);
            }
            this.hideHeader = true;
        });

        // event subscription to bring header back
        this.event_provider.showHeader.subscribe(value => {              // listening event
            this.hideHeader = false;
        });

        // event subscription to hide header from login
        this.event_provider.hideHeaderLogin.subscribe(value => {              // listening event
            this.hideHeader = true;
        });

        // event subscription to change color
        this.event_provider.changeColor.subscribe(value => {              // listening event
            document.documentElement.style.setProperty('--primary-color', value.value);
        });

        // event subscription to change mode
        this.event_provider.darkModeOn.subscribe(value => {              // listening event
            this.darkmode = value.value;
        });

        // event subscription to change color
        this.event_provider.setDefaultColorMode.subscribe(value => {              // listening event
            this.darkmode = (this.default_app_style === "2") ? true : false;
            document.documentElement.style.setProperty('--primary-color', this.default_theme_color);
        });

        var lang:any =  localStorage.getItem('app_language');
        if (lang) {
            if(lang == 'ar'){
                this.event_provider.changeLanguageForFooter('rtl');
                document.dir="rtl"

            }else{
                this.event_provider.changeLanguageForFooter('ltr');
                document.dir = "ltr"
            }
            localStorage.setItem('app_language', lang);
            this.translate.setDefaultLang(lang);
        } else {
            localStorage.setItem('app_language', 'en');
            this.translate.setDefaultLang('en');
            // saving lang for ISM
            let app_language = {
                code: 'en',
                name: 'English'
            };
            this.event_provider.changeLanguageForFooter('ltr');
            localStorage.setItem('ism_app_language', JSON.stringify(app_language));
        }

        const dt_format =  localStorage.getItem('isDateTimeFormat');
        if (dt_format) {
            localStorage.setItem('isDateTimeFormat', 'true');
        } else {
            localStorage.setItem('isDateTimeFormat', 'false');
        }

        // event subscription to set favicon
        this.event_provider.setFavicon.subscribe(value => { // listening event
            this._document.getElementById('appFavicon').setAttribute('href', this.app_favicon);
        });
    }
    getUniqueId(parts: number): string {
        const stringArr = [];
        let ar= ['d','e','r','n','a','g'] 
        for(let i = 0; i< parts; i++){
            // tslint:disable-next-line:no-bitwise
            let S4 = (((1 + Math.random()) * 0x100000000) | 0).toString(36).substring(1);
            let random_index = Math.random() * parts;
             S4 = S4.slice(0, random_index) + ar[i] +  S4.slice(random_index, 5);
            stringArr.push(S4);
        }
        return stringArr.join('');
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.isUserLoggedIn();

            this.keyboard.hideFormAccessoryBar(true);
            this.keyboard.hideFormAccessoryBar(false);
        });
        this.event_provider.changeAppLanguage.subscribe((res: any) => {
            if(res){
                this.getDesign();
            }
        })


        this.device_id = this.getUniqueId(6);
        if(this.device_id.length === 35){
            this.device_id = this.device_id + 'a';
        }
        var bodies = {
        string: this.device_id
        }
        localStorage.setItem('device_id', this.device_id);
        this.auth.getToken((bodies)).subscribe((res:any)=>{
            if(res.status){
                localStorage.setItem("apiAuthToken", res.token)
            }           
        }) 
    }

    private async presentToast(message) {
        const toast = await this.toastController.create({
            message,
            duration: 3000
        });
        toast.present();
    }


    async getDesign() {
        // getting theme from local storage
        if (localStorage.getItem('church_data')) {
            this.theme = JSON.parse(localStorage.getItem('church_data')).church;
            if (this.theme.ch_id != "" && this.theme.ch_id != null) {
                // getting latest theme from service if church_id is there

                this.getChurchDetails(this.theme.ch_id);
            } else {
                // getting latest theme from service if church_id is NOT there
                if(this.platform.is('cordova')){
                    this.theme.ch_id = environment.church_id;
                    this.getChurchDetails(this.theme.ch_id);
                } else {
                    if(this.church_id){
                        this.getChurchDetails(this.church_id);
                    }
                }
            }
            this.themeStored = true;
        } else {
            // getting latest theme from service if church_id is NOT there
            if(this.platform.is('cordova')){
                this.theme.ch_id = environment.church_id;
                this.getChurchDetails(this.theme.ch_id);
            } else {
                if(this.church_id){
                    this.getChurchDetails(this.church_id);
                }
            }
        }
    }

    async isUserLoggedIn() {
        var client_type = '';
        // getting user from local storage
        this.storage.get('user').then(async (val: any) => {
            if (val !== 'guest' && val !== null) {
                this.getDesign();
                this.router.navigate([this.router.url],{ skipLocationChange: true, replaceUrl:false});
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                client_type = 'user';
            } else {
                //removing user data from local storage
                await this.storage.remove('user').then(res => {
                });
                client_type = 'guest';
                this.apiService.analyticsPerVisit(this.church_id, this.platform.platforms()[0], client_type).subscribe(
                    (res: any) => {
                    },
                    (err: any) => {

                    }
                );

                this.platform.resume.subscribe(
                    (res: any) => {
                        this.apiService.analyticsPerVisit(this.church_id, this.platform.platforms()[0], client_type).subscribe(
                            (res: any) => {
                            },
                            (err: any) => {
                                console.log(err);
                            }
                        );
                    },
                    (err: any) => {
                        console.log(err);
                    }
                );
                // getting ID from ID SERVICE which is set by HOME page or
                // GIVING Pages (External pages which comes with church ID)
                let id = this.idService.getId();

                    // getting theme from local storage
                    await this.storage.get('theme').then(value => {
                        if (value && value.ch_id != id && id) {
                            // THIS PART RUNS WHEN CHURCH ID FROM LOCAL
                            // STORAGE DOES NOT MATCH WITH CHURCH ID FROM SERVICE(latest church id)

                            // removing theme from local storage and then calling latest theme
                            this.storage.remove('theme').then(val => {
                                this.getChurchDetails(id);

                            });
                        } else if (value) {
                            // THIS PART RUNS WHEN CHURCH ID FROM LOCAL
                            // STORAGE DOES MATCHES WITH CHURCH ID FROM SERVICE(latest church id)
                            this.getDesign();
                        } else {
                            // THIS PART RUNS WHEN there is no theme
                            // in local storage (New Church)
                            this.getChurchDetails(id);
                        }
                    });
                }
            });
    }

    async getslugPage(churchId, slug, ) {
        // this.loader.presentLoading().then(() => {
            const body = JSON.stringify({ id: churchId });
            this.common.getTheme(body).subscribe(async (res: any) => {
                if (res.status && res.church) {
                    var idx = res.apppages.findIndex(page=> page.slug == slug);
                    if(idx > -1){
                        var appPage:any = res.apppages[idx];
                        // this.loader.stopLoading();
                        this.navigateToPage(appPage.id, appPage.page_type, appPage.name, appPage.html, appPage);
                    } else{
                         // here is call we calling api for slug_pages
                         if(slug !== ''){
                             this.apiService.slugContentApi(churchId,slug).subscribe((res:any)=>{
                                if(res.status){
                                    this.newSlugPages(churchId,res)
                                }
                                // else if(slug === 'delete-user-account'){
                                //     this.router.navigate(['delete-user-account/' + this.church_id],{ skipLocationChange: true});
                                //     this.loader.stopLoading();
                                //  }
                                 else{
                                    this.slug.ch_id = res.church
                                    this.router.navigate(['/home-tabs/home/'+res.church],{ skipLocationChange: true,replaceUrl:true});
                                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                                    this.loader.stopLoading();
                                }
                             },err=>{
                            // this.loader.stopLoading();
                            this.slug.ch_id = res.church;
                            this.router.navigate(['/home-tabs/home/'+res.church],{ skipLocationChange: true,replaceUrl:true});
                            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                            this.loader.stopLoading();
                             })
                         }
                    }
                }
            })
        // })
    }


    async getChurchDetails(churchId) {
        this.loader.presentLoading().then(() => {})
            if(churchId === null || churchId === undefined){
                if(this.church_id){
                    churchId = this.church_id;
                }else{
                    if(this.new_path[1] !== 'newsletter' && this.new_path[1] !== 'sermon' && !this.new_path[1].includes('my-contribute') && this.new_path[1] !== 'newsletter-detail' && this.new_path[1] !== 'newsletter' && this.new_path[1] !== 'campaign-registration' && this.new_path[1] !== 'event-giving-pwa' && this.new_path[1] !== 'login' && this.new_path[1] !== 'give-new' && this.new_path[1] !== 'campus-giving' && this.new_path[1] !== 'home-tabs' && this.new_path[2] !== 'home' && this.new_path[1] !== 'ism-home'){
                        const body = JSON.stringify({ domain_name: this.current_domain });
                        this.apiService.get_domain(body).subscribe(async (res: any) => {
                            if (await res.status) {
                                churchId = res.church_id;
                                this.church_id = res.church_id;
                                this.idService.setId(this.church_id);
                                if(this.new_path[1].includes('locations')|| this.new_path[1].includes('location-detail')||this.new_path[1] == "custom-posts" || !this.new_path[1].includes('custom-post') ||  this.new_path[1] == "prayer-wall" ||  this.new_path[1] == "sermon" || this.new_path[1] == 'event' || this.new_path[1]== 'notes' || this.new_path[1]== "forms" ){
                                    this.loader.stopLoading();
                                }else{
                                    await this.getslugPage(res.church_id, this.new_path[1])
                                    this.loader.presentLoading()
                                }

                            }
                        })
                    }
                }
            }

            if(churchId) {
                const body = JSON.stringify({id: churchId});
                
                this.common.getTheme(body).subscribe(async (res: any) => {
                    if (res.status && res.church) {
                        // setting app favicon
                        if (res.church.website_logo) {
                            let interval = setInterval(() => {
                                this.app_favicon = res.church.website_logo;
                                this.event_provider.setfavicon(this.app_favicon);
                                clearInterval(interval);
                            }, 3000);

                        }
                        if (res.church.datetimeformat == '1') {
                            localStorage.setItem('isDateTimeFormat', 'true');
                        } else {
                            localStorage.setItem('isDateTimeFormat', 'false');

                        }
                        if (res.is_wordpress_enable === "1") {
                            localStorage.setItem('is_wordpress_enable', 'true');
                        }
                       
                        // if(this.new_path[1] === 'location-detail'){

                        //     console.log('yes its location details');
                        // }else{
                        //     console.log('nooooo its location details');
                        // }
                        if( !this.new_path[1].includes("delete-user-account") && this.new_path[1] !== 'location-detail' && this.new_path[1] !== 'newsletter' && !this.new_path[1].includes('my-contribute') && this.new_path[1] !== 'newsletter-detail' && this.new_path[1] !== 'newsletter' && this.new_path[1] !== 'campaign-registration' && this.new_path[1] !== 'event-giving-pwa' && this.new_path[1] !== 'login' && this.new_path[1] !== 'give-new' && this.new_path[1] !== 'campus-giving' && this.new_path[1] !== 'home-tabs' && this.new_path[2] !== 'home' && this.new_path[1] !== 'ism-home'){
                            if(this.new_path[1] !== 'locations' && this.new_path[1] !== 'location-detail' && this.new_path[1] !== "custom-posts" &&  !this.new_path[1].includes('custom-post') && this.new_path[1] !== "prayer-wall" && this.new_path[1] !== "sermon" && this.new_path[1] !== 'event' && this.new_path[1] !== 'notes' && this.new_path[1] !== "forms" ){
                                await this.getslugPage(churchId, this.new_path[1] )    
                            }
                        }    

                        if (this.is_lang_selected == 'false' && res.church.is_translation_enable == '1') {
                            this.church_id = res.church.ch_id;
                            this.loader.stopLoading();
                            this.selectLanguagePopup();
                        } else {
                            this.default_theme_color = res.church.color ? res.church.color : "#302F34";
                            this.default_app_style = res.church.app_style;


                            if (res.church.home_tabs) {
                                // updating Home Tabs
                                this.event_provider.updatehometabs(res.church.home_tabs);
                            }

                            // setting AppMode
                            if (res.church.app_style === '1') {
                                this.darkmode = false;
                            } else if (res.church.app_style === '2') {
                                this.darkmode = true;
                            }
                            this.darkModeService.setDark_mode(this.darkmode);

                            // storing app pages
                            await this.common.store_app_pages(res.apppages, 'GCD from app.ts');
                            this.event_provider.getapppages(res.apppages);

                            await this.common.storeTheme(res.church);
                            this.event_provider.getapptheme(res.church);
                            this.storage.get('theme').then(res => {
                            });
                            this.themeStored = true;
                            this.loader.stopLoading();
                            this.event_provider.getchurchdata(res);
                        }
                    }
                    if (!res.status) {
                        this.loader.stopLoading();
                        this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.no_church_exists : 'No Church Exists with id') + ' ' + churchId, (this.alertMessage ? this.alertMessage.WarningMessage : 'Warning Message'), 'warning');
                    }
                }, (err: any) => {
                    let message = err.message;
                    this.loader.stopLoading();
                    this.toast.presentToastWithOptions(message, (this.alertMessage ? this.alertMessage.WarningMessage : 'Warning Message'), 'warning');
                });
            }
        // });
    }

    store_app_pages(churchId) {
        // this.loader.presentLoading().then(() => {

            this.storage.get('app_pages').then(res => {
                if (res) {
                    let remove = this.storage.remove('app_pages');
                    if (remove) {
                        const body = JSON.stringify({id: churchId});
                    }
                }
            });
        // });
    }

    async selectLanguagePopup(){
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Select Language',
            backdropDismiss: false,
            inputs: [
              {
                name: 'English',
                type: 'radio',
                label: 'English',
                value: 'en',
                checked: true
              },
              {
                name: 'Spanish',
                type: 'radio',
                label: 'Spanish',
                value: 'es'
              },

            ],
            buttons: [
               {
                text: 'Ok',
                handler: (value) => {
                   localStorage.setItem('app_language', value);
                   localStorage.setItem('is_lang_selected', 'true');
                   this.is_lang_selected  = 'true';
                   this.translate.setDefaultLang(value);
                   this.getChurchDetails(this.church_id);
                }
              }
            ]
          });

          await alert.present();
    }

    navigateToPage(page_id, page_type, pageName, pagehtml, pageData) {
        this.selected_item_id = page_id;
        if (page_type === 'page') {
            if (pageName === 'Audio') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'audio-archive'
                };
                this.slug.setPage_id("audio-archive",page_id);
                this.slug.setSlugName( "audio-archive" , pageData.slug );
                this.router.navigate(['audio-archive'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));

            } else if (pageName === 'Video') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'video-archive'
                };
                this.slug.setPage_id("video-archive",page_id)
                this.slug.setSlugName( "video-archive" , pageData.slug )
                this.router.navigate(['video-archive'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Events') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'events'
                };
                this.slug.setPage_id("events",page_id);
                this.slug.setSlugName( "events" , pageData.slug );
                this.router.navigate(['events'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));

            } else if (pageName === 'Donations') {
                let total = this.app_pages.concat(this.web_app_pages);
                let link_page = total.find(x => x.id === page_id);
                let url = JSON.parse(localStorage.getItem("url"));
                if (link_page) {
                    let open_external = link_page.open_external;
                    if (open_external === 'true') {
                        let donations_url = '';
                        if (link_page.html) {

                            donations_url = url.origin + "/give-new/" + link_page.html;
                        } else {
                            donations_url = url.origin + "/give-new/" + this.church_id;

                        }
                        if(this.platform.is("mobile") || this.platform.is("pwa")){
                            if (url.host.includes("pwabuilder.churchbase.com")) {
                                let navigationExtras: NavigationExtras = {
                                    queryParams: {
                                        url: link_page.url
                                    }
                                };
                                this.slug.setIframe(link_page.url)
                                this.slug.setSlugName( "iframeheader" , pageData.slug);
                                this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                            }
                            else{
                                this.openurlinbrowser(donations_url);
                            }
                        }else{
                            this.openurlinbrowser(donations_url);
                        }
                    } else {
                        this.router.navigateByUrl('/my-contribute/'+link_page.html);
                    }
                }
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'my-contribute'
                };

            } else if (pageName === 'Campaign Registration') {
                this.router.navigate(['/campaign-registration/' + this.theme.ch_id + '/' + pagehtml]);
            } else if (pageName === 'iFrame') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'in-app-iframe'
                };
                this.slug.setPage_id("in-app-iframe",page_id)
                this.slug.setSlugName( "in-app-iframe/" , pageData.slug)
                this.router.navigate(['in-app-iframe/'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Custom Post') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'custom-post'
                };
                this.slug.setPage_id("custom-post",page_id)
                this.slug.setSlugName( "custom-post" , pageData.slug)
                this.router.navigate(['custom-post/'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Feed') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'feed-tab'
                };
                if ((this.home_tabs && this.home_tabs[1].status === 'true') || this.theme.ch_id === '1000'){
                    this.router.navigate(['/feed-tab']);
                } else {
                    this.router.navigate(['home-tabs/feed-tab']);
                }
            } else if (pageName === 'Chat') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'chat'
                };
                if ((this.home_tabs && this.home_tabs[2].status === 'true') || this.theme.ch_id === '1000'){
                    this.router.navigate(['/chat']);
                } else {
                    this.router.navigate(['home-tabs/chat']);
                }
            } else if (pageName === 'Live Stream') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'live-stream'
                };
                this.slug.setCh_Id(this.theme.ch_id)
                this.slug.setSlugName( "live-stream" , pageData.slug)
                this.router.navigate(['live-stream'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Form') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'form'
                };
                if(localStorage.getItem('is_wordpress_enable')) {
                    let form_page = this.app_pages.filter(x => x.id === page_id);
                    if (form_page.length > 0) {
                        this.router.navigate(['/forms/' + form_page[0].html]);
                    }
                } else {
                    this.slug.setForm(page_id)
                    this.slug.setSlugName( "form" , pageData.slug)
                    this.router.navigate(['/form'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }
            } else if (pageName === 'Bible'){
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'bible'
                };
                if(this.theme.ch_id == '1000'){
                    this.router.navigate(['/bible-chapter']);
                } else {
                    pageName = pageName.replace(' ', '-');
                    pageName = pageName.toLowerCase();

                    // saving for highlighing selected item
                    this.selected_item_obj = {
                        id: page_id,
                        url: pageName
                    };

                    this.router.navigate([pageName]);
                }
            } else if (pageName === 'Community'){
                this.slug.setPage_id("community",page_id)
                this.slug.setSlugName( "community" , pageData.slug)
                this.router.navigate(['/community'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Newsletter') {
                this.slug.setCh_Id(this.theme.ch_id);
                this.slug.setSlugName( "newsletter" , pageData.slug);
                this.router.navigate(['/newsletter/' ], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else {
                if(pageName === 'Sermon Series'  ){
                    var data={
                        id:JSON.parse(pageData.series_id),
                        ch_id:this.church_id,
                        page_id: page_id
                    }
                    this.slug.setAllSermon(data)
                    this.event_provider.allsermonSeries(data);
                    this.slug.setSlugName( "all-sermons" , pageData.slug )
                    this.router.navigate(['all-sermons'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }else if( pageName === 'Sermons' ){
                    this.event_provider.sermonPage(page_id)
                    this.slug.setPage_id("sermons",page_id)
                    this.slug.setSlugName( "sermons" , pageData.slug)
                    this.router.navigate(['/sermons'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }else{
                    pageName = pageName.replace(' ', '-');
                    pageName = pageName.toLowerCase();
                    this.router.navigate([pageName]);
                    this.slug.setPage_id(pageName,page_id);
                    this.slug.setSlugName( pageName , pageData.slug);
                    this.router.navigate([pageName], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }
            }
        } else if (page_type === 'folder') {
            if (pageName === 'Home') {
                // for ISM app
                if (this.theme.ch_id === '1000'){
                    pageName = 'ism-home/' + this.theme.ch_id;
                    this.router.navigate([pageName]);
                } else {
                    this.slug.setCh_Id(this.theme.ch_id)
                    pageName = 'home-tabs/home/' + this.theme.ch_id;
                    this.router.navigate([pageName],{ skipLocationChange: true, replaceUrl:false });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                }
            } else {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'folder'
                };

                this.slug.setFolder(page_id)
                this.slug.setSlugName( "folder" , pageData.slug)
                this.event_provider.folderPage(page_id);
                this.router.navigate(['/folder'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            }
        } else if (page_type === 'custom') {
            // saving for highlighing selected item
            this.selected_item_obj = {
                id: page_id,
                url: 'custom-page'
            };
            this.slug.setPage_id("custom-page",page_id);
            this.event_provider.customePage(page_id);
            this.slug.setSlugName( "custom-page" , pageData.slug)
            this.router.navigate(['/custom-page'], { skipLocationChange: true, replaceUrl: true });
            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
        } else if (page_type === 'home') {
            if (this.theme.ch_id === '1000'){
                pageName = 'ism-home/' + this.theme.ch_id;
                this.router.navigate([pageName]);
            } else {
                pageName = 'home-tabs/home/' + this.theme.ch_id;
                this.router.navigate([pageName],{ skipLocationChange: true, replaceUrl:false });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
            }
        } else if (page_type === 'link') {
            let total = this.app_pages.concat(this.web_app_pages);
            let link_page = total.find(x => x.id === page_id);
            if (link_page) {
                let open_external = link_page.open_external;
                if (open_external === 'true') {
                    // this.openurlinbrowser(link_page.url);
                    // var url: any = new URL(window.location.href);
                    let url = JSON.parse(localStorage.getItem("url"));
                    if (url.host.includes("pwabuilder.churchbase.com")) {
                        let navigationExtras: NavigationExtras = {
                            queryParams: {
                                url: link_page.url
                            }
                        };
                        this.slug.setIframe(link_page.url);
                        this.slug.setSlugName( "iframeheader" , pageData.slug);
                        this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                        this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                    } else {
                        this.openurlinbrowser(link_page.url);
                    }
                } else {
                    // var url: any = new URL(window.location.href);
                    let url = JSON.parse(localStorage.getItem("url"));
                    if (url.host.includes("pwabuilder.churchbase.com")) {
                        let navigationExtras: NavigationExtras = {
                            queryParams: {
                                url: link_page.url
                            }
                        };
                        this.slug.setIframe(link_page.url);
                        this.slug.setSlugName( "iframeheader" , pageData.slug)
                        this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                        this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                    } else {
                        this.openurlinapp(link_page.url);
                    }

                }
            }
        }


    }


    newSlugPages(churchId,details){
        if(details.sermons.identify == 'posts_categories'){
            var is_wordpress_enable = localStorage.getItem('is_wordpress_enable')
            var detail = {
                ch: churchId,
                id: details.sermons.id,
                page_id: "",
                lc: is_wordpress_enable
              }
              this.slug.setCustomPost(detail)
              // this.router.navigate(['custom-post-detail']);
              this.slug.setSlugName( "custom-post-detail" , details.sermons.slug )
              this.router.navigate(['custom-post-detail'], { skipLocationChange: true, replaceUrl: true });
              this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([details.sermons.slug])));
        }else if(details.sermons.identify == 'event_multi_schedule_local'){
        
        }else if(details.sermons.identify == 'content_sermons'){
            var id={
                id: details.sermons.id
            }
            this.slug.setSermonDetail(id)
            this.slug.setSlugName( "sermon-detail" , details.sermons.slug )
            this.router.navigate(['sermon-detail'], { skipLocationChange: true, replaceUrl: true });
            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([details.sermons.slug])));
        }else if(details.sermons.identify == 'notes_beta'){
            
        }
           
        
    }

    // inside app on page with done button
    openurlinapp(url){
        if(this.platform.is('android') || this.platform.is('iphone')) {
            this.iab.create(url, '_blank', this.options);
        } else {
            this.iab.create(url, '_self', this.options);
        }
    }

    // in external safari/chrome
    openurlinbrowser(url){
        this.iab.create(url, '_system');
    }
}
