import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {IonicStorageModule} from '@ionic/storage';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {HeaderComponent} from './common/header/header.component';
import {SidebarMenuComponent} from './common/sidebar-menu/sidebar-menu.component';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {SharedCommonModule} from "./common/shared-common.module";
import {CurrencyPipe} from "@angular/common";
import {HeaderMobileComponent} from "./common/header-mobile/header-mobile.component";
import {CommentModalComponent} from "./prayer-wall/comment-modal/comment-modal.component";
import {FormsModule} from "@angular/forms";
import {DatePipe} from '@angular/common';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {LaunchNavigator, LaunchNavigatorOptions} from '@ionic-native/launch-navigator/ngx';
import {SocketIoModule, SocketIoConfig} from 'ngx-socket-io';
import {FilterPopoverComponent} from "./events/filter-popover/filter-popover.component";
import {Push, PushObject, PushOptions} from '@ionic-native/push/ngx';
import {AngularFireModule} from 'angularfire2';
import {AngularFirestoreModule} from 'angularfire2/firestore';
import {Firebase} from '@ionic-native/firebase/ngx';
import {QrcodeModalComponent} from './check-in/qrcode-modal/qrcode-modal.component';
import {SharingModalComponent} from "./bible-chapter/sharing-modal/sharing-modal.component";
import {ConnectDeviceModalComponent} from "./bible-chapter/connect-device-modal/connect-device-modal.component";
import {BibleFilterModalComponent} from "./common/bible-filter-modal/bible-filter-modal.component";
import { Device } from '@ionic-native/device/ngx';
import {Media} from "@ionic-native/media/ngx";
import {SharingModalSermonComponent} from "./sermon-detail/sharing-modal-sermon/sharing-modal-sermon.component";
import { FormBuilder, FormGroup } from '@angular/forms';
import {CreditCardComponent} from "./common/credit-card/credit-card.component";
import {StoryPagePage} from "./story-page/story-page.page";
import {TranslateModule, TranslateLoader} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RECAPTCHA_NONCE} from 'ng-recaptcha';
import {BibleTranslationsComponent} from "./common/bible-translations/bible-translations.component";
import {Ng2SearchPipeModule} from "ng2-search-filter";
import { AlphabetFilterModule } from 'alphabet-filter';
import {EmailComponent} from "./settings/email/email.component";
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { InterceptorService } from 'src/services/interceptor.service';
import { AudioPlayerComponent } from './common/audio-player/audio-player.component';
import { HomePageModule } from './home/home.module';
import  {  NgxEmojiPickerModule  }  from  'ngx-emoji-picker';
import {CommentAlertComponent} from "./common/comment-alert/comment-alert.component";
import { NgxStripeModule } from 'ngx-stripe';
import { StripeModule, StripeBank } from "stripe-angular";
import { GeneralSearchComponent } from './common/general-search/general-search.component';
import { DeleteAccountModalComponent } from './delete-account-modal/delete-account-modal.component';
import { ReportModalComponent } from './common/report-modal/report-modal.component';


const config: SocketIoConfig = {url: 'http://ec2-3-19-201-180.us-east-2.compute.amazonaws.com:3001', options: {}};
 
const firebaseConfig = {
    apiKey: "AIzaSyCDRRacjW4pLIh6aiTJlqACbDbTQCuiQlg",
    authDomain: "churchbasetest-eb38a.firebaseapp.com",
    databaseURL: "https://churchbasetest-eb38a.firebaseio.com",
    projectId: "churchbasetest-eb38a",
    storageBucket: "churchbasetest-eb38a.appspot.com",
    messagingSenderId: "387934331512",
    appId: "1:387934331512:web:97fb1a516ca22e0453438d",
    measurementId: "G-BDXJXSS9E5"
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent, HeaderComponent, SidebarMenuComponent, HeaderMobileComponent, CommentModalComponent, EmailComponent, BibleTranslationsComponent, StoryPagePage, FilterPopoverComponent, QrcodeModalComponent, SharingModalComponent, SharingModalSermonComponent, ConnectDeviceModalComponent, CreditCardComponent, CommentAlertComponent, GeneralSearchComponent , ReportModalComponent , DeleteAccountModalComponent],
    entryComponents: [CommentModalComponent, EmailComponent, QrcodeModalComponent, BibleTranslationsComponent, FilterPopoverComponent, SharingModalComponent, StoryPagePage, SharingModalSermonComponent, ConnectDeviceModalComponent, BibleFilterModalComponent, AudioPlayerComponent, CommentAlertComponent, GeneralSearchComponent , ReportModalComponent , DeleteAccountModalComponent],
    imports: [
        SharedCommonModule,
        BrowserModule,
        RecaptchaV3Module,
        IonicModule.forRoot(),
        AppRoutingModule,
        NgxStripeModule.forRoot(environment.stripe_key),
        StripeModule.forRoot(environment.stripe_key),
        AlphabetFilterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        HttpClientModule,
        IonicStorageModule.forRoot(),
        FormsModule,
        SocketIoModule.forRoot(config),
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFirestoreModule,
        Ng2SearchPipeModule,
        HomePageModule,
        NgxEmojiPickerModule.forRoot()
    ],
    exports: [],
    providers: [
        StatusBar,
        SplashScreen,
        Keyboard,
        CallNumber,
        DatePipe,
        FormBuilder,
        LaunchNavigator,
        InAppBrowser,
        HeaderComponent,
        StripeBank,
        Title,
        Media,
        Push,
        Device,
        Firebase,
        {
            provide: RECAPTCHA_NONCE,
            useValue: 'OekNV2HiFprop4HUCgpbusGR',
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lek-UMjAAAAAPcGdHwsUyupLPHNH90UdpEpHBnn' },
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
          }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
