import { EventProviderService } from 'src/services/event-provider.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Howl } from 'howler';
import { Component, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { IonRange } from '@ionic/angular';

export interface Track {
  name: string;
  path: any;
}

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit, OnChanges {

  @Input() audioUrl: any;
  @Input() isSermon: boolean;
  @Input() index: any;
  soundcloud = false;

  playlist: Track[] = [];
  activeTrack = false;
  player: Howl = null;
  isPlaying = false;
  progress = 0;

  current_sound_id: any;
  current_total_duration: any;
  current_duration: any;
  firstPlay = true;

  @ViewChild('range', { static: false }) range: IonRange;

  constructor(private dom: DomSanitizer, private eventProvider: EventProviderService) {
    this.eventProvider.audioPlayer.subscribe(
      (res) => {
        if (res) {
          if (this.isPlaying) {
            this.isPlaying = false;
            this.activeTrack = true;
            this.player.stop();
          }
        }
      }
    );
    this.eventProvider.stopAudios.subscribe(
      (res) => {
        if (res !== this.index) {
          if (this.isPlaying) {
            this.isPlaying = false;
            this.activeTrack = true;
            this.player.stop();
          }
        }
      }
    );
  }

  start(url: any) {
    let self = this;
    this.eventProvider.stopAudioPlayer(this.index);
    if (this.player) {
      this.player.stop();
    }
    if(url['changingThisBreaksApplicationSecurity']){
      url = url['changingThisBreaksApplicationSecurity'];
    }
    this.player = new Howl({
      src: [url],
      html5: true,
      onplay: () => {
        this.isPlaying = true;
        this.activeTrack = true;
        this.updateProgress();
      },
      onend: () => {

      }
    });

    this.player.once('load', () => {
      // self.current_sound_id = self.player.play();
      // self.player.stop(self.current_sound_id);
      self.current_total_duration = self.intoMinutes(self.player.duration(self.current_sound_id));
      this.firstPlay = false;
      this.isPlaying = false;
    });
  }

  intoMinutes(second) {
    // returns rounded off minutes and seconds
    var minutes = Math.floor(second / 60) || 0;
    var seconds = Math.floor(second - minutes * 60) || 0;
    let min;
    let sec;

    if (minutes < 10){
      min = minutes;
    } else {
      min = minutes.toFixed(2);
    }
    if (seconds < 10){
      sec = '0' + seconds;
    } else {
      sec = seconds;
    }
    return min + ':' + sec;
  }

  togglePlayer(pause) {
    this.isPlaying = !pause;
    this.firstPlay = false;
    this.eventProvider.stopAudioPlayer(this.index);
    if (pause) {
      this.player.pause();
    } else {
      this.player.play();
    }
  }

  seek() {
    let newValue = +this.range.value;
  }

  updateProgress() {
    let seek = Math.floor(this.player.seek());
    this.current_duration = this.intoMinutes(seek);
    this.progress = (seek / this.player.duration()) * 100 || 0;
    setTimeout(() => {
      this.updateProgress();
    }, 100)
  }

  forward() {
    let seek = Math.floor(this.player.seek());
    this.player.seek(seek + 10);
  }

  backward() {
    let seek = Math.floor(this.player.seek());
    this.player.seek(seek - 10);
  }

  ngOnInit() {

  }
  ngOnChanges(): void {
    this.activeTrack = true;
    if(this.audioUrl['changingThisBreaksApplicationSecurity']){
      this.audioUrl = this.audioUrl['changingThisBreaksApplicationSecurity'];
    }
    if (this.isSermon) {
      if (this.audioUrl.includes("soundcloud.com")) {
        this.audioUrl = "https://w.soundcloud.com/player/?url=" + this.audioUrl;
        this.audioUrl = this.dom.bypassSecurityTrustResourceUrl(this.audioUrl);
        this.soundcloud = true;
      }
    } else {
      // this.audioUrl = this.dom.bypassSecurityTrustResourceUrl(this.audioUrl);
      if (this.audioUrl.includes("soundcloud.com")) {
        this.audioUrl = "https://w.soundcloud.com/player/?url=" + this.audioUrl;
        this.audioUrl = this.dom.bypassSecurityTrustResourceUrl(this.audioUrl);
        this.soundcloud = true;
      }
    }

    if (this.audioUrl){
      this.start(this.audioUrl);
    }
  }

}
