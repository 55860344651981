import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/services/api.service';
import { AuthService } from 'src/services/auth.service';
import { EventProviderService } from 'src/services/event-provider.service';
import { LoaderService } from 'src/services/loader.service';
import { ToastService } from 'src/services/toast.service';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
})
export class ReportModalComponent implements OnInit {
  alertMessage: any;
  report_reason: any;
  brief_report_reason: any;
  theme: any;
  ch_id: any;
  report_reason_array = ['violance', 'harrasment', 'self-injury', 'hate-speech']
  constructor(public modalController: ModalController,
    private loader: LoaderService,
    private storage: Storage,
    private Auth: AuthService,
    private toast: ToastService,
    private translate: TranslateService,
    private router: Router,
    private event_provider: EventProviderService,
    private navParams: NavParams,
    private apiService: ApiService
  ) {
    let interval = setInterval(() => {
      this.translate.get('ALERTS').subscribe((res: any) => {
        this.alertMessage = res;
        clearInterval(interval);
      });
    }, 2000)
  }

  ngOnInit() {
    this.getAuthUser();
  }
  async getAuthUser() {
    // getting theme from local storage
    this.theme = await this.storage.get('theme');
    this.ch_id = this.theme.ch_id;
  }
  report() {

    if (this.navParams.data.event === 'report-comment') {
      const body = JSON.stringify({
        id: this.navParams.data.body.id,
        church_id: this.ch_id,
        report_reason: this.report_reason,
        brief_report_reason: this.brief_report_reason,
        method: this.navParams.data.method,
        action: 'reportComment',
        account_donor_id: this.navParams.data.user_donor_id ? this.navParams.data.user_donor_id : ''
      });
      this.apiService.report_newsWall_comment(body).subscribe((res: any) => {
        if (res && res.status) {
          this.toast.presentToastWithOptions((res.message ? res.message : 'Reported!'), (this.alertMessage ? this.alertMessage.Success : 'Success'), 'success');
          this.modalController.dismiss({
            // });
          });
        }
      }, (err: any) => {
        this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.error_occured_try_again : 'Error Occured, Try Again'), (this.alertMessage ? this.alertMessage.error : 'ERROR'), "warning");
      })
    } else if (this.navParams.data.event === 'report') {
      const body = JSON.stringify({
        id: this.navParams.data.body.id,
        church_id: this.ch_id,
        report_reason: this.report_reason,
        brief_report_reason: this.brief_report_reason,
        donor_id: this.navParams.data.user_donor_id ? this.navParams.data.user_donor_id : ''
      });
      this.apiService.report_news(body).subscribe((res: any) => {
        if (res && res.status) {
          this.toast.presentToastWithOptions((res.message ? res.message : 'Reported!'), (this.alertMessage ? this.alertMessage.Success : 'Success'), 'success');
          this.modalController.dismiss({
          });
        }
      }, (err: any) => {
        this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.error_occured_try_again : 'Error Occured, Try Again'), (this.alertMessage ? this.alertMessage.error : 'ERROR'), "warning");
      })
    }

  }

  dismiss() {
    this.modalController.dismiss({
      'cancel': true
    });
  }



}
