import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuController, Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {AuthService} from 'src/services/auth.service';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {LoaderService} from 'src/services/loader.service';
import {LogoServiceService} from '../../../services/logo-service.service';
import {CommonService} from '../../../services/common.service';
import {ToastService} from '../../../services/toast.service';
import {DomSanitizer} from '@angular/platform-browser';
import {IdService} from "../../../services/id.service";
import {EventProviderService} from "../../../services/event-provider.service";
import {Device} from "@ionic-native/device/ngx";
import {InAppBrowser, InAppBrowserOptions} from "@ionic-native/in-app-browser/ngx";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import { isEmpty } from 'rxjs/operators';
import { Location } from '@angular/common';
import { SlugService } from 'src/services/slug.service';

@Component({
    selector: 'app-sidebar-menu',
    templateUrl: './sidebar-menu.component.html',
    styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
    public theme: any;
    logoURL: any;
    sidebarSide = 'side';
    myStuff_subMenu: boolean = false;
    sidebarType = 'overlay';
    enable_home: boolean;
    ch_id: any;
    username: any;
    profile_picture: any;
    app_pages: any;
    web_app_pages: any;
    header_left_app_pages: any;
    userLoggedIn: boolean = false;
    check: any = 'heart';
    churchname: any;
    gave_theme: boolean = false;
    is_device: boolean = false;
    home_tabs: any;
    alertMessage: any;
    home_name_ism = 'Home';
    hide_mycontributions: boolean = false;
    showHeaderWithoutMenu: boolean = false;
    mobileMenuItemActiveArray: any = [];
    webMenuItemActiveArray: any = [];
    selected_item_id: any = '';
    selected_item_obj: any = {
        id: '',
        url: ''
    };
    home_title: any = "Home";

    options : InAppBrowserOptions = {
        closebuttoncaption : 'Close',
        closebuttoncolor: '#FFFFFF',
        disallowoverscroll: 'no',
        hidenavigationbuttons: 'no',
        toolbar: 'yes',
        toolbarposition: 'top',
        location: 'yes'
    };

    IMAGE_BASE_URL: any = environment.profile_img_base_url;
    path_origin: any = environment.devdomain_name;

    constructor(
        private menu: MenuController,
        private storage: Storage,
        private Auth: AuthService,
        private router: Router,
        private loader: LoaderService,
        private logoService: LogoServiceService,
        private common: CommonService,
        private toast: ToastService,
        private dom: DomSanitizer,
        private idService: IdService,
        private event_provider: EventProviderService,
        private platform: Platform,
        private device: Device,
        private iab: InAppBrowser,
        public route:ActivatedRoute,
        private translate: TranslateService,
        public slug:SlugService,
        public location:Location,
    ) {
        // get translated alert message
        let interval = setInterval(() => {
            this.translate.get('ALERTS').subscribe( (res: any) => {
                this.alertMessage = res;
                clearInterval(interval);
            });
        }, 3000);
        // this.event_provider.getChurchData.subscribe(res => {
        // });
    }

    async toggleMenu() {
        // toggle sidebar menu
        this.menu.toggle('sidebar');
    }

    ngOnInit() {
        // get design
        // 2 seconds timer to stop loading after everything is loaded completely
        this.event_provider.changeAppLanguage.subscribe((res: any) => {
            if(res){
                this.getDesign();
                this.sidebarMobile();
            }
        })
        let interval = setInterval(() => {
            this.getDesign();
            clearInterval(interval);
        }, 5000);

        // set sidebar according to Mobile View
        this.sidebarMobile();

        // getting user from local storage
        this.getUser();

        // checking if using on device
        this.checkDeviceStatus();


        this.event_provider.updateSidebarMenu.subscribe(value => {              // ----------- test
            this.getUser();
        });

        this.event_provider.updateUrl.subscribe(value => {
            this.selected_item_id = '';
        });


        this.event_provider.changeDarkModeAgain.subscribe(value => {              // listening event
            if (value.value) {
                this.logoURL = this.theme.side_menu_dark_logo ? this.theme.side_menu_dark_logo : this.theme.logo ;
            } else {
                this.logoURL = this.theme.logo;
            }
        });

        this.event_provider.isHomeEnable.subscribe(value => {              // listening event
            this.enable_home = value.value.is_enable_menu;
        });

        this.event_provider.expandMyAccountSidebar.subscribe(value => {              // listening event
            this.menu.toggle();
            this.myStuff_subMenu = true;
        });

        // event subscription to show header
        this.event_provider.showHeaderWithoutMenu.subscribe(value => {              // listening event
            this.showHeaderWithoutMenu = value;
        });
    }

    navigateForExternalGiving(page){
        if (page === 'my-contributions'){
            this.slug.setmode("giving");
            this.router.navigate(['my-contributions']);
        } else if (page === 'my-events'){
            this.slug.setmode("giving");
            this.router.navigate(['my-events']);
        } else if (page === 'my-groups'){
            this.slug.setmode("giving");
            this.router.navigate(['my-groups']);
        } else if (page === 'my-volunteering'){
            this.slug.setmode("giving");
            this.router.navigate(['my-volunteering']);
        } else if (page === 'my-profile'){
            this.slug.setmode("giving");
            this.router.navigate(['profile']);
        }
    }

    checkDeviceStatus(){
        this.is_device = true;
    }

    changeTheme(color: string) {
        // change color variable from variable.scss file
        document.documentElement.style.setProperty('--primary-color', color);
    }

    async getDesign() {
        // checking if url has home, then we get theme according to ID SERVICE
        let url = JSON.parse(localStorage.getItem("url"));
        let url_is_home = url.pathname.includes("/home");

        // getting theme from local storage
        this.event_provider.getChurchData.subscribe(res => {
            
            let body;
            if (!(Object.keys(res).length === 0) && !url_is_home) {
                this.ch_id = res.church.ch_id;
                if (!this.gave_theme) {
                    // updating theme wherever this event is subscribed
                    this.event_provider.gettheme( res );               // calling event
                }
            } else {
                this.ch_id = this.idService.getId();
                // this.ch_id = res.church.ch_id;
            }

            if (this.ch_id) {
                if(this.ch_id === '466' || this.ch_id === '708' || this.ch_id === '1000'){
                    this.hide_mycontributions = false;
                } else {
                    this.hide_mycontributions = true;
                }
                body = JSON.stringify({id: this.ch_id});
                // fetching latest theme with church ID from idService
                // this.event_provider.getChurchData.subscribe(res => {
                    // debugger;
                    if (!(Object.keys(res).length === 0)) {
                        this.theme = res.church;
                        this.ch_id = res.church.ch_id;
                        // setting color
                        let theme_color = this.theme.color ? this.theme.color : '#302f34';
                        let url = new URL(window.location.href);
                        let new_path = url.pathname.split('/');
                        if(new_path[1] != 'give-new'){
                            this.changeTheme(theme_color);
                        }

                        // sending theme to bottom menu
                        if (!this.gave_theme) {
                            this.event_provider.gettheme( res.church );               // calling event
                        }

                        // this.event_provider.getapppages( res.apppages );               // calling event

                        // to check which tab is active
                        if (this.theme.home_tabs){
                            this.home_tabs = JSON.parse(this.theme.home_tabs);
                        }

                        // ============================ setting logo ================================================
                        if (res.church.app_style === '2') {
                            if (res.church.side_menu_dark_logo !== '' && res.church.side_menu_dark_logo !== null) {
                                this.logoURL = res.church.side_menu_dark_logo;
                            } else {
                                if (this.logoURL) {
                                    this.logoURL = res.church.logo;
                                } else {
                                    this.churchname = res.church.church_name;
                                }
                            }
                        } else if (this.theme.app_style === '1') {
                            if (res.church.side_menu_logo !== '' && res.church.side_menu_logo !== null) {
                                this.logoURL = res.church.side_menu_logo;
                            } else {
                                if (this.logoURL) {
                                    this.logoURL = res.church.logo;
                                } else {
                                    this.churchname = res.church.church_name;
                                }
                            }
                        }
                        // ============================ setting logo ================================================

                        // saving latest app pages in local storage
                        // this.common.store_app_pages(res.apppages, 'SIDEBAR').then(
                        //     (res: any) => {
                                // this.event_provider.getAppPages.subscribe(res => {
                                //     if (res) {
                                        if (this.ch_id === '1000'){
                                            let check_home = res.apppages.find( x => x.page_type === 'home');
                                            if (check_home){
                                                this.home_name_ism = check_home.title;
                                            }
                                        }

                        // HOME TITLE TO SHOW ON NAV BAR
                        let one_page = res.apppages.find(x => x.page_type === 'home');
                        if(one_page) {
                            this.home_title = one_page.title;
                        }

                                    // seperating app pages, web pages
                                    this.app_pages = res.apppages.filter(x => x.activated === 'true' && x.show_menu === 'true' && x.show_app === 'true' && x.page_type !== 'home');
                                    this.web_app_pages = res.apppages.filter(x => x.activated === 'true' && x.show_menu === 'true' && x.show_pwa === 'true' && x.page_type !== 'home');
                                    let result = res.apppages.filter(x => x.activated === 'true' && x.show_pwa === 'true' && x.page_type !== 'home' && !x.show_menu);
        
                                    // setting mobile sidebar
                                    let web_length = this.web_app_pages.length;
        
                                    // setting LEFT pages from header (after first 5)
                                    let start;
                                    if (result.length > 4) {
                                        this.header_left_app_pages = [];
                                        start = 5;
                                        for (let i = start; i < result.length; i++) {
                                            for (let j = 0; j < web_length; j++) {
                                                if (this.web_app_pages[j].name !== result[i].name) {
                                                    this.web_app_pages.push(result[i]);
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                // }
                                // });
                        //     }
                        // );
                        
                    }
                // });
            }
        });
    }

    async logOutUser() {
        this.toggleMenu();
        this.loader.presentLoading().then(() => {
            this.Auth.logoutUser();
            this.event_provider.userlogout();               // calling event
            this.event_provider.refresh_home();               // calling event
            this.logoService.setStatus(false);
            if (this.theme.ch_id === '1000'){
                this.router.navigate(['bible-chapter']);
            } else {
                let login_url = '/home-tabs/home/' + this.theme.ch_id;
                this.router.navigate([login_url]);
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
            }
            this.loader.stopLoading();
        });
    }

    sidebarMobile() {
        // change sidebar according to Mobile View
        const mq = window.matchMedia('(max-width: 991px)');
        if (mq.matches) {
            var lang:any =  localStorage.getItem('app_language');

            if (lang) {
                if(lang == 'ar'){
                    this.sidebarSide = 'end';
                    this.sidebarType = 'overlay';

                }else{
                    this.sidebarSide = 'start';
                    this.sidebarType = 'push';
                }
            }else{
                this.sidebarSide = 'start';
                this.sidebarType = 'push';
            }
        } else {
            this.sidebarSide = 'end';
            this.sidebarType = 'overlay';
        }
    }

    getUser() {
        // getting user from local storage
        this.storage.get('user').then((val: any) => {
                if (val != null && val != 'guest') {
                    this.userLoggedIn = true;
                    this.profile_picture = val.user.photo_profile ? this.dom.bypassSecurityTrustUrl(this.IMAGE_BASE_URL + val.user.photo_profile) : ''; // to save profile pic
                    this.username = val.user.first_last_name;
                    console.log(this.username)
                } else {
                    this.userLoggedIn = false;
                }
            }
        );
    }

    navigate_ism(){
        this.router.navigate(['ism-teachings/bible']);
    }

    // Navigating to HOME
    gotoHome() {
        if (this.enable_home && !this.showHeaderWithoutMenu) {
            // for ISM app
            let pageName;
            if(this.theme){
                if (this.theme.ch_id === '1000'){
                    pageName = 'ism-home/' + this.theme.ch_id;
                    this.router.navigate([pageName]);
                } else {
                    pageName = 'home-tabs/home/' + this.theme.ch_id;
                    this.router.navigate([pageName],{ skipLocationChange: true, replaceUrl:false });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                }
            } else{
                pageName = 'home-tabs/home/' + JSON.parse(localStorage.getItem('church_data')).church.ch_id;
                this.router.navigate([pageName],{ skipLocationChange: true, replaceUrl:false });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
            }
            this.menu.toggle('sidebar');
        } else {
            this.menu.toggle('sidebar');
        }

    }

    // navigating side bar menu links
    navigateToPage(page_id, page_type, pageName, pagehtml, pageData) {
        this.selected_item_id = page_id;
        if (page_type === 'page') {
            if (pageName === 'Audio') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                  id: page_id,
                  url: 'audio-archive'
                };
                this.slug.setPage_id("audio-archive",page_id);
                this.slug.setSlugName( "audio-archive" , pageData.slug );
                this.router.navigate(['audio-archive'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Video') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'video-archive'
                };
                this.slug.setPage_id("video-archive",page_id);
                this.slug.setSlugName( "video-archive" , pageData.slug );
                this.router.navigate(['video-archive'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Events') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'events'
                };
                this.slug.setPage_id("events",page_id);
                this.slug.setSlugName( "events" , pageData.slug )
                this.router.navigate(['events'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Donations') {
                let total = this.app_pages.concat(this.web_app_pages);
                let link_page = total.find(x => x.id === page_id);
                let url = JSON.parse(localStorage.getItem("url"));

                if (link_page) {
                    let open_external = link_page.open_external;
                    if (open_external === 'true') {
                        let donations_url = '';
                        if (link_page.html) {
                            donations_url = url.origin + "/give-new/" + link_page.html;
                        } else {
                            donations_url = url.origin + "/give-new/" + this.ch_id;
                        }

                        if(this.platform.is("mobile") || this.platform.is("pwa")){
                            if (url.host.includes("pwabuilder.churchbase.com")) {
                                let navigationExtras: NavigationExtras = {
                                    queryParams: {
                                        url: link_page.url
                                    }
                                };
                                this.slug.setIframe(link_page.url)
                                this.slug.setSlugName( "iframeheader" , pageData.slug);
                                this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                            }
                            else{
                                this.openurlinbrowser(donations_url);
                            }
                        } else {
                            this.openurlinbrowser(donations_url);
                        }
                    } else {
                        this.router.navigateByUrl('/my-contribute/'+link_page.html);
                    }
                }
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'my-contribute'
                };

            } else if (pageName === 'Campaign Registration') {
                this.router.navigate(['/campaign-registration/' + this.theme.ch_id + '/' + pagehtml]);
            } else if (pageName === 'iFrame') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'in-app-iframe'
                };
                this.slug.setPage_id("in-app-iframe",page_id);
                this.slug.setSlugName( "in-app-iframe/" , pageData.slug);
                this.router.navigate(['in-app-iframe/'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Custom Post') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'custom-post'
                };
                this.slug.setPage_id("custom-post",page_id);
                this.slug.setSlugName( "custom-post" , pageData.slug);
                this.router.navigate(['custom-post/'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Feed') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'feed-tab'
                };
                if ((this.home_tabs && this.home_tabs[1].status === 'true') || this.theme.ch_id === '1000'){
                    this.router.navigate(['/feed-tab']);
                } else {
                    this.router.navigate(['home-tabs/feed-tab']);
                }
            } else if (pageName === 'Chat') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'chat'
                };
                if ((this.home_tabs && this.home_tabs[2].status === 'true') || this.theme.ch_id === '1000'){
                    this.router.navigate(['/chat']);
                } else {
                    this.router.navigate(['home-tabs/chat']);
                }
            } else if (pageName === 'Live Stream') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'live-stream'
                };
                this.slug.setCh_Id(this.theme.ch_id);
                this.slug.setSlugName( "live-stream" , pageData.slug);
                this.router.navigate(['live-stream'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Form') {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'form'
                };
                if(localStorage.getItem('is_wordpress_enable')) {
                    let form_page = this.app_pages.filter(x => x.id === page_id);
                    if (form_page.length > 0) {
                        this.router.navigate(['/forms/' + form_page[0].html]);
                    }
                } else {
                    this.slug.setForm(page_id);
                    this.slug.setSlugName( "form" , pageData.slug);
                    this.router.navigate(['/form'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }
            } else if (pageName === 'Bible'){
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'bible'
                };
                if(this.theme.ch_id == '1000'){
                    this.router.navigate(['/bible-chapter']);
                } else {
                    pageName = pageName.replace(' ', '-');
                    pageName = pageName.toLowerCase();

                    // saving for highlighing selected item
                    this.selected_item_obj = {
                        id: page_id,
                        url: pageName
                    };
                    this.router.navigate([pageName]);
                }
            } else if (pageName === 'Community'){
                this.slug.setPage_id("community",page_id);
                this.slug.setSlugName( "community" , pageData.slug);
                this.router.navigate(['/community'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Newsletter') {
                this.slug.setCh_Id(this.theme.ch_id);
                this.slug.setSlugName( "newsletter" , pageData.slug);
                this.router.navigate(['/newsletter/' ], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Contact Us') {
                this.slug.setCh_Id(this.theme.ch_id)
                this.slug.setSlugName( "contact-us" , pageData.slug)
                this.router.navigate(['/contact-us/' , {page_id: page_id}]);
            } else {
                if(pageName === 'Sermon Series'  ){
                    var data={
                        id:JSON.parse(pageData.series_id),
                        ch_id:this.ch_id,
                        page_id: page_id
                    }
                    this.event_provider.allsermonSeries(data);
                    this.slug.setAllSermon(data)
                    this.slug.setSlugName( "all-sermons" , pageData.slug )
                    this.router.navigate(['all-sermons'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }else if( pageName === 'Sermons' ){
                    this.event_provider.sermonPage(page_id)
                    this.slug.setPage_id("sermons",page_id)
                    this.slug.setSlugName( "sermons" , pageData.slug)
                    this.router.navigate(['/sermons'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }else{
                    pageName = pageName.replace(' ', '-');
                    pageName = pageName.toLowerCase();
                    this.router.navigate([pageName]);
                }
            }
        } else if (page_type === 'folder') {
            if (pageName === 'Home') {
                // for ISM app
                if (this.theme.ch_id === '1000'){
                    pageName = 'ism-home/' + this.theme.ch_id;
                    this.router.navigate([pageName]);
                } else {
                    this.slug.setCh_Id(this.theme.ch_id);
                    pageName = 'home-tabs/home/' + this.theme.ch_id;
                    this.router.navigate([pageName],{ skipLocationChange: true, replaceUrl:false });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                }
            } else {
                // saving for highlighing selected item
                this.selected_item_obj = {
                    id: page_id,
                    url: 'folder'
                };
                this.slug.setFolder(page_id);
                this.slug.setSlugName( "folder" , pageData.slug);
                this.event_provider.folderPage(page_id);
                this.router.navigate(['/folder'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            }
        } else if (page_type === 'custom') {
            // saving for highlighing selected item
            this.selected_item_obj = {
                id: page_id,
                url: 'custom-page'
            };
            this.slug.setPage_id("custom-page",page_id);
            this.event_provider.customePage(page_id);
            this.slug.setSlugName( "custom-page" , pageData.slug);
            this.router.navigate(['/custom-page'], { skipLocationChange: true, replaceUrl: true });
            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
        } else if (page_type === 'home') {
            if (this.theme.ch_id === '1000'){
                pageName = 'ism-home/' + this.theme.ch_id;
                this.router.navigate([pageName]);
            } else {
                pageName = 'home-tabs/home/' + this.theme.ch_id;
                this.router.navigate([pageName],{ skipLocationChange: true, replaceUrl:false });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
            }
        } else if (page_type === 'link') {
            let total = this.app_pages.concat(this.web_app_pages);
            let link_page = total.find(x => x.id === page_id);
            if (link_page) {
                let open_external = link_page.open_external;
                if (open_external === 'true') {
                    // this.openurlinbrowser(link_page.url);
                    let url = JSON.parse(localStorage.getItem("url"));
                    if (url.host.includes("pwabuilder.churchbase.com")) {
                        let navigationExtras: NavigationExtras = {
                            queryParams: {
                                url: link_page.url
                            }
                        };
                        this.slug.setIframe(link_page.url);
                        this.slug.setSlugName( "iframeheader" , pageData.slug);
                        this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                        this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                    } else {
                        this.openurlinbrowser(link_page.url);
                    }
                } else {
                    let url = JSON.parse(localStorage.getItem("url"));
                    if (url.host.includes("pwabuilder.churchbase.com")) {
                        let navigationExtras: NavigationExtras = {
                            queryParams: {
                                url: link_page.url
                            }
                        };
                        this.slug.setIframe(link_page.url);
                        this.slug.setSlugName( "iframeheader" , pageData.slug);
                        this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                        this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                    } else {
                        this.openurlinapp(link_page.url);
                    }
                    
                }
            }
        }

        // closing side menu after navigation
        this.toggleMenu();

    }

    // inside app on page with done button
    openurlinapp(url){
        if(this.platform.is('android') || this.platform.is('iphone')) {
            this.iab.create(url, '_blank', this.options);
        } else {
            this.iab.create(url, '_self', this.options);
        }
    }

    // in external safari/chrome
    openurlinbrowser(url){
        this.iab.create(url, '_system');
    }
}
