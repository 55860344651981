import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { throttleTime } from 'rxjs/operators';
import { IdService } from './id.service';

@Injectable({
    providedIn: 'root'
})
export class SlugService {

    church_ID:any;
    domainURl:any;
    mode:any;
    page_id:any;
    iframeUrl:any;
    ch_id:any;
    form_id:any;
    folder_id:any;
    history=[];
    theme:any;

    constructor(public router:Router, public location:Location, public idService:IdService) {
        this.theme = JSON.parse(localStorage.getItem('church_data'));
        // //console.log(this.theme.ch_id);
         if(this.theme && this.theme.church && this.theme.church.ch_id){
           this.ch_id = this.theme.church.ch_id
         }
        var history=[]
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.history.push(event.urlAfterRedirects)
            }
          })
       }
  goback(){
    //console.log(this.history , "totallll" ,this.history.length -1 )
      this.history.splice(this.history.length-1 , 1)
      //console.log(this.history[this.history.length-1])
      if(this.history[this.history.length-1] != "/home-tabs/home/"+this.ch_id || this.history[this.history.length-1] != "/home-tabs/home"){
        this.getSlugName(this.history[this.history.length-1]).then((res:any)=>{
          //console.log(res,"ressssss");
          this.router.navigate([res.pageName], { skipLocationChange: true, replaceUrl: true });
          this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([res.slugName])));
          this.history.splice(this.history.length-1 , 1)
          //console.log(this.history)
        }) .catch((page) => { 
          //console.log(page)
          if(page == "/home-tabs/home/"+this.ch_id || page == "/home-tabs/home"){
           //console.log("enterrrrrr " , page != "/home-tabs/home/"+this.ch_id)
            this.router.navigate(['/home-tabs/home'], { skipLocationChange: true, replaceUrl: true });
            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree(['/'])));
            this.history.splice(this.history.length-1 , 1)
          }else{
            //console.log("enterrrrrr " , page !== "/home-tabs/home/"+this.ch_id , page !== "/home-tabs/home")
            if(this.history.length > 0){
                this.router.navigate([page], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([page])));
              this.history.splice(this.history.length-1 , 1)
              }else{
                this.router.navigate(['/home-tabs/home/'+this.ch_id], { skipLocationChange: true, replaceUrl: true });
              this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree(['/'])));
              }
          }
        
          //console.log(this.history)
      }); 
      }else{
        this.router.navigate(['/home-tabs/home'+this.ch_id], { skipLocationChange: true, replaceUrl: true });
        this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree(['/'])));
      }

      

  
  }
  
  setSlugName(page , slug){
    var slugDetail ={
      pageName: page,
      slugName: slug
    }
    //console.log(slugDetail)
    localStorage.setItem("slug/"+page , JSON.stringify(slugDetail))
  }
  getSlugName(page){
    //console.log(page , JSON.parse(localStorage.getItem("slug"+page)) )
    return new Promise((resolve, reject) => { 
      if(JSON.parse(localStorage.getItem("slug"+page))){
        //console.log(JSON.parse(localStorage.getItem("slug"+page)))
        resolve(JSON.parse(localStorage.getItem("slug"+page)))
      }else{
        reject(page)
      }
       
  }) 
    // return  JSON.parse(localStorage.getItem(page))  
    // if(slugInfo.page == slugInfo.slug){
    //   return slugInfo
    // }else{
    //   return slugInfo
    // }
    // return localStorage.getItem(page);
  }

    setmode(mode){
        localStorage.setItem("mode", mode);
    }

    getmode(){
        return localStorage.getItem("mode");
    }

    

    setPage_id(pageName ,page_id){
        localStorage.setItem(pageName,page_id )
    }
    getPage_id(pageName){
        return localStorage.getItem(pageName);
    }

    setIframe(iframeUrl){
        localStorage.setItem("iframeUrl", iframeUrl)
    }
    getIframe(){
        return localStorage.getItem("iframeUrl")
    }

    setCh_Id(ch_id){
        localStorage.setItem("ch_id", ch_id)
    }
    getCh_Id(){
        return localStorage.getItem("ch_id")
    }


    setFolder(folder_id){
        localStorage.setItem("folder_id", folder_id);
    }
    getFolder(){
        return localStorage.getItem("folder_id")
    }

    setForm(form_id){
        localStorage.setItem("form_id", form_id)
    }
    getForm(){
        return localStorage.getItem("form_id");
    }

    setEventDetail(details){
        localStorage.setItem("event_detail", JSON.stringify(details))
    }
    getEventDetail(){
        return JSON.parse(localStorage.getItem("event_detail"))
    }

    setSermonDetail(details){
        localStorage.setItem("sermon_detail", JSON.stringify(details))
    }
    getSermonDetail(){
        return JSON.parse(localStorage.getItem("sermon_detail"))
    }

    setAllSermon(details){
        localStorage.setItem("all_sermon", JSON.stringify(details))
    }
    getAllSermon(){
        return JSON.parse(localStorage.getItem("all_sermon"))
    }

    setChatDetail(details){
        localStorage.setItem("chat_detail", JSON.stringify(details))
    }
    getChatDetail(){
        return JSON.parse(localStorage.getItem("chat_detail"))
    }

    setLocation(detail){
        localStorage.setItem("locationData", JSON.stringify(detail))
    }
    getLocation(){
        return JSON.parse(localStorage.getItem("locationData"))
    }

    setVideoDetail(detail){
        localStorage.setItem("videoDetail", JSON.stringify(detail))
    }
    getVideoDetail(){
        return JSON.parse(localStorage.getItem("videoDetail"))
    }

    setVideoArchieveDetail(detail){
        localStorage.setItem("videoArchieve-detail", JSON.stringify(detail))
    }
    getVideoArchieveDetail(){
        return JSON.parse(localStorage.getItem("videoArchieve-detail"))
    }

    setSermonNotes(detail){
        localStorage.setItem("sermonNotes", JSON.stringify(detail))
    }
    getSermonNotes(){
        return JSON.parse(localStorage.getItem("sermonNotes"))
    }

    setCustomPost(detail){
        localStorage.setItem("customPost", JSON.stringify(detail))
    }
    getCustomPost(){
        return JSON.parse(localStorage.getItem("customPost"))
    }

    eventGivingId(id){
        localStorage.setItem("event-giving", JSON.stringify(id))
    }
    getEventGivingId(){
        return JSON.parse(localStorage.getItem("event-giving"))
    }
    setGroupDetail(detail){
        localStorage.setItem("groupDetail", JSON.stringify(detail))
    }
    getGroupDetail(){
        return JSON.parse(localStorage.getItem("groupDetail"))
    }
    setmyEventData(data){
        localStorage.setItem("my-eventDetail", JSON.stringify(data))
    }
    getmyEventData(){
        return JSON.parse(localStorage.getItem("my-eventDetail"))
    }
    setVolunteeringDetail(data){
        localStorage.setItem("volunteering-detail", JSON.stringify(data))
    }
    getVolunteeringDetail(){
        return JSON.parse(localStorage.getItem("volunteering-detail"))
    }

}
