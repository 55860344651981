import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';
import { Router } from '@angular/router';
import { EventProviderService } from './event-provider.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  userData: any;
  alertMessage: any;
  lang: any;
  key: string = 'n0TKP4K0#1^Vpm@%wwvY';
  constructor(private storage: Storage, private apiService: ApiService,
     private translateService: TranslateService,
      private toast: ToastService, private router: Router,private event_provider:EventProviderService) {
    // get translated alert message
    let interval = setInterval(() => {
      this.translateService.get('ALERTS').subscribe((res: any) => {
        this.alertMessage = res;
        clearInterval(interval);
      });
    }, 2000)
  }


  isJson(str) {
    try {
         JSON.parse(str);
         return true;
    } catch (e) {
        return false;
    }
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('auth');
    const refresh_token = localStorage.getItem('refresh_token');
    const authToken = localStorage.getItem('apiAuthToken');

    if(authToken && request.url.includes('sendOTP')){
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          // "Authorization": "Bearer "+authToken
        },
        body: (request.url.includes('sendOTP')) ? (<any>window).CryptoJSAesJson.encrypt((JSON.stringify({ ...request.body, 'auth': 'Bearer ' + authToken, 'authorization': 'Bearer ' + authToken })), this.key) :  ''
      });
    }
    //  authorization: 'Bearer ' + this.loginData.auth.refresh_token
    else if (token && (((request.url.match('manage.churchbase.com')) || (request.url.match('devmanage.churchbase.com'))) && !(request.url.match('visitsAnalytics')) && !(request.url.match('contentcms/uploadFile')))) {
      this.lang = localStorage.getItem('app_language');
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        url: (request.url + '/' + this.lang),
        body: (request.url.includes('sendOTP') || request.url.includes('process_payment') || request.url.includes('getGuestToken') || request.url.includes('update_creditcard') || request.url.includes('get_eptpl') || request.url.includes('get_donorsources') || request.url.includes('delete_source') || request.url.includes('get_donations') || request.url.includes('donationstat') || request.url.includes('stopsubscription') || request.url.includes('get_subscriptions') || request.url.includes('guestcreatepledge')) ? (<any>window).CryptoJSAesJson.encrypt(JSON.stringify({ ...request.body, 'auth': 'Bearer ' + token, 'authorization': 'Bearer ' + refresh_token }), this.key) : this.isJson(request.body) ? JSON.stringify({ ...JSON.parse(request.body), 'auth': 'Bearer ' + token, 'authorization': 'Bearer ' + refresh_token, 'app_lang': this.lang ? this.lang : 'en' }) : JSON.stringify({ ...request.body, 'auth': 'Bearer ' + token, 'authorization': 'Bearer ' + refresh_token, 'app_lang': this.lang ? this.lang : 'en' })

      });
    }

    else if(request.url.match('churchbase.site/category') || request.url.match('churchbase.io/category')){
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        url: (request.url),
        body: JSON.stringify({ ...request.body})
      });
    } else if(!token && ((request.url.match('manage.churchbase.com')) || (request.url.match('devmanage.churchbase.com'))) && !(request.url.match('visitsAnalytics')) && !(request.url.match('contentcms/uploadFile'))){
        this.lang = localStorage.getItem('app_language');
        request = request.clone({
        setHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        url: (request.url + '/' + this.lang),
        body: (request.url.includes('sendOTP') || request.url.includes('process_payment') || request.url.includes('getGuestToken') || request.url.includes('update_creditcard') || request.url.includes('get_eptpl') || request.url.includes('get_donorsources') || request.url.includes('delete_source') || request.url.includes('get_donations') || request.url.includes('donationstat') || request.url.includes('stopsubscription') || request.url.includes('get_subscriptions') || request.url.includes('guestcreatepledge')) ? (<any>window).CryptoJSAesJson.encrypt(JSON.stringify({ ...request.body, 'app_lang': this.lang ? this.lang : 'en'}), this.key) : this.isJson(request.body) ? JSON.stringify({ ...JSON.parse(request.body), 'app_lang': this.lang ? this.lang : 'en'}) :JSON.stringify({ ...(request.body), 'app_lang': this.lang ? this.lang : 'en'})
      });
    } else if(!(request.url.match('app.churchprayerwall.com'))) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        url: (request.url),
          body: (request.url.includes('sendOTP') || request.url.includes('process_payment') || request.url.includes('getGuestToken') || request.url.includes('update_creditcard') || request.url.includes('get_eptpl') || request.url.includes('get_donorsources') || request.url.includes('delete_source') || request.url.includes('get_donations') || request.url.includes('donationstat') || request.url.includes('stopsubscription') || request.url.includes('get_subscriptions') || request.url.includes('guestcreatepledge')) ? (<any>window).CryptoJSAesJson.encrypt(JSON.stringify({ ...request.body}), this.key) : this.isJson(request.body) ? JSON.stringify({ ...JSON.parse(request.body),'app_lang': this.lang ? this.lang : 'en'}) : JSON.stringify({ ...request.body, 'app_lang': this.lang ? this.lang : 'en'})

      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({

        setHeaders: {
          'content-type': 'application/json'
        }
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && (error.error.code === 'access_token_expired' || error.error.code === "access_token_not_found")) {
          this.storage.get('user').then(res => {
            if (res != null && res != 'guest') {
              this.userData = res;
              const data = {
                auth: 'Bearer ' + this.userData.auth.access_token,
                authorization: 'Bearer ' + this.userData.auth.refresh_token
              };
              this.apiService.updateToken(JSON.stringify(data)).subscribe(res => {
                this.userData.auth.access_token = res['access_token'];
                this.userData.auth.refresh_token = res['refresh_token'];
                this.storage.set('user', this.userData);
                localStorage.setItem('auth', this.userData.auth.access_token);
                localStorage.setItem('refresh_token', this.userData.auth.refresh_token);
                this.event_provider.updatedToken(res['refresh_token'],error.url);
              }, (err: any) => {
                if(err.error.http_code === 403 && err.error.message === 'you need to re-login') {
                  this.toast.presentToastWithOptions( err.error.message , 'ERROR', "warning");
                  this.router.navigate(['/login']);
                } else {
                  this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.error_occured_try_again : 'Error Occured, Try Again'), (this.alertMessage ? this.alertMessage.error : 'ERROR'), "warning");
                }
              });
            }
          });

        } else if((error.error.code === 'access_token_expired' || error.error.code === "access_token_not_found") && !error.url.includes('get_donorsources')){
          this.storage.get('user').then(res => {
            if (res != null && res != 'guest') {
              this.userData = res;
              const data = {
                auth: 'Bearer ' + this.userData.auth.access_token,
                authorization: 'Bearer ' + this.userData.auth.refresh_token
              };
              this.apiService.updateToken(JSON.stringify(data)).subscribe(res => {
                this.userData.auth.access_token = res['access_token'];
                this.userData.auth.refresh_token = res['refresh_token'];
                this.storage.set('user', this.userData);
                localStorage.setItem('auth', this.userData.auth.access_token);
                localStorage.setItem('refresh_token', this.userData.auth.refresh_token);
                this.event_provider.updatedToken(res['refresh_token'],error.url);
              }, (err: any) => {
                if(err.error.http_code === 403 && err.error.message === 'you need to re-login') {
                  this.toast.presentToastWithOptions( err.error.message , 'ERROR', "warning");
                  this.router.navigate(['/login']);
                } else {
                  this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.error_occured_try_again : 'Error Occured, Try Again'), (this.alertMessage ? this.alertMessage.error : 'ERROR'), "warning");
                }
              });
            }
          });
        } else if (error.status != 404 && error.status != 500 && error.status != 403 && !(error.error.code === 'access_token_expired' || error.error.code === "access_token_not_found")) {
          if(!error.url.includes('analytics') && !error.url.includes('visitsAnalytics')  && !error.url.includes('googleseofields') && !error.url.includes('wasabisys.com') && !error.url.includes('dbt.io')){
            this.router.navigate(['/login']);
          }
        }
        return throwError(error);
      }));
  }

}
