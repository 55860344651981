import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../environments/environment";
import {Storage} from '@ionic/storage';
import {Observable, of, Subject} from "rxjs";
import {EventProviderService} from "./event-provider.service";
import { catchError, tap } from 'rxjs/operators';
import {PagePasswordModalComponent} from "../app/common/page-password-modal/page-password-modal.component";
import {ModalController} from "@ionic/angular";
import {Location} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    _headers: HttpHeaders;

    is_home_enable = {
      is_enable : true,
      next_page : '',
      is_enable_menu: true
    };
    appPages: any;
    pathSave:any;
    currentPath:any;

    constructor(
        private http: HttpClient,
        private storage: Storage,
        private event_provider: EventProviderService,
        public modalController: ModalController,
        private location: Location
    ) {
        this._headers = new HttpHeaders();
        this._headers = this._headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }

    getTheme(body) {
        let api = environment.api_end_point + '/churchs/church';
        let _body = JSON.parse(body);
        _body.updated_data = true;
        return this.http.post(api, _body, {headers: this._headers});
    }

    async getChurchData(){
        if(localStorage.getItem('church_data')){
            return JSON.parse(localStorage.getItem('church_data'));
        } else {
            let interval = await setInterval(async() => {
                clearInterval(interval);
                return await this.reCallChurchData();
            }, 2000)
        }
    }

    async reCallChurchData(){
        if(localStorage.getItem('church_data')){
            return JSON.parse(localStorage.getItem('church_data'));
        } else {
            // return this.reCallChurchData();
            let interval = await setInterval(async () => {
                clearInterval(interval);
                return await this.getChurchData();
            }, 2000)
        }
    }

    log(arg0: string): void {
        throw new Error(arg0);
    }
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
    
          // TODO: better job of transforming error for user consumption
          this.log(`${operation} failed: ${error.message}`);
    
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

    getSEO(church_id) {
        let api = environment.api_end_point + '/churchs/googleseofields/'+church_id;
        return this.http.get(api, {headers: this._headers});
    }

    async storeTheme(data) {
        let resp = await this.storage.set('theme', data);
        if (resp) {
            this.storage.get('theme').then(res => {
            });
        }
    }

    async store_app_pages(data, name) {
        // looking if home is enable or not
        let home_page = data.filter(x => x.page_type === 'home');
        if (home_page[0].show_pwa === "true") {
            this.is_home_enable.is_enable = true;
        } else if (home_page[0].show_pwa === "false") {
            this.is_home_enable.is_enable = false;
            for(let x=0; x<data.length; x++){
                if (data[x].page_type !== "home" && data[x].name !== "Side Menu") {
                    if(data[x].activated === "true") {
                        this.is_home_enable.next_page = data[x].name;
                        break;
                    }
                }
            }
        }
        if (home_page[0].show_menu === "true") {
            this.is_home_enable.is_enable_menu = true;
        } else if (home_page[0].show_menu === "false") {
            this.is_home_enable.is_enable_menu = false;
        }
        this.event_provider.ishomeenable( this.is_home_enable );               // calling event
        this.event_provider.ishomeenablemenu( this.is_home_enable );               // calling event
        this.appPages = data;

        let resp = await this.storage.set('app_pages', data);
        if (resp) {
            this.storage.get('app_pages').then(res => {
            });
        }
    }

    checkData(data){
        if(Array.isArray(data)){
            return data;
        } else if(data instanceof Object){
            if(data.hasOwnProperty("data")){
                return data.data;
            } else if(data.hasOwnProperty("items")){
                return data.items;
            } else {
                if(data.hasOwnProperty("channel")){
                    return data.channel.item;
                } else {
                    let is_element_selected = false;
                    let selected_element = [];
                    Object.keys(data).forEach(element => {
                        if(Array.isArray(data[element]) && !is_element_selected){
                            is_element_selected = true;
                            selected_element = data[element];
                        } else {
                           Object.keys(data[element]).forEach(secondObjectElement => {
                                if(Array.isArray(data[secondObjectElement]) && !is_element_selected){
                                    is_element_selected = true;
                                    selected_element = data[secondObjectElement];
                                }
                            })
                        }
                    });
                    return selected_element;
                }
            }
        }
    }

    checkIfUserIsGroupMember(account_donor_id, group_id){
        let api = environment.api_end_point + '/auth/checkIfUserIsGroupMember/' + account_donor_id + '/' + group_id;
        return this.http.get(api);
    }

    openPagePasswordModal(password, groupId) {
        this.storage.get('user').then((val: any) => {
            if (val != null && val != 'guest') {
                let userId = val.user.id;
                this.checkIfUserIsGroupMember(userId, groupId).subscribe( (res: any) => {
                    if (res && res.status === false && password){
                        this.showPasswordModal(password);
                    }
                })
            } else {
                if (password){
                    this.showPasswordModal(password);
                }
            }
        });
    }

    async showPasswordModal(password){
        let modal;
        modal = await this.modalController.create({
            component: PagePasswordModalComponent,
            cssClass: 'page-password-modal',
            showBackdrop: true,
            backdropDismiss: false,
            componentProps: {
                page_password: password
            }
        });

        modal.onDidDismiss().then(res => {
            if ( res.data.status === false ){

            }
        });

        return await modal.present();
    }
}
