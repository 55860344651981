import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Storage} from '@ionic/storage';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    _headers: HttpHeaders;
    prayersAPI = 'https://app.churchprayerwall.com/api/';

    constructor(
        private http: HttpClient,
        private storage: Storage
    ) {
        this._headers = new HttpHeaders();
        this._headers = this._headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }

    updateToken(body) {
        let api = environment.api_end_point + '/token/refresh';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getContributions(body) {
        let api = environment.api_end_point + '/givings/donationstat';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }


    stopSubscription(body) {
        let api = environment.api_end_point + '/givings/stopsubscription';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getEvents(body) {
        let api = environment.api_end_point + '/event/events';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getGroups(body) {
        let api = environment.api_end_point + '/groups/groups';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getVolunteering(body) {
        let api = environment.api_end_point + '/volunteering/volunteering';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getPrayers(body) {
        let api = this.prayersAPI + 'church/byname/' + body;
        return this.http.get(api);
    }

    getAllPrayers(url, page_Id) {
        let external_link = this.prayersAPI + 'prayerByChurch/' + url;;
        let api = environment.api_end_point + '/event/translateExternalLink';

        let body = {
            url: external_link,
            method: 'getAllPrayers',
            page:page_Id

        };
        let _body = JSON.stringify(body);
        return this.http.post(api, JSON.parse(_body), {headers: this._headers});
    }

    createPrayer(body, id) {
        let api = this.prayersAPI + 'prayer/' + id;
        return this.http.post(api, body);
    }

    createComment(body, id) {
        let api = this.prayersAPI + 'prayer/pwacomments/' + id;
        return this.http.post(api, body);
    }

    iAmPraying(id) {
        let api = this.prayersAPI + 'prayer/' + id + '/pray';
        return this.http.get(api);
    }

    pray(id) {
        let api = this.prayersAPI + 'prayer/' + id + '/pray';
        return this.http.post(api, null);
    }

    churchDetails(body) {
        let api = environment.api_end_point + '/churchs/churchdetail';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getAllGroups(body) {
        let api = environment.api_end_point + '/groups/group/' + body;
        return this.http.get(api);
    }

    register_group(body) {
        let api = environment.api_end_point + '/groups/group';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    checkExcistence(body) {
        let api = environment.api_end_point + '/groups/checkgroup/' + body.church_id + '/' + body.donor_id + '/' + body.group_id;
        return this.http.get(api);
    }

    update_profile(body) {
        let api = environment.api_end_point + '/auth/update';
        return this.http.post(api, body , {headers: this._headers});
    }

    liveStream(body) {
        // let api = 'https://devmanage.churchbase.com/index.php?api/Builder/churchs/livestream/' + body;
        let api = environment.api_end_point + '/churchs/livestream/' + body;
        return this.http.get(api);
    }

    create_liveStream_comment(body) {
        let api = environment.api_end_point + '/churchs/livecomments';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    get_custom_url(body) {
        let api = '' + body;
        return this.http.get(api);
    }

    get_inApp_url(body) {
        let api = '' + body;
        return this.http.get(api);
    }

    get_news_wall(body) {
        let api = environment.api_end_point + '/newswall/news/' + body;
        return this.http.get(api);
    }

    create_newsWall_comment(body) {
        let api = environment.api_end_point + '/newswall/comments';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }
    edit_newsWall_comment(body) {
        let api = environment.api_end_point + '/newswall/commonComment';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }
    delete_newsWall_comment(body) {
        let api = environment.api_end_point + '/newswall/commonComment';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }
    report_newsWall_comment(body) {
        let api = environment.api_end_point + '/newswall/commonComment';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    create_newsWall_like(body) {
        let api = environment.api_end_point + '/newswall/likes';
        return this.http.post(api, JSON.parse(body), {headers: this._headers}).pipe(
            retry(1)
        );
    }

    get_church_locations(body) {
        let api = environment.api_end_point + '/churchs/churchlocations/' + body;
        return this.http.get(api);
    }

    get_community(body) {
        let api = environment.api_end_point + '/churchs/community';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    add_in_community(body) {
        let api = environment.api_end_point + '/churchs/friend';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    get_requested_people(my_id, ch_id) {
        let api = environment.api_end_point + '/churchs/requested/' + ch_id + '/' + my_id;
        return this.http.get(api);
    }

    get_directory(linkcode) {
        let api = environment.api_end_point + '/churchs/directory/' + linkcode;
        return this.http.get(api);
    }

    get_directories(body) {
        let api = environment.api_end_point + '/churchs/alldirectories';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    get_campaigns(id) {
        let api = environment.api_end_point + '/churchs/campaign/' + id;
        return this.http.get(api);
    }

    get_checkin(id, link_code) {
        let api = environment.api_end_point + '/churchs/checkin/' + id + '/' + link_code;
        return this.http.get(api);
    }

    get_people(church_id) {
        let api = environment.api_end_point + '/churchs/people/' + church_id;
        return this.http.get(api);
    }

    create_person(body) {
        let api = environment.api_end_point + '/churchs/createperson';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    load_profileQR(body) {
        let api = environment.api_end_point + '/churchs/loadProfileQR';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    get_campus(campus_id, church_id) {
        let api = environment.api_end_point + '/churchs/campus/' + church_id + '/' + campus_id;
        return this.http.get(api);
    }

    get_household(body) {
        let api = environment.api_end_point + '/churchs/gethouseHold';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    checkin(body) {
        let api = environment.api_end_point + '/churchs/checkin';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getPageData(body) {
        let api = environment.api_end_point + '/churchs/apppage';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    get_domain(body) {
        let api = environment.api_end_point + '/churchs/domain';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    get_topics(church_id, device_id) {
        let api = environment.api_end_point + '/churchs/topics/' + church_id + '/' + device_id;
        return this.http.get(api);
    }

    topic_subscribe(body) {
        let api = environment.api_end_point + '/churchs/topicsubscribe';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    upload_file(body) {
        let api = environment.api_end_point + '/newswall/fileupload';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    save_news(body) {
        let api = environment.api_end_point + '/newswall/savenews';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }
    edit_news(body) {
        let api = environment.api_end_point + '/newswall/editnews';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }
    delete_news(body) {
        let api = environment.api_end_point + '/newswall/deletenews';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }
    report_news(body) {
        let api = environment.api_end_point + '/newswall/reportnews';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    meet_the_need(body) {
        let api = environment.api_end_point + '/newswall/meettheneed';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    get_giving_design(church_id) {
        let api = environment.api_end_point + '/churchs/get_giving_design/' + church_id;
        return this.http.get(api);
    }

    prayerwall_notification_subscribe(body) {
        let api = environment.api_end_point + '/churchs/prayerwall_notification_subscribe';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    prayerwall_notification_send(body) {
        let api = environment.api_end_point + '/churchs/save_prayerwall_notification';
        return this.http.post(api, body, {headers: this._headers});
    }

    getPrayerWallToggleStatus(body) {
        let api = environment.api_end_point + '/churchs/getPrayerWallToggleStatus';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getNotifications(body) {
        let api = environment.api_end_point + '/churchs/getNotifications';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    removeNotification(body) {
        let api = environment.api_end_point + '/churchs/disbaleNotificationForHistory/' + body;
        return this.http.get(api);
    }

    clearHistory(body) {
        let api = environment.api_end_point + '/churchs/clearHistory';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    changeStoryStatus(body) {
        let api = environment.api_end_point + '/churchs/changeStoryStatus';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getbibleleftoff(body) {
        let api = environment.api_end_point + '/churchs/getbibleleftoff';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});

    }

    sendemailfromapp(body) {
        let api = environment.api_end_point + '/auth/sendemailfromapp';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});

    }
    getnewsletter(body) {
        let api = environment.api_end_point + '/churchs/getnewsletter/' + body;
        return this.http.get(api);
    }

    getnewsletterbyid(ch_id, post_id) {
        let api = environment.api_end_point + '/churchs/getnewsletterbyid/' + ch_id + '/' + post_id;
        return this.http.get(api);
    }

    getIsmBibleBooks() {
        let api = environment.api_end_point + '/external/getBibleBooks';
        return this.http.get(api);
    }

    getBibleVideoByBookId(body) {
        let api = environment.api_end_point + '/external/commonSingleVideo';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getBibleVideo(body) {
        let api = environment.api_end_point + '/external/commonVideos';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    checkVideo(url) {
        return this.http.get(url);
    }

    // wordpress migration apis

    // sermon apis

    getSermonSeries(church_id) {
        let api = environment.api_end_point + '/Contentcms/sermonsListing';
        const body = { church_id: church_id }
        return this.http.post(api, body, {headers: this._headers});
    }
    getOnlySermonSeries(ch_id, pageID ) {
        let api = environment.api_end_point + '/Contentcms/sermonSeries';
        const body = { church_id: ch_id, page_id:pageID }
        return this.http.post(api, body, {headers: this._headers});
    }

    getSermons(church_id, series_id, page) {
        let api = environment.api_end_point + '/Contentcms/getSermons';
        const body = { church_id: church_id, series_id: series_id, page: page }
        return this.http.post(api, body, {headers: this._headers});
    }

    getSermon(church_id, sermon_id) {
        let api = environment.api_end_point + '/Contentcms/sermon';
        const body = { church_id: church_id, sermon_id: sermon_id }
        return this.http.post(api, body, {headers: this._headers});
    }

    getSermonAudioVideo(church_id, page) {
        let api = environment.api_end_point + '/Contentcms/sermonAudioVideo';
        const body = { church_id: church_id, page: page }
        return this.http.post(api, body, {headers: this._headers});
    }

    // event apis

    _getEvents(church_id, series_id, page) {
        let api = environment.api_end_point + '/Contentcms/getEvents';
        const body = { church_id: church_id, page: page }
        return this.http.post(api, body, {headers: this._headers});
    }

    getEvent(church_id, event_id) {
        let api = environment.api_end_point + '/Contentcms/event';
        const body = { church_id: church_id, event_id: event_id }
        return this.http.post(api, body, {headers: this._headers});
    }

    // notes apis

    getNotes(church_id, series_id, page) {
        let api = environment.api_end_point + '/Contentcms/getNotes';
        const body = { church_id: church_id, page: page }
        return this.http.post(api, body, {headers: this._headers});
    }

    getNote(church_id, note_id) {
        let api = environment.api_end_point + '/Contentcms/note';
        const body = { church_id: church_id, note_id: note_id }
        return this.http.post(api, body, {headers: this._headers});
    }

    // post apis

    getPosts(church_id, page_id, page) {
        let api = environment.api_end_point + '/Contentcms/getPosts';
        const body = { church_id: church_id, page_id: page_id, page: page }
        return this.http.post(api, body, {headers: this._headers});
    }

    getPost(church_id, post_id, page) {
        let api = environment.api_end_point + '/Contentcms/post';
        const body = { church_id: church_id, post_id: post_id, page: page }
        return this.http.post(api, body, {headers: this._headers});
    }

    // post apis

    getFormsData(church_id, page_id) {
        let api = environment.api_end_point + '/Contentcms/formsData';
        const body = { church_id: church_id, page_id: page_id }
        return this.http.post(api, body, {headers: this._headers});
    }

    saveForm(church_id, page_id) {
        let api = environment.api_end_point + '/Contentcms/saveForm';
        const body = { church_id: church_id, page_id: page_id }
        return this.http.post(api, body, {headers: this._headers});
    }

    slugContentApi(churchId, slug) {
        let api = environment.api_end_point + '/Contentcms/CustomPostDetail';
        const body = { church_id: churchId, slug:slug }
        return this.http.post(api, body, {headers: this._headers});
    }

    // analytics apis

    analyticsPerVisit(church_id, device, client_type) {
        let api = environment.api_end_point + '/Churchs/visitsAnalytics';
        const body = { church_id: church_id, device: device, client_type: client_type }
        return this.http.post(api, body, {headers: this._headers});
    }

    getLibraryPhotos() {
        let api = environment.api_end_point + '/external/photoLibrary';
        return this.http.get(api);
    }

    searchingWords(body){
        let api = environment.api_end_point + '/content/searchData';
        return this.http.post(api, JSON.parse(body), {headers: this._headers}); 
    }
}
