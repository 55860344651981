import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-comment-alert',
  templateUrl: './comment-alert.component.html',
  styleUrls: ['./comment-alert.component.scss'],
})
export class CommentAlertComponent implements OnInit {

  constructor(public modalController: ModalController) {

  }
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true,
      agree: true
    });
  }

  ngOnInit() {}

}
