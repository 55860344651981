import {Component, OnInit, ViewChild} from '@angular/core';
import {IonSlides, ModalController} from "@ionic/angular";
import {ApiService} from "../../services/api.service";
import {EventProviderService} from "../../services/event-provider.service";
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
    selector: 'app-story-page',
    templateUrl: './story-page.page.html',
    styleUrls: ['./story-page.page.scss'],
})
export class StoryPagePage implements OnInit {
    @ViewChild(IonSlides, {static: true}) slides: IonSlides;

    slideOpts = {
        slidesPerView: 1,
        speed: 400,
        grabCursor: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        autoplay: {
            delay: 10000,
            disableOnInteraction: false,
        }
    };

    stories;
    ch_id;
    churchnameForStory;
    logoUrl;

    constructor(
        private api: ApiService,
        public modalController: ModalController,
        private event_provider: EventProviderService,
        private statusBar: StatusBar
    ) {
    }

    ionViewWillEnter(){
        // let status bar overlay webview
        // this.statusBar.overlaysWebView(true);

        // set status bar to black
        this.statusBar.backgroundColorByHexString('#000000');
    }

    ngOnInit() {
        // to update the slider
        this.slides.update();

        if (this.stories && this.stories.length > 0) {
            // making 1st story seen
            if (this.stories[0].seen === false) {
                this.stories[0].seen = true;
            }
        }
    }

    sliderChanged() {
        this.slides.getActiveIndex().then(val => {
            let index = val;
            // changing seen status of stories that have been seen
            if (this.stories[index].seen === false) {
                this.stories[index].seen = true;
            }
        })

    }

    nextSlide() {
        this.slides.slideNext();
    }

    ionViewWillLeave() {
        const body = JSON.stringify({
            church_id: this.ch_id,
            app_stories: JSON.stringify(this.stories)
        });
        this.api.changeStoryStatus(body).subscribe( (res: any) => {
            // saving updated stories object in DB when page is left
        });

        this.event_provider.updateAppStories(this.stories);
    }
    dismiss() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }

}
