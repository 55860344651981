import {Component, OnInit} from '@angular/core';
import {Storage} from "@ionic/storage";
import {NavigationExtras, Router} from "@angular/router";
import {ToastService} from "../../../services/toast.service";
import {CommonService} from "../../../services/common.service";
import {EventProviderService} from "../../../services/event-provider.service";
import {TranslateService} from "@ngx-translate/core";
import {InAppBrowser, InAppBrowserOptions} from "@ionic-native/in-app-browser/ngx";
import { Platform } from '@ionic/angular';
import {environment} from "../../../environments/environment.prod";
import { SlugService } from 'src/services/slug.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-footer-mobile',
    templateUrl: './footer-mobile.component.html',
    styleUrls: ['./footer-mobile.component.scss'],
})
export class FooterMobileComponent implements OnInit {
    ch_id: any;
    isLoggedIn: boolean = false;
    theme: any;
    bottom_menu: any;
    enable_home: boolean = true;
    home_tabs: any;
    alertMessage: any;
    app_pages: any;

    options : InAppBrowserOptions = {
        closebuttoncaption : 'Close',
        closebuttoncolor: '#FFFFFF',
        disallowoverscroll: 'no',
        hidenavigationbuttons: 'no',
        toolbar: 'yes',
        toolbarposition: 'top',
        location: 'yes'
    };

    constructor(
        private storage: Storage,
        private router: Router,
        private toast: ToastService,
        private common: CommonService,
        private event_provider: EventProviderService,
        private translate: TranslateService,
        private iab: InAppBrowser,
        private platform: Platform,
        public slug:SlugService,
        public location:Location,
    ) {
        // get translated alert message
        let interval = setInterval(() => {
            this.translate.get('ALERTS').subscribe( (res: any) => {
                this.alertMessage = res;
                clearInterval(interval);
            });
        }, 2000);


        this.event_provider.chnageFooter.subscribe(res=>{
            if(res){
                this.direction = res
            }
        });
    }

    ngOnInit() {
        this.event_provider.updateurl();

        // get theme
        this.getChurch();

        // get user data
        this.getUser();

        // get user data
        this.getAppPages();

        this.event_provider.getTheme.subscribe(value => {              // listening event
            if (value.value) {
                this.theme = value.value;
                if (this.theme.bottom_menu) {
                    this.bottom_menu = JSON.parse(this.theme.bottom_menu);
                    if (!this.common.is_home_enable.is_enable_menu) {
                        // Exclude HOME Page if it is desabled
                        this.bottom_menu = this.bottom_menu.filter(x => x.page_type !== 'home');
                    }
                }
                this.ch_id = this.theme.ch_id;
                // to check which tab is active
                if (this.theme.home_tabs){
                    this.home_tabs = JSON.parse(this.theme.home_tabs);
                }
            }
        });

        // event subscription to update App pages
        this.event_provider.getAppPages.subscribe(value => {              // ----------- test
            if (value.value){
                this.app_pages = value.value;
            }
        });

        // event subscription to update User Data
        this.event_provider.updateSidebarMenu.subscribe(value => {              // ----------- test
            this.getUser();
        });
    }

    getAppPages(){
        this.storage.get('app_pages').then( pages => {
             if (pages){
                 this.app_pages = pages;
             }
        })
    }

    getChurch() {
        // getting theme from local storage
        this.event_provider.getAppTheme.subscribe(theme => {

            if (theme) {
                this.ch_id = theme.ch_id;
                if (theme.bottom_menu) {
                    this.bottom_menu = JSON.parse(theme.bottom_menu);

                    if (!this.common.is_home_enable.is_enable_menu) {
                        // Exclude HOME Page if it is desabled
                        this.bottom_menu = this.bottom_menu.filter(x => x.page_type !== 'home');
                    }
                }

                // to check which tab is active
                if (theme.home_tabs){
                    this.home_tabs = JSON.parse(theme.home_tabs);
                }

                if (theme.home_tabs){
                    // updating Home Tabs
                    this.event_provider.updatehometabs( theme.home_tabs );
                }
            }
        })
    }

    getUser() {
        // getting user from local storage
        this.storage.get('user').then(res => {
            if (res != null && res != "guest") {
                this.isLoggedIn = true;
            } else {
                this.isLoggedIn = false;
            }
        })
    }

    // Navigating on Bottom Menu Icons CLICK
    gotoPage(pageName, pageId, pageType, pageData) {
        if (pageName) {
            if (pageName === 'Audio') {
                pageName = 'audio-archive';
                this.slug.setPage_id("audio-archive", pageId);
                this.slug.setSlugName( "audio-archive" , pageData.slug );
                this.router.navigate(['audio-archive'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Video') {
                pageName = 'video-archive';
                this.slug.setPage_id( "video-archive",pageId);
                this.slug.setSlugName( "video-archive" , pageData.slug );
                this.router.navigate(['video-archive'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageType === 'home') {
                if (this.ch_id === '1000'){
                    pageName = 'ism-home/' + this.ch_id;
                    this.router.navigate([pageName]);
                } else {
                    pageName = 'home-tabs/home/' + this.ch_id;
                    this.router.navigate([pageName],{ skipLocationChange: true, replaceUrl:false });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
                }
            } else if (pageName === 'Bible'){
                if(this.ch_id == '1000'){
                    this.router.navigate(['/bible-chapter']);
                } else {
                    pageName = pageName.replace(' ', '-');
                    pageName = pageName.toLowerCase();

                    this.router.navigate([pageName]);
                }
            } else if (pageName === 'Account') {
                this.router.navigate(['/login']);
            } else if (pageName === 'Custom Post') {
                this.slug.setPage_id("custom-post",pageId);
                this.slug.setSlugName( "custom-post" , pageData.slug);
                this.router.navigateByUrl('/', { skipLocationChange: true, replaceUrl: true}).then(() => {
                    this.router.navigate(['custom-post/']);
                });
                // this.router.navigate(['custom-post/'], { skipLocationChange: false, replaceUrl: true });
                // this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Chat') {
                if ((this.home_tabs && this.home_tabs[2].status === 'true') || this.ch_id === '1000'){
                    this.router.navigate(['/chat']);
                } else {
                    this.router.navigate(['home-tabs/chat']);
                }
            } else if (pageName === 'Donations') {
                let current_url = JSON.parse(localStorage.getItem("url"));
                let path_origin = current_url.origin;
                let link_page = this.app_pages.find(x => x.id === pageId);
                if (link_page) {
                    let open_external = link_page.open_external;
                    if (open_external === 'true') {
                        let donations_url = '';
                        if (link_page.html) {
                            donations_url = current_url.origin + "/give-new/" + link_page.html;
                        } else {
                            donations_url = current_url.origin + "/give-new/" + this.ch_id;
                        }

                        let url = JSON.parse(localStorage.getItem("url"));

                        if(this.platform.is("mobile") || this.platform.is("pwa")){
                            if (url.host.includes("pwabuilder.churchbase.com")) {
                                let navigationExtras: NavigationExtras = {
                                    queryParams: {
                                        url: link_page.url
                                    }
                                };
                                this.slug.setIframe(link_page.url)
                                this.slug.setSlugName( "iframeheader" , pageData.slug);
                                this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                            }
                            else{
                                this.openurlinbrowser(donations_url);
                            }
                        } else {
                            this.openurlinbrowser(donations_url);
                        }
                    } else {
                        this.router.navigateByUrl('/my-contribute/'+link_page.html);
                    }
                }
            } else if (pageName === 'Feed') {
                if ((this.home_tabs && this.home_tabs[1].status === 'true') || this.ch_id === '1000'){
                    this.router.navigate(['/feed-tab']);
                } else {
                    this.router.navigate(['home-tabs/feed-tab']);
                }
            } else if (pageName === 'Events') {
                this.slug.setPage_id("events",pageId);
                this.slug.setSlugName( "events" , pageData.slug );
                this.router.navigate(['events'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Profile') {
                if (this.isLoggedIn) {
                    this.slug.setSlugName( "profile" , pageData.slug );
                    this.router.navigate(['profile'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                } else {
                    this.router.navigate(['/login']);
                }
            } else if (pageName === 'Live Stream') {
                this.slug.setCh_Id(this.ch_id);
                this.slug.setSlugName( "live-stream" , pageData.slug);
                this.router.navigate(['live-stream'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Folder') {
                this.slug.setFolder(pageId);
                this.slug.setSlugName( "folder" , pageData.slug);
                this.event_provider.folderPage(pageId);
                this.router.navigate(['/folder'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Custom Page') {
                this.slug.setPage_id("custom-page",pageId);
                this.event_provider.customePage(pageId);
                this.slug.setSlugName( "custom-page" , pageData.slug);
                this.router.navigate(['/custom-page'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Form') {
                this.slug.setForm(pageId);
                this.slug.setSlugName( "form" , pageData.slug);
                this.router.navigate(['/form'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'iFrame') {
                this.slug.setPage_id("in-app-iframe" ,pageId);
                this.slug.setSlugName( "in-app-iframe/" , pageData.slug);
                this.router.navigate(['in-app-iframe/'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageType === 'link') {
                let link_page = this.app_pages.find(x => x.id === pageId);
                if (link_page) {
                    let open_external = link_page.open_external;
                    if (open_external === 'true') {
                        let url = JSON.parse(localStorage.getItem("url"));
                        if (url.host.includes("pwabuilder.churchbase.com")) {
                            let navigationExtras: NavigationExtras = {
                                queryParams: {
                                    url: link_page.url
                                }
                            };
                            this.slug.setIframe(link_page.url);
                            this.slug.setSlugName( "iframeheader" , pageData.slug);
                            this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                        } else {
                            this.openurlinbrowser(link_page.url);
                        }
                    } else {
                        this.openurlinapp(link_page.url);
                    }
                }
            } else if (pageName === 'Contact Us') {
                this.slug.setCh_Id(this.theme.ch_id)
                this.slug.setSlugName( "contact-us" , pageData.slug)
                this.router.navigate(['/contact-us/' , {page_id: pageId}]);
            }
            else if (pageName === 'Newsletter') {
                this.slug.setCh_Id(this.ch_id)
                // this.router.navigate(['/newsletter/' + this.ch_id ]);
                this.slug.setSlugName( "newsletter" , pageData.slug)
                this.router.navigate(['/newsletter/'+ this.ch_id ], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else {
                if(pageName === 'Sermon Series'  ){
                    var data={
                        id:JSON.parse(pageData.series_id),
                        ch_id:this.ch_id,
                        page_id: pageId
                    }
                    this.slug.setAllSermon(data)
                    this.event_provider.allsermonSeries(data);
                    this.slug.setSlugName( "all-sermons" , pageData.slug )
                    this.router.navigate(['all-sermons'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }else if( pageName === 'Sermons' ){
                    this.event_provider.sermonPage(pageId)
                    this.slug.setPage_id("sermons",pageId)
                    this.slug.setSlugName( "sermons" , pageData.slug)
                    this.router.navigate(['/sermons'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }else{
                    pageName = pageName.replace(' ', '-');
                    pageName = pageName.toLowerCase();
                    this.router.navigate([pageName]);
                }
            }
        }
    }

    // inside app on page with done button
    openurlinapp(url) {
        if(this.platform.is('android') || this.platform.is('iphone')) {
            this.iab.create(url, '_blank', this.options);
        } else {
            this.iab.create(url, '_self', this.options);
        }
    }

    // in external safari/chrome
    openurlinbrowser(url){
        this.iab.create(url, '_system');
    }
    direction:any;
    ionViewWillEnter() {
        this.ngOnInit();
    }
}
