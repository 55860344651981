import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {LoaderService} from "../../services/loader.service";
import {Storage} from "@ionic/storage";
import {AuthService} from "../../services/auth.service";
import {ToastService} from "../../services/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {EventProviderService} from "../../services/event-provider.service";
import { ApiService } from 'src/services/api.service';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss'],
})
export class DeleteAccountModalComponent implements OnInit {

  user: any;
  alertMessage: any;
  pageName: any;
  ch_id: any;
  theme: any;

  deleteComment:boolean = false;
  deleteFeed:boolean = false;
  accountDelete:boolean = false;

  constructor(
      public modalController: ModalController,
      private loader: LoaderService,
      private storage: Storage,
      private Auth: AuthService,
      private toast: ToastService,
      private translate: TranslateService,
      private router: Router,
      private event_provider: EventProviderService,
      private navParams: NavParams,
      private apiService : ApiService
  ) {
    // get translated alert message
    let interval = setInterval(() => {
      this.translate.get('ALERTS').subscribe( (res: any) => {
        this.alertMessage = res;
        clearInterval(interval);
      });
    }, 2000)
  }

  ngOnInit() {
    if(this.navParams.data.event === 'delete'){
      this.deleteFeed = true;
    } else if (this.navParams.data.event === 'delete-comment') {
      this.deleteComment = true;
    } else {
      this.accountDelete = true;
    }
    this.getAuthUser();
  }

  async getAuthUser() {
    // getting user from local storage
    this.storage.get('user').then(value => {
      this.user = value.user;
    });
    this.theme = await this.storage.get('theme');
    this.ch_id = this.theme.ch_id;
  }

  deleteAccount() {
    this.loader.presentLoading().then( () => {});

    const body = JSON.stringify({
      'account_donor_id': this.user.id,
    });

    this.Auth.deleteAccount(body).subscribe((res: any) => {
      this.Auth.logoutUser();
      this.event_provider.userlogout();               // calling event
      this.event_provider.refresh_home();
      // this.event_provider.refreshSettings();            // calling event

      this.modalController.dismiss({
        'dismissed': true
      });

      this.pageName = 'home-tabs/home/' + this.ch_id;
      this.router.navigate([this.pageName]);

      this.loader.stopLoading();
    }, (err: any) => {
      let message = err.message;
      this.loader.stopLoading();
      this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.error_occured_try_again : 'Error Occurred, Try Again'), (this.alertMessage ? this.alertMessage.WarningMessage : 'Warning Message'), 'warning');
    });


    this.modalController.dismiss({
      'dismissed': true
    });
  }

  deleteItem() {
  
  if(this.navParams.data.event === 'delete-comment') {
    const body = JSON.stringify({
      id : this.navParams.data.body.id,
      church_id : this.ch_id,
      method : this.navParams.data.method,
      action:'deleteComment',
  });
    this.apiService.delete_newsWall_comment(body).subscribe((res:any) => {
      if(res && res.status) {
        this.modalController.dismiss({
          'success': res.status
        });
      }
  }, (err: any) => {
    this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.error_occured_try_again : 'Error Occured, Try Again'), (this.alertMessage ? this.alertMessage.error : 'ERROR'), "warning");
})
  } else if(this.navParams.data.event === 'delete') {
    const body = JSON.stringify({
      id : this.navParams.data.body.id,
      church_id : this.ch_id,
      method : this.navParams.data.method,
      action:'deleteComment',
  });
    this.apiService.delete_news(body).subscribe((res : any) => {
      if(res && res.status) {
        this.modalController.dismiss({
          'success': res.status
        });
      }
    }, (err: any) => {
      this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.error_occured_try_again : 'Error Occured, Try Again'), (this.alertMessage ? this.alertMessage.error : 'ERROR'), "warning");
  })
  } else {
    this.deleteAccount();
  }
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
