import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FooterComponent} from "./footer/footer.component";
import {FooterMobileComponent} from "./footer-mobile/footer-mobile.component";
import {FormsModule} from "@angular/forms";
import {HeaderComponent} from "./header/header.component";
import {ExpandableComponent} from "./expandable/expandable.component";
import {BibleFilterModalComponent} from "./bible-filter-modal/bible-filter-modal.component";
import {TranslateModule} from "@ngx-translate/core";
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import  {  NgxEmojiPickerModule  }  from  'ngx-emoji-picker';
import { EmojiComponent } from './emoji/emoji.component';
import {SharingModalAudioComponent} from "../audio-archive/sharing-modal-audio/sharing-modal-audio.component";
import {PagePasswordModalComponent} from "./page-password-modal/page-password-modal.component";
import { MoreInfoComponent } from './more-info/more-info.component';


@NgModule({
    declarations: [FooterComponent, FooterMobileComponent, ExpandableComponent, PagePasswordModalComponent, BibleFilterModalComponent, AudioPlayerComponent, EmojiComponent, SharingModalAudioComponent , MoreInfoComponent],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        NgxEmojiPickerModule
    ],
    exports: [
        FooterComponent,
        FooterMobileComponent,
        ExpandableComponent,
        BibleFilterModalComponent,
        AudioPlayerComponent,
        PagePasswordModalComponent,
        EmojiComponent,
        MoreInfoComponent
    ],
    entryComponents: [
        EmojiComponent,
        MoreInfoComponent,
        SharingModalAudioComponent,
        PagePasswordModalComponent
      ],
})
export class SharedCommonModule {
}
