import {Component, OnInit} from '@angular/core';
import {Storage} from '@ionic/storage';
import {LoaderService} from 'src/services/loader.service';
import {Router} from '@angular/router';
import {CommonService} from '../../../services/common.service';
import {IdService} from '../../../services/id.service';
import { EventProviderService } from 'src/services/event-provider.service';
// import {Events} from "@ionic/angular";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

    theme: any;
    additional_links: any;
    footer_sections: any;
    footer_column_1: any = [];
    footer_column_2: any = [];
    footer_column_3: any;
    social_links: any;
    footer_address_show: any = false;

    constructor(
        private storage: Storage,
        private loader: LoaderService,
        private router: Router,
        private common: CommonService,
        private idService: IdService,
        private event_provider: EventProviderService
        // public events: Events,
    ) {
    }

    ngOnInit() {
        // 2 seconds timer to stop loading after everything is loaded completely
        let interval = setInterval(() => {
                this.getDesign();
                clearInterval(interval);
        }, 0)

    }

    async getDesign() {
        if (!this.router.url.includes('/event-giving-pwa/') && !this.router.url.includes('/campaign-registration/') && !this.router.url.includes('/campus-giving/') && !this.router.url.includes('/check-in/') && !this.router.url.includes('/directory/') && !this.router.url.includes('/give-new/')) {
            // this section executes when PAGE is not EXTERNAL page (page without church ID)
                    this.event_provider.getChurchData.subscribe(async (res: any) => {
                        if (!(Object.keys(res).length === 0)) {
                            this.theme = res.church;

                            if(this.theme.footer_address != ""){
                                this.footer_address_show = true;
                            }

                            // assigning additional links
                            if (this.theme.additional_links) {
                                this.additional_links = JSON.parse(this.theme.additional_links);
                            }

                            // assigning social links
                            if (this.theme.social_links) {
                                this.social_links = JSON.parse(this.theme.social_links);
                            }

                            // assigning footer section
                            if (this.theme.footer_section) {
                                this.footer_column_1 = [];
                                this.footer_column_2 =[];
                                this.footer_column_3 = [];                             
                                this.footer_sections = JSON.parse(this.theme.footer_section);
                                if (this.footer_sections.length > 0 && this.footer_sections.length < 5) {
                                    let count = 1;

                                    // dividing Footer Sections in 5 seperate Columns in footer
                                    // to display even and better
                                    for (let i = 0; i < this.footer_sections.length; i++) {
                                        if (count === 1) {
                                            this.footer_column_1.push(this.footer_sections[i]);
                                            count = count + 1;
                                        } else if (count === 3) {
                                            this.footer_column_1.push(this.footer_sections[i]);
                                            count = count + 1;
                                        } else if (count === 5) {
                                            this.footer_column_3 = this.footer_sections[i];
                                            count = count + 1;
                                        } else {
                                            this.footer_column_2.push(this.footer_sections[i]);
                                            count = count + 1;
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
        //     });
        // } else {
            // this section executes when PAGE is EXTERNAL page (page with church ID)

        //     let id = this.idService.getId();
        //     const body = JSON.stringify({id: id});
        //     // getting latest theme
        //     this.event_provider.getChurchData.subscribe(async (res: any) => {

        //         if (res.status) {
        //             this.theme = res.church;
        //             if(this.theme.footer_address != ""){
        //                 this.footer_address_show = true;
        //             }
        //             // assigning additional links
        //             if (this.theme.additional_links) {
        //                 this.additional_links = JSON.parse(this.theme.additional_links);
        //             }
        //             // assigning social links
        //             if (this.theme.social_links) {
        //                 this.social_links = JSON.parse(this.theme.social_links);
        //             }
        //             // assigning footer secitons
        //             if (this.theme.footer_section) {
        //                 this.footer_sections = JSON.parse(this.theme.footer_section);
        //                 if (this.footer_sections.length > 0 && this.footer_sections.length < 6) {
        //                     let count = 1;

        //                     // dividing Footer Sections in 5 seperate Columns in footer
        //                     // to display even and better
        //                     for (let i = 0; i < this.footer_sections.length; i++) {
        //                         if (count === 1) {
        //                             this.footer_column_1.push(this.footer_sections[i]);
        //                             count = count + 1;
        //                         } else if (count === 3) {
        //                             this.footer_column_1.push(this.footer_sections[i]);
        //                             count = count + 1;
        //                         } else if (count === 5) {
        //                             this.footer_column_3 = this.footer_sections[i];
        //                             count = count + 1;
        //                         } else {
        //                             this.footer_column_2.push(this.footer_sections[i]);
        //                             count = count + 1;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     });
        // }
    }


}
