import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss'],
})
export class MoreInfoComponent implements OnInit {
  action: any;
  current_user_news: boolean = false;
  type: any;
  constructor(private popoverController: PopoverController, private navParams: NavParams) { }

  ngOnInit() {
    this.type = this.navParams.data.type;
    this.action = this.navParams.data.action;
    if (this.navParams.data.isUserLoggedIn) {
      if (this.navParams.data.type === 'Campaign' && this.navParams.data.action === 'comments') {
        if (this.navParams.data.user_donor_id === this.navParams.data.comment_donor_id) {
          this.current_user_news = true;
        } else {
          this.current_user_news = false;
        }
      } else {
        if (this.navParams.data.user_donor_id === this.navParams.data.news_donor_id) {
          this.current_user_news = true;
        } else {
          this.current_user_news = false;
        }
      }

    }

  }

  infoAction(event) {
    if (event === 'edit') {
      this.popoverController.dismiss({
        event: 'edit'
      });
    } else if (event === 'report') {
      this.popoverController.dismiss({
        event: 'report'
      });
    } else if (event === 'delete') {

      this.popoverController.dismiss({
        event: 'delete'
      });
    } else if (event === 'edit-comment') {
      this.popoverController.dismiss({
        event: 'edit-comment'
      });
    } else if (event === 'report-comment') {
      this.popoverController.dismiss({
        event: 'report-comment'
      });
    } else if (event === 'delete-comment') {
      this.popoverController.dismiss({
        event: 'delete-comment'
      });
    }

  }

}
