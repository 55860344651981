import {Component, OnInit} from '@angular/core';
import {Events, NavParams, PopoverController} from "@ionic/angular";
import {EventProviderService} from "../../../services/event-provider.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-filter-popover',
    templateUrl: './filter-popover.component.html',
    styleUrls: ['./filter-popover.component.scss'],
})
export class FilterPopoverComponent implements OnInit {
    date: any;
    cancel_filter: any;

    constructor(
        private navParams: NavParams,
        private popoverController: PopoverController,
        private event_provider: EventProviderService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit() {
        //Get data from popover page
        this.cancel_filter = this.navParams.data.cancel_filter;
    }

    dismiss( func ) {
        this.popoverController.dismiss({
            date: this.date,
            func: func
        });
    }

    eventFromPopover() {
        this.event_provider.frompopoverevents();
        this.popoverController.dismiss();
    }
}
