import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {Router} from "@angular/router";
import {ToastService} from "../../../services/toast.service";
import {DomSanitizer} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-sharing-modal-sermon',
  templateUrl: './sharing-modal-sermon.component.html',
  styleUrls: ['./sharing-modal-sermon.component.scss'],
})
export class SharingModalSermonComponent implements OnInit {

  // app_url = 'http://localhost:8100';
  app_url = 'https://builder.churchbase.com';

  fbUrl: any = 'https://facebook.com/sharer/sharer.php?u=';
  twitterUrl: any = 'https://twitter.com/intent/tweet?text=';
  whatsappUrl: any = 'whatsapp://send?text=';

  link;
    alertMessage: any;

  constructor( public modalController: ModalController,
               private router: Router,
               private toast: ToastService,
               private dom: DomSanitizer,
               private translate: TranslateService,
               ) {

      // get translated alert message
      let interval = setInterval(() => {
          this.translate.get('ALERTS').subscribe( (res: any) => {
              this.alertMessage = res;
              clearInterval(interval);
          });
      }, 2000)

  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  ngOnInit() {
   this.link = this.link ? this.link : '';
  }

  copyLink() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.link_copied_to_clipboard : 'Link Copied to Clipboard'), (this.alertMessage ? this.alertMessage.LinkCopied : 'Link Copied'), 'success');
    this.dismiss();
  }

  sanitize_url(url) {
    return this.dom.bypassSecurityTrustUrl(url);
  }

}
