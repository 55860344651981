import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ApiService} from "../../../services/api.service";
import {LoaderService} from "src/services/loader.service";
import {Storage} from "@ionic/storage";
import {ToastService} from "../../../services/toast.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  to_email: any = 'pressbible@ism.bible';
  from_email: any = '';
  subject: any = ' [PressBible] App Feedback ';
  message: any = '';
  from_name: any = '';

  constructor(
      public modalController: ModalController,
               private apiService: ApiService,
               private loader: LoaderService,
               private toast: ToastService,
               private translate: TranslateService,
      ) { }

  ngOnInit() {}

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  sendEmail(){
    if (this.from_email != '' && this.message !== ''){
      if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.from_email))
      {
        this.toast.presentToastWithOptions("Incorrect Email Format", "Fail", "warning");
        return;
      }
      let body = JSON.stringify({
        to_email: this.to_email,
        from_email: this.from_email,
        from_name: this.from_name,
        subject: this.subject,
        message: this.message,
      });
      this.apiService.sendemailfromapp(body).subscribe( (res: any) => {
        if (res){
          if (res.status == true){
            this.toast.presentToastWithOptions("Email Successfully Sent!", "Sent", "success");
          } else {
            this.toast.presentToastWithOptions("Error Sending EMail!", "Fail", "warning");
          }
        }
        this.dismiss();
      })
    } else {
      this.toast.presentToastWithOptions("Missing Required fields", "Warning", "warning");
    }

  }

}
