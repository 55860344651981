import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ApiService} from "../../../services/api.service";
import {LoaderService} from "src/services/loader.service";
import {Storage} from "@ionic/storage";
import {ToastService} from "../../../services/toast.service";
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';

@Component({
    selector: 'app-comment-modal',
    templateUrl: './comment-modal.component.html',
    styleUrls: ['./comment-modal.component.scss'],
})
export class CommentModalComponent implements OnInit {

    name: any;
    last_name: any;
    message: any;
    isUserLoggedIn;
    prayer_id;
    submitted: boolean = false;
    alertMessage: any;

    constructor(
        public modalController: ModalController,
        private apiService: ApiService,
        private loader: LoaderService,
        private toast: ToastService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit() {
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            'dismissed': true,
            'submitted': this.submitted
        });
    }

    createComment() {
        let church_data = JSON.parse(localStorage.getItem('church_data'));
        if (!this.message) {
            this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.enter_all_required_fields : 'Please Enter all required Fields'), (this.alertMessage ? this.alertMessage.WarningMessage : 'Warning Message'), 'warning');
            return;
        }
        let date = moment().tz(church_data.church.timezone);
        const body = {
            name: this.name,
            last_name: this.last_name,
            message: this.message,
            date: date
        };

        this.loader.presentLoading().then(() => {
            this.apiService.createComment(body, this.prayer_id).subscribe(res => {
                this.submitted = true;
                this.dismiss();
                this.loader.stopLoading();
            }, (err: any) => {
                this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.error_occured_try_again : 'Error Occured, Try Again'), (this.alertMessage ? this.alertMessage.WarningMessage : 'Warning Message'), 'warning');
                this.loader.stopLoading();
            });
        })
    }
}
