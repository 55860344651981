import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {path: 'login', loadChildren: './login/login.module#LoginPageModule'},
    {path: 'login/:id/:from', loadChildren: './login/login.module#LoginPageModule'},
    {path: 'home/:id', loadChildren: './home/home.module#HomePageModule'},
    {path: 'home/:id/:slug', loadChildren: './home/home.module#HomePageModule'},
    {path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule'},
    {path: 'my-contribute', loadChildren: './my-contribute/my-contribute.module#MyContributePageModule'},
    {path: 'my-contribute/:id', loadChildren: './my-contribute/my-contribute.module#MyContributePageModule'},
    {path: 'signup', loadChildren: './signup/signup.module#SignupPageModule'},
    {path: 'events', loadChildren: './events/events.module#EventsPageModule'},
    {path: 'sermons', loadChildren: './sermons/sermons.module#SermonsPageModule'},
    {path: 'delete-user-account/:church_id', loadChildren: './delete-user-account/delete-user-account.module#DeleteUserAccountPageModule'},
    {path: 'audio-archive', loadChildren: './audio-archive/audio-archive.module#AudioArchivePageModule'},
    {path: 'my-groups', loadChildren: './my-groups/my-groups.module#MyGroupsPageModule'},
    {path: 'groups', loadChildren: './groups/groups.module#GroupsPageModule'},
    {path: 'my-volunteering', loadChildren: './my-volunteering/my-volunteering.module#MyVolunteeringPageModule'},
    {path: 'my-contributions', loadChildren: './my-contributions/my-contributions.module#MyContributionsPageModule'},
    {path: 'bible', loadChildren: './bible/bible.module#BiblePageModule'},
    {path: 'event-detail', loadChildren: './event-detail/event-detail.module#EventDetailPageModule'},
    {path: 'prayer-wall', loadChildren: './prayer-wall/prayer-wall.module#PrayerWallPageModule'},
    {path: 'leaders-conference', loadChildren: './leaders-conference/leaders-conference.module#LeadersConferencePageModule'},
    {path: 'live-stream', loadChildren: './live-stream/live-stream.module#LiveStreamPageModule'},
    {path: 'locations', loadChildren: './locations/locations.module#LocationsPageModule'},
    {path: 'location-detail', loadChildren: './location-detail/location-detail.module#LocationDetailPageModule'},
    {path: 'my-events', loadChildren: './my-events/my-events.module#MyEventsPageModule'},
    {path: 'thankyou', loadChildren: './thankyou/thankyou.module#ThankyouPageModule'},
    {path: 'chat', loadChildren: './chat/chat.module#ChatPageModule'},
    {path: 'chat-detail', loadChildren: './chat-detail/chat-detail.module#ChatDetailPageModule'},
    {path: 'notifications', loadChildren: './notifications/notifications.module#NotificationsPageModule'},
    {path: 'feed-tab', loadChildren: './feed-tab/feed-tab.module#FeedTabPageModule'},
    {path: 'chat-tab', loadChildren: './chat-tab/chat-tab.module#ChatTabPageModule'},
    {path: 'home-tabs', loadChildren: './home-tabs/home-tabs.module#HomeTabsPageModule'},
    {path: 'sermon-detail', loadChildren: './sermon-detail/sermon-detail.module#SermonDetailPageModule'},
    {path: 'all-sermons', loadChildren: './all-sermons/all-sermons.module#AllSermonsPageModule'},
    {path: 'all-sermons/:ch_id/:page_id', loadChildren: './all-sermons/all-sermons.module#AllSermonsPageModule' },
    {path: 'groups', loadChildren: './groups/groups.module#GroupsPageModule'},
    {path: 'group-detail', loadChildren: './group-detail/group-detail.module#GroupDetailPageModule'},
    {path: 'my-event-detail', loadChildren: './my-event-detail/my-event-detail.module#MyEventDetailPageModule'},
    {path: 'sermon-notes', loadChildren: './sermon-notes/sermon-notes.module#SermonNotesPageModule'},
    {path: 'sermon-notes-detail', loadChildren: './sermon-notes-detail/sermon-notes-detail.module#SermonNotesDetailPageModule'},
    {path: 'my-volunteering-detail', loadChildren: './my-volunteering-detail/my-volunteering-detail.module#MyVolunteeringDetailPageModule'},
    {path: 'settings', loadChildren: './settings/settings.module#SettingsPageModule'},
    {path: 'custom', loadChildren: './custom/custom.module#CustomPageModule'},
    {path: 'in-app-iframe', loadChildren: './in-app-iframe/in-app-iframe.module#InAppIframePageModule'},
    {path: 'video-archive', loadChildren: './video-archive/video-archive.module#VideoArchivePageModule'},
    {path: 'video-archive-detail', loadChildren: './video-archive-detail/video-archive-detail.module#VideoArchiveDetailPageModule'},
    {path: 'folder', loadChildren: './folder/folder.module#FolderPageModule'},
    {path: 'custom-page', loadChildren: './custom-page/custom-page.module#CustomPagePageModule'},
    {path: 'form', loadChildren: './form/form.module#FormPageModule'},
    {path: 'event-giving/:event_id', loadChildren: './event-giving/event-giving.module#EventGivingPageModule'},
    {path: 'community', loadChildren: './community/community.module#CommunityPageModule'},
    {path: 'campaign-registration/:ch_id/:id', loadChildren: './campaign-registration/campaign-registration.module#CampaignRegistrationPageModule'},
    {path: 'directory/:ch_id/:code', loadChildren: './directory/directory.module#DirectoryPageModule'},
  { path: 'check-in/:ch_id/:id/:link_code', loadChildren: './check-in/check-in.module#CheckInPageModule' },
  { path: 'campus-giving/:ch_id/:campus_id', loadChildren: './campus-giving/campus-giving.module#CampusGivingPageModule' },
  { path: 'event-giving-pwa/:ch_id/:event_id', loadChildren: './event-giving-pwa/event-giving-pwa.module#EventGivingPwaPageModule' },
  { path: 'old-testament', loadChildren: './old-testament/old-testament.module#OldTestamentPageModule' },
  { path: 'new-testament', loadChildren: './new-testament/new-testament.module#NewTestamentPageModule' },
  { path: 'bible-chapter', loadChildren: './bible-chapter/bible-chapter.module#BibleChapterPageModule' },
  { path: 'bible-search', loadChildren: './bible-search/bible-search.module#BibleSearchPageModule' },
  { path: 'bible-search-list', loadChildren: './bible-search-list/bible-search-list.module#BibleSearchListPageModule' },
  { path: 'teachings-search-list', loadChildren: './teachings-search-list/teachings-search-list.module#TeachingsSearchListPageModule' },
  // { path: 'audio-player', loadChildren: './audio-player/audio-player.module#AudioPlayerPageModule' },
  { path: 'topics', loadChildren: './topics/topics.module#TopicsPageModule' },
  { path: 'contact-us', loadChildren: './contact-us/contact-us.module#ContactUsPageModule' },
  { path: 'video-detail', loadChildren: './video-detail/video-detail.module#VideoDetailPageModule' },
  { path: 'custom-post', loadChildren: './custom-post/custom-post.module#CustomPostPageModule' },
  { path: 'custom-post-detail', loadChildren: './custom-post-detail/custom-post-detail.module#CustomPostDetailPageModule' },
  { path: 'give-new/:ch_id', loadChildren: './give-new/give-new.module#GiveNewPageModule' },
  { path: 'give-new/:ch_id/:kiosk', loadChildren: './give-new/give-new.module#GiveNewPageModule' },
  { path: 'discover', loadChildren: './discover/discover.module#DiscoverPageModule' },
  { path: 'discuss', loadChildren: './discuss/discuss.module#DiscussPageModule' },
  { path: 'ism-home/:id', loadChildren: './ism-home/ism-home.module#IsmHomePageModule' },
  { path: 'story-page', loadChildren: './story-page/story-page.module#StoryPagePageModule' },
  { path: 'alpha-scroll', loadChildren: './alpha-scroll/alpha-scroll.module#AlphaScrollPageModule' },
  { path: 'iframe', loadChildren: './iframe/iframe.module#IframePageModule' },
  { path: 'newsletter', loadChildren: './newsletter/newsletter.module#NewsletterPageModule' },
  { path: 'newsletter/:ch_id', loadChildren: './newsletter/newsletter.module#NewsletterPageModule' },
  { path: 'newsletter-detail/:ch_id/:post_id', loadChildren: './newsletter-detail/newsletter-detail.module#NewsletterDetailPageModule' },
  { path: 'ism-teachings', loadChildren: './ism-teachings/ism-teachings.module#IsmTeachingsPageModule' },
  { path: 'ism-bible', loadChildren: './ism-bible/ism-bible.module#IsmBiblePageModule' },
  { path: 'ism-topic', loadChildren: './ism-topic/ism-topic.module#IsmTopicPageModule' },
  { path: 'ism-teachings-video-detail', loadChildren: './ism-teachings-video-detail/ism-teachings-video-detail.module#IsmTeachingsVideoDetailPageModule' },
  { path: 'ism-teachings-videos', loadChildren: './ism-teachings-videos/ism-teachings-videos.module#IsmTeachingsVideosPageModule' },
  { path: 'sermon/topic/:slug', loadChildren: './modules/sermons/pages/topic/topic.module#TopicPageModule' },
  { path: 'sermon/books/:slug', loadChildren: './modules/sermons/pages/books/books.module#BooksPageModule' },
  { path: 'sermon/series/:slug', loadChildren: './modules/sermons/pages/series/series.module#SeriesPageModule' },
  { path: 'sermon/speakers/:slug', loadChildren: './modules/sermons/pages/speakers/speakers.module#SpeakersPageModule' },
  { path: 'sermon/:slug', loadChildren: './modules/sermons/pages/view-sermon/view-sermon.module#ViewSermonPageModule' },
  { path: 'custom-posts/category/:slug', loadChildren: './modules/custom-post/pages/category/category.module#CategoryPageModule' },
  { path: 'custom-posts/:slug', loadChildren: './modules/custom-post/pages/viewpost/viewpost.module#ViewpostPageModule' },
  { path: 'event/category/:slug', loadChildren: './modules/events/pages/category/category.module#CategoryPageModule' },
  { path: 'event/venue/:slug', loadChildren: './modules/events/pages/viewvenue/viewvenue.module#ViewvenuePageModule' },
  { path: 'event/organizer/:slug', loadChildren: './modules/events/pages/vieworganizer/vieworganizer.module#VieworganizerPageModule' },
  { path: 'event/:slug', loadChildren: './modules/events/pages/viewevent/viewevent.module#VieweventPageModule' },
  { path: 'notes/:slug', loadChildren: './modules/notes-beta/pages/viewnote/viewnote.module#ViewnotePageModule' },
  { path: 'forms/:slug', loadChildren: './modules/forms/forms/forms.module#FormsPageModule' },
  { path: 'iframeheader', loadChildren: './common/iframeheader/iframeheader.module#IframeheaderPageModule' },
  { path: ':slug',  loadChildren: './home/home.module#HomePageModule'},
  {path: 'login/:id/:from', loadChildren: './login/login.module#LoginPageModule'},
  {path: 'give-new/:ch_id/:kiosk', loadChildren: './give-new/give-new.module#GiveNewPageModule'},
  {path: 'delete-user-account/:church_id', loadChildren: './delete-user-account/delete-user-account.module#DeleteUserAccountPageModule'},

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
