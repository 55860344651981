import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {lang} from "moment";

@Injectable({
    providedIn: 'root'
})
export class EventProviderService {

    updateSidebarMenu = new Subject<any>();
    showLogo = new Subject<any>();
    fromPopoverEvents = new Subject<any>();
    updateOldTest = new Subject<any>();     // with obj
    changeColor = new Subject<any>();  // with string
    darkModeOn = new Subject<any>();  // with boolean/string
    userLogin = new Subject<any>();
    changeLogo = new Subject<any>();    // with string
    userLogout = new Subject<any>();
    refreshHome = new Subject<any>();
    fetchGivingDesign = new Subject<any>();
    changeDarkMode = new Subject<any>();     // change-darkmode without data
    changeDarkModeAgain = new Subject<any>();    // change-dark-mode with boolean/string
    searchBible = new Subject<any>();    // with string
    setDefaultColorMode = new Subject<any>();
    defaultLogo = new Subject<any>();
    hideHeader = new Subject<any>();    // with 2 strings
    getTheme: BehaviorSubject<any> = new BehaviorSubject<any>({});    // with obj
    updateNewTest = new Subject<any>();    // with string
    count = new Subject<any>();    // with 1string and 1 obj
    isHomeEnable = new Subject<any>();    // with 1 obj
    isHomeEnableMenu = new Subject<any>();    // with 1 obj
    updateHomeTabs = new Subject<any>();    // with 1 obj
    hideHeaderLogin = new Subject<any>();     // with 2 strings
    showHeader = new Subject<any>();
    showHeaderWithoutMenu = new Subject<any>();
    updateGivingColor = new Subject<any>();
    updateappstories = new Subject<any>(); // with 1 obj
    setBibleHeaderButtons = new Subject<any>(); // with 1 obj
    loadTranslation = new Subject<any>();
    setFavicon = new Subject<any>();
    setTranslationsIcon = new Subject<any>();
    openTranslationModal2 = new Subject<any>();
    openModalTranslation = new Subject<any>();
    updateUrl = new Subject<any>();
    updateBibleLanguage = new Subject<any>();
    getAppPages = new Subject<any>();
    changeAppLanguage = new Subject<any>();
    getAppTheme = new Subject<any>();
    audioPlayer = new Subject<any>();
    stopAudios = new Subject<any>();
    showBibleChapterOnTop = new Subject<any>();
    expandMyAccountSidebar = new Subject<any>();
    chnageFooter = new Subject<any>();
    tokenupdated = new Subject<any>();
    customPageId = new Subject<any>();
    sermonSerId = new Subject<any>();
    allsermonSeriesData = new Subject<any>();
    folderPageId  = new Subject<any>();

    public getChurchData: BehaviorSubject<any> = new BehaviorSubject<any>({});

    constructor() {
    }

    changeLanguageForFooter(apstyle){
        this.chnageFooter.next(apstyle);
    }
    sermonPage(id){
        this.sermonSerId.next(id);
    }

    allsermonSeries(data){
        this.allsermonSeriesData.next(data);
    }

    customePage(id){
        this.customPageId.next(id);
    }
    folderPage(id){
        this.folderPageId.next(id);
    }


    setAudioPlayer(isPlay) {
        this.audioPlayer.next(isPlay);
    }
    stopAudioPlayer(isPlay) {
        this.stopAudios.next(isPlay);
    }
    
    updatehometabs( home_tabs ) {
        this.updateHomeTabs.next({value: home_tabs});
    }

    getapppages( pages ) {
        this.getAppPages.next(pages);
    }
    getapptheme( pages ) {
        this.getAppTheme.next(pages);
    }

    showbiblechapterontop( val ) {
        this.showBibleChapterOnTop.next(val);
    }

    getchurchdata(data){
        localStorage.setItem('church_data', JSON.stringify(data));
        this.getChurchData.next(data)
    }

    getChurchDataFromStorage(){
        return JSON.parse(localStorage.getItem('church_data'));
    }

    changeapplanguage(isChange){
        this.changeAppLanguage.next(isChange);
    }

    setfavicon( icon ) {
        this.setFavicon.next({value: icon});
    }

    expandmyaccountsidebar(  ) {
        this.expandMyAccountSidebar.next("");
    }

    updateurl(  ) {
        this.updateUrl.next("");
    }

    settranslationsicon( status ) {
        this.setTranslationsIcon.next({status: status});
    }

    opentranslationmodal2(  ) {
        this.openTranslationModal2.next("");
    }

    openmodaltranslation(  ) {
        this.openModalTranslation.next("");
    }

    updateSideMenu() {
        this.updateSidebarMenu.next("");
    }

    updategivingcolor() {
        this.updateGivingColor.next("");
    }

    updatebiblelanguage( ) {
        this.updateBibleLanguage.next("");
    }

    setbibleheaderdata(book, chapter, dam_id) {
        this.setBibleHeaderButtons.next({book: book, chapter: chapter, dam_id: dam_id});
    }

    showlogo() {
        this.showLogo.next("");
    }

    showheader() {
        this.showHeader.next("");
    }

    frompopoverevents() {
        this.fromPopoverEvents.next("");
    }

    updateoldtest(obj) {
        this.updateOldTest.next({value: obj});
    }

    changecolor(color) {
        this.changeColor.next({value: color});
    }

    darkmodeon(status) {
        this.darkModeOn.next({value: status});
    }

    userlogin() {
        this.userLogin.next("");
    }

    changelogo(logo) {
        this.changeLogo.next({value: logo});
    }

    userlogout() {
        this.userLogout.next("");
    }

    refresh_home() {
        this.refreshHome.next("");
    }

    fetchgivingdesign() {
        this.fetchGivingDesign.next("");
    }

    changedarkmode() {
        this.changeDarkMode.next("");
    }

    changedarkmodeagain(status) {
        this.changeDarkModeAgain.next({value: status});
    }

    searchbible(input) {
        this.searchBible.next({value: input});
    }

    defaultlogo() {
        this.defaultLogo.next("");
    }

    hideheader(c1, c2) {
        this.hideHeader.next({color1: c1, color2: c2});
    }

    hideheaderlogin() {
        this.hideHeaderLogin.next("");
    }

    showheaderwithoutmenu(value) {
        this.showHeaderWithoutMenu.next({value: value});
    }

    gettheme(obj) {
        this.getTheme.next({value: obj});
    }

    updatenewtest(val) {
        this.updateNewTest.next({value: val});
    }

    countfunc(str, obj) {
        this.count.next({count: str, data: obj});
    }

    ishomeenable(obj) {
        this.isHomeEnable.next({value: obj});
    }

    ishomeenablemenu(obj) {
        this.isHomeEnableMenu.next({value: obj});
    }

    setdefaultcolormode() {
        this.setDefaultColorMode.next("");
    }

    updateAppStories( stories ) {
        this.updateappstories.next({value: stories});
    }

    updatedToken(token,url) {
        this.tokenupdated.next({token : token , url : url})
    }

}
