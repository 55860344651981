import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';
import {ToastService} from '../../../services/toast.service';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";
import {SocialSharing} from "@ionic-native/social-sharing/ngx";

@Component({
    selector: 'app-sharing-modal',
    templateUrl: './sharing-modal.component.html',
    styleUrls: ['./sharing-modal.component.scss'],
})
export class SharingModalComponent implements OnInit {

    // app_url = 'http://localhost:8100';
    app_url = 'https://devpwabuilder.churchbase.com';

    fbUrl: any = 'https://facebook.com/sharer/sharer.php?u=';
    twitterUrl: any = 'https://twitter.com/intent/tweet?text=';
    whatsappUrl: any = 'whatsapp://send?text=';
    current_url: any;
    selected_verse;
    selected_verse_id;
    selected_chapter_id;
    ch_id;
    selected_book_name;
    have_teaching;
    teaching_link;
    teaching: boolean = false;
    alertMessage: any;

    constructor(public modalController: ModalController,
                private router: Router,
                private toast: ToastService,
                private dom: DomSanitizer,
                private translate: TranslateService,
                private socialSharing: SocialSharing
    ) {
        // get translated alert message
        let interval = setInterval(() => {
            this.translate.get('ALERTS').subscribe( (res: any) => {
                this.alertMessage = res;
                clearInterval(interval);
            });
        }, 2000)
    }

    dismiss() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    ngOnInit() {
        this.current_url = this.app_url + this.router.url;

        let share_data = '"' + this.selected_verse + '" ' + this.selected_book_name + ' ' + this.selected_chapter_id + ':' + this.selected_verse_id;
        this.selected_verse = share_data;

        if (this.have_teaching) {
          this.selected_verse = this.selected_verse + ',  Teaching Link: ' + this.teaching_link;
        }


    }


    copyLink() {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.selected_verse;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.toast.presentToastWithOptions((this.alertMessage ? this.alertMessage.link_copied_to_clipboard : 'Link Copied to Clipboard'), (this.alertMessage ? this.alertMessage.LinkCopied : 'Link Copied'), 'success');
        this.dismiss();
    }

    sanitize_url(url) {
        return this.dom.bypassSecurityTrustUrl(url);
    }


}
