import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../environments/environment";
import {Storage} from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    _headers: HttpHeaders;

    constructor(
        private http: HttpClient,
        private storage: Storage,
    ) {
        this._headers = new HttpHeaders();
        this._headers = this._headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }

    getToken(body){
        let api = environment.api_end_point + '/auth/getGuestToken';
        return this.http.post(api, (body), {headers: this._headers});
    }

    loginWithNumber(body: any) {
        let api = environment.api_end_point + '/auth/verificationcode';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }
    newLoginWithNumber(body: any) {
        let api = environment.api_end_point + '/auth/sendOTP';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    loginWithEmail(body: any) {
        let api = environment.api_end_point + '/auth/emailverificationcode';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    verifyOTP(body: any, from_email) {
        var api;
        if (from_email === true){
            api = environment.api_end_point + '/auth/emaillogin';
        } else {
            api = environment.api_end_point + '/auth/login';
        }
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    createPersonToken(body: any) {
        let api = environment.api_end_point + '/churchs/persondevice';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    deleteAccount(body: any) {
        let api = environment.api_end_point + '/auth/deleteaccount';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    registerUser(body: any) {
        let api = environment.api_end_point + '/Auth/signUpForm';
        return this.http.post(api, body, {headers: this._headers});
    }
    createUser(body: any) {
        let api = environment.api_end_point + '/Auth/create';
        return this.http.post(api, body, {headers: this._headers});
    }
    getUserForms(body : any) {
        let api = environment.api_end_point + '/Auth/editUserFrom';
        return this.http.post(api, body, {headers: this._headers});
    }
    updaterUserForm(body : any) {
        let api = environment.api_end_point + '/Auth/updateUserForm';
        return this.http.post(api, body, {headers: this._headers});
    }
    getFormFields(body: any) {
        let api = environment.api_end_point + '/Contentcms/signinPage';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    storeUser(body: any) {
        this.storage.set('user', body);
        localStorage.setItem('auth', body.auth.access_token);
        localStorage.setItem('refresh_token', body.auth.refresh_token);
        localStorage.setItem('userId', body.user.id ? JSON.stringify(body.user.id) : '');
    }

    storeGuest(body){
        this.storage.set('user', body);
    }

    storeUserGiving(body: any) {
        this.storage.set('user_giving', body);
    }

    getUserGiving() {
       return  this.storage.get('user_giving');
    }

    getUser() {
        return this.storage.get('user');
    }

    async logoutUser() {
        await this.storage.remove('user').then( res => {
        })
        localStorage.removeItem('auth');
        localStorage.removeItem('refresh_token');
    }

    async logoutUserGiving() {
        await this.storage.remove('user_giving').then( res => {
        })
    }

    getcountrycodes() {
        let api = environment.api_end_point + '/churchs/getcountrycodes';
        return this.http.get(api);
    }

    getUserProfile(donor_id : any){ 
    
        const body = {
            "donor_id": donor_id
        };
     
        let api = environment.api_end_point + '/Auth/getUserProfile';
        return this.http.post(api, body, {headers: this._headers});
    }

    
  delete_profile(payload: any){
    return this.http.post<any>(
      environment.api_end_point + "/churchs/deleteUser", payload);
  }
}
