import {Injectable} from '@angular/core';
import {LoadingController} from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    constructor(
        private loading: LoadingController
    ) {}

    async presentLoading() {
        const loadingC = await this.loading.create({
            spinner: 'crescent',
            message: 'LOADING',
            translucent: true,
            cssClass: 'custom-class custom-loading'
        });
        return await loadingC.present();
    }

    async stopLoading() {
        await this.loading.dismiss();
    }

}
