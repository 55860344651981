import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../environments/environment";
import {Storage} from "@ionic/storage";

@Injectable({
    providedIn: 'root'
})
export class BibleService {
    _headers: HttpHeaders;

    initial_url = "https://4.dbt.io/";

    constructor(
        private http: HttpClient,
        private storage: Storage
    ) {
        this._headers = new HttpHeaders();
        this._headers = this._headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }

    getbibleBooks(dam_id) {
        let api = this.initial_url + 'library/book/?v=2&key=2ff95acf3188d428a46c8241805219a1&dam_id=' + dam_id;
        return this.http.get(api);

    }

    getbibleBooksText(dam_id) {
        let api = this.initial_url + 'library/book/?v=2&key=2ff95acf3188d428a46c8241805219a1&dam_id=' + dam_id;
        return this.http.get(api);
    }

    getBookChapterAudio(dam_id: string, book_id: string) {
        let api = this.initial_url + 'audio/path/?v=2&key=2ff95acf3188d428a46c8241805219a1&dam_id=' + dam_id + '&book_id=' + book_id;
        return this.http.get(api);
    }

    getChapterText(dam_id: string, book_id: string, chapter_id: string) {
        let api = this.initial_url + 'text/verse/?key=2ff95acf3188d428a46c8241805219a1&dam_id=' + dam_id + '&book_id=' + book_id + '&chapter_id=' + chapter_id + '&v=2';
        return this.http.get(api);
    }

    getAudioLocation() {
        let api = this.initial_url + 'audio/location/?v=2&key=2ff95acf3188d428a46c8241805219a1&protocol=https';
        return this.http.get(api);
    }

    getCopyrightInfo(dam_id) {
        let api = this.initial_url + 'library/metadata?key=2ff95acf3188d428a46c8241805219a1&dam_id=' + dam_id + '&v=2';
        return this.http.get(api);
    }

    search(dam_id, text) {
        let api = this.initial_url + "text/search?key=2ff95acf3188d428a46c8241805219a1&dam_id=" + dam_id + "&query=" + text + "&v=2&limit=1000";
        return this.http.get(api);
    }

    saveVerse(body) {
        let api = environment.api_end_point + '/churchs/saveverse';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getVerses(donor_id, ch_id) {
        let api = environment.api_end_point + '/churchs/verses/' + donor_id + '/' + ch_id;
        return this.http.get(api);
    }

    volumelanguagefamily() {
        let api = this.initial_url + 'library/volumelanguagefamily?key=2ff95acf3188d428a46c8241805219a1&v=2';
        return this.http.get(api);
    }

    getVolumes(family_code) {
        let api = this.initial_url + 'library/volume?key=2ff95acf3188d428a46c8241805219a1&language_family_code=' + family_code + '&v=2';
        return this.http.get(api);
    }

    getVerseTime(dam_id, book_id, chap_id, verse_id) {
        let api = this.initial_url + "audio/versestart?key=2ff95acf3188d428a46c8241805219a1&dam_id=" + dam_id + "&osis_code=" + book_id + "&chapter_number=" + chap_id + "&verse_number="+ verse_id + "&v=2";
        // let api = this.initial_url + 'audio/versestart?key=2ff95acf3188d428a46c8241805219a1&dam_id=' + dam_id + '&osis_code=' + book_id + '&chapter_number=' + chap_id + '&v=2';
        return this.http.get(api);
    }

    saveLastOff(body) {
        let api = environment.api_end_point + '/churchs/updatelatestbiblereading';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

}
