import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {

  dark_mode: boolean = false;

  constructor() { }

  getDark_mode(){
    return this.dark_mode;
  }

  setDark_mode( val ){
    this.dark_mode = val;
  }

}
