import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from 'src/services/api.service';
import {LoaderService} from 'src/services/loader.service';
import {Storage} from '@ionic/storage';
import {Title} from '@angular/platform-browser';
import {NavigationExtras, Router} from '@angular/router';
import {LogoServiceService} from '../../../services/logo-service.service';
import {CommonService} from '../../../services/common.service';
import {EventProviderService} from "../../../services/event-provider.service";
import {InAppBrowser, InAppBrowserOptions} from "@ionic-native/in-app-browser/ngx";
import {StoryPagePage} from "../../story-page/story-page.page";
import {AlertController, ModalController, Platform} from "@ionic/angular";
import {books} from "googleapis/build/src/apis/books";
import { BibleTranslationsComponent } from '../bible-translations/bible-translations.component';
import {forEach} from "@angular-devkit/schematics";
import {environment} from "../../../environments/environment.prod";
import { SlugService } from 'src/services/slug.service';
import { Location } from '@angular/common';
import { IdService } from 'src/services/id.service';
import { GeneralSearchComponent } from '../general-search/general-search.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [StoryPagePage]
})

export class HeaderComponent implements OnInit, OnDestroy {
    theme: any;
    data: any;
    login: boolean;
    logoURL: any;
    churchname: any;
    userLoggedIn: boolean = false;
    mobileLogo: boolean = true;
    app_pages: any = [];
    home_title: any = "Home";
    check: any;
    church_id: any;

    // header pages
    sermons: boolean = false;
    locations: boolean = false;
    groups: boolean = false;
    bible: boolean = false;
    chat: boolean = false;
    profile: boolean = false;
    feed: boolean = false;
    enable_home: boolean = true;
    event_theme: any;
    default_logo: any;
    home_tabs: any;

    unseen_stories: boolean = false;
    app_stories: any = [];
    churchnameForStory: any;
    bibleleftoffpath: any;
    user: any;
    path_origin: any = '';

    bibleButtonChapter: any;
    bibleButtonBook: any;
    current_bible_language: any = {language_family_code: 'ENG', language_family_name: 'English', selection_type: 'default'};
    current_bible_version: any = {dam_id: 'EN1WEB', volume_name: 'WEB - Winfred Henson', version_code: 'WEB'};
    is_bible_read_page: boolean = false;
    show_bible_chapter_on_top: boolean = false;
    current_url: any;
    bibleButtonDamId: any;
    translationsIcon : boolean = false;
    hideHeader : boolean = false;
    showHeaderWithoutMenu : boolean = false;
    options : InAppBrowserOptions = {
        closebuttoncaption : 'Close',
        closebuttoncolor: '#FFFFFF',
        disallowoverscroll: 'no',
        hidenavigationbuttons: 'no',
        toolbar: 'yes',
        toolbarposition: 'top',
        location: 'yes'
    };

    pwaLogoUrlWidth = {
        width: '180px'
    };
 
    constructor(
        private apicallService: ApiService,
        private loader: LoaderService,
        private storage: Storage,
        private titleService: Title,
        private url: Router,
        private logoService: LogoServiceService,
        private common: CommonService,
        private router: Router,
        private event_provider: EventProviderService,
        private iab: InAppBrowser,
        public modalController: ModalController,
        public alertController: AlertController,
        private platform: Platform,
        public slug:SlugService,
        public location:Location,
        public idservice:IdService

    ) {
        // saving bible header data for ISM
        this.storage.get("bibleheaderdata").then(val=>{
            if (val){
                this.bibleButtonChapter = val.chapter;
                this.bibleButtonBook = val.book;
                this.bibleButtonDamId = val.dam_id;
            }
        });

        let current_url = JSON.parse(localStorage.getItem("url"));
        this.current_url = current_url.pathname;
        this.path_origin = current_url.origin;

        // getting status of header logo
        this.mobileLogo = logoService.getStatus();

        // get User data  from local storage
        this.authCheck();

        // getting side_nac status
        storage.get('login_sidenav').then((val) => {
            this.login = val;
        });

        // Checking User Status
        this.event_provider.userLogout.subscribe(value => {              // listening event
            this.logout();
        });

        // updating stories status
        this.event_provider.updateappstories.subscribe(value => {              // listening event
            if (value.value){
                this.app_stories = value.value;


                let checkStatus = this.app_stories.find( x => {
                    if (x.seen === false){
                        return x;
                    }
                });


                if(checkStatus){
                    this.unseen_stories = true;
                } else {
                    this.unseen_stories = false;
                }
            }
        });

        // updating user info after Log In

        this.event_provider.userLogin.subscribe(value => {              // listening event
            this.authCheck();
        });

        // hiding or enabling the translations icon
        this.event_provider.setTranslationsIcon.subscribe(value => {              // listening event
            if (value){
                this.translationsIcon = value.status;
            }
        });

        this.event_provider.setBibleHeaderButtons.subscribe(value => {              // listening event
            if (value){
                this.bibleButtonChapter = value.chapter;
                this.bibleButtonBook = value.book;
                this.bibleButtonDamId = value.dam_id;

                let b = localStorage.getItem('bible') ? JSON.parse(localStorage.getItem('bible')) : '';
                if (b) {
                    if (b.language) {
                        this.current_bible_language = b.language;
                    }
                    if (b.volume) {
                        this.current_bible_version = b.volume;
                    }
                }
                let bible_obj = {
                    language: this.current_bible_language,
                    volume: this.current_bible_version
                };
                // storing in local storage
                localStorage.setItem("bible", JSON.stringify(bible_obj));
            }
        });

        // event subscription for show logo or not

        this.event_provider.showLogo.subscribe(value => {              // listening event
            this.mobileLogo = logoService.getStatus();
        });

        // event subscription for changing mode

        this.event_provider.changeDarkModeAgain.subscribe(value => {              // listening event
              if (value.value) {
                    this.logoURL = this.theme.dark_logo ? this.theme.dark_logo : this.theme.logo ;
                } else {
                    this.logoURL = this.theme.logo;
                }
        });

        // event subscription for updating logo to default logo

        this.event_provider.defaultLogo.subscribe(value => {              // listening event
            this.logoURL = this.default_logo;
        });

        // event subscription for changing logo

        this.event_provider.changeLogo.subscribe(value => {              // listening event
            this.logoURL = value.value;
        });

        this.event_provider.showBibleChapterOnTop.subscribe(value => {              // listening event
            this.show_bible_chapter_on_top = value;
        });

        // event subscription to hide header
        this.event_provider.hideHeader.subscribe(value => {              // listening event
            this.hideHeader = true;
        });

        // event subscription to show header
        this.event_provider.showHeader.subscribe(value => {              // listening event
            this.hideHeader = false;
        });

        // event subscription to show header
        this.event_provider.showHeaderWithoutMenu.subscribe(value => {              // listening event
            this.showHeaderWithoutMenu = value;
        });

    }

    ngOnInit() {
        this.event_provider.getAppTheme.subscribe(value => {              // listening event
            if (value) {
                this.event_theme = value;
                this.theme = this.event_theme;

                this.church_id = this.theme.ch_id;

                // getting app pages
                let count = 0;
                // getting latest app pages in 2 seconds timer
                // to call after theme pages have already loaded
                let interval = setInterval(() => {
                    count++;
                    if (count === 2) {
                        this.getAppPages();
                        clearInterval(interval);
                    }
                }, 1000)
            }
        });

        // event subscription, runs when event fired from common service to update if HOME Page is enabled or not

        this.event_provider.isHomeEnable.subscribe(value => {              // listening event
            this.enable_home = value.value.is_enable_menu;
        });
    }

    openTranslationsModal(){
        this.modalController.getTop().then( (val) => {
            if (!val){
                this.event_provider.openmodaltranslation();
            }
        })
    }

    async openTranslationsModalTwo(){
        const modal = await this.modalController.create({
            component: BibleTranslationsComponent,
            cssClass: 'TranslationModel',
            componentProps: {
                filtered_volumes: '',
                selected_lang: '',
                selected_vol: '',
                all_langs: ''
            }
            
        });

        modal.onDidDismiss().then( (res: any) => {
            // getting bible status from local storage
            this.storage.get('bible').then((b) => {
                if (b) {
                    if (b.language) {
                        // this.selected_language = b.language;
                    }
                    if (b.volume) {
                        // this.selected_volume = b.volume;
                    }
                }
            });

            
        });
        return await modal.present();
    }

    async showTranslation( ) {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Translation',
            message: this.current_bible_language.language_family_name + ', '+ this.current_bible_version.volume_name,
        });

        await alert.present();
    }

    gotobackPage( back = '') {
        if (back === ''){
            let temp = localStorage.getItem('bible_last_read');
            if(temp){
                let last_read = JSON.parse(temp);
                let data: NavigationExtras = {
                    queryParams: {
                        chapter: JSON.stringify(last_read.chapter),
                        book: JSON.stringify(last_read.book),
                        dam_id: last_read.dam_id,
                        page: last_read.selected_page,
                        from: 'header',
                    }
                };
                this.router.navigate(['/bible-chapter'], data);
            }
            else {
                this.router.navigate(['/bible-chapter']);
            }
        } else {
            if (this.bibleButtonDamId.charAt(6) == 'O'){
                this.router.navigate(['/bible/old-testament', { book_id: this.bibleButtonBook.book_id, chapter_id: this.bibleButtonChapter.id}]);
            } else if (this.bibleButtonDamId.charAt(6) == 'N'){
                this.router.navigate(['/bible/new-testament', { book_id: this.bibleButtonBook.book_id, chapter_id: this.bibleButtonChapter.id}]);
            }
            this.event_provider.setbibleheaderdata(this.bibleButtonBook, this.bibleButtonChapter, this.bibleButtonDamId);
        }
    }

    gotoHome() {
        if(!this.current_url.includes('giving')){
            if (this.enable_home && !this.showHeaderWithoutMenu) {
                // for ISM app
                let pageName;
                if (this.theme.ch_id === '1000'){
                    pageName = 'ism-home/' + this.theme.ch_id;
                    this.router.navigate([pageName]);
                } else {
                    pageName = 'home-tabs/home/' + this.theme.ch_id;
                    this.router.navigate([pageName], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree(['/'])));
                }
            }
        }
    }

    async getDesign() {
        this.loader.presentLoading().then(async () => {
            // getting theme from local storage
            let theme = await this.storage.get('theme');
            if (theme) {
                this.theme = theme;



                // getting app pages
                this.getAppPages();
                this.loader.stopLoading();
            } else {
                if (this.event_theme) {
                    this.theme = this.event_theme;
                    // getting app pages
                    this.getAppPages();
                }
                this.loader.stopLoading();

            }
        });
    }

     async gotoBibleLeftOff(){
        if (this.bibleleftoffpath && this.userLoggedIn){
            let pageName = this.bibleleftoffpath;
            window.open(pageName, "_self");
            // this.router.navigate([pageName]);
        } else {
            if (!this.userLoggedIn) {
                const alert = await this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Login!',
                    message: "Login to pick up where you left.",
                });
                await alert.present();
            }
        }
    }

    getAppPages() {
            let theme_color = this.theme.color ? this.theme.color : '#302f34';
            let theme_color_secondary = this.theme.secondary_color ? this.theme.secondary_color : '#ADACAE';
            if (theme_color) {
                let url = new URL(window.location.href);
                let new_path = url.pathname.split('/');
                if(new_path[1] != 'give-new'){
                    let url = new URL(window.location.href);
                    let new_path = url.pathname.split('/');
                    if(new_path[1] != 'give-new'){
                        this.changeTheme(theme_color);
                    }
                }
                // this.changeTheme(theme_color);
            }
            if (theme_color_secondary) {
                this.changeThemeSecondary(theme_color_secondary);
            }

        // to check which tab is active
        if (this.theme.home_tabs){
            this.home_tabs = JSON.parse(this.theme.home_tabs);
        }

        // to save stories
        if (this.theme.app_stories){
            this.app_stories = JSON.parse(this.theme.app_stories);
            let checkStatus = this.app_stories.find( x => {
                if (x.seen === false){
                    return x;
                }
            });

            if(checkStatus){
                this.unseen_stories = true;
            } else {
                this.unseen_stories = false;
            }
        }

        if (this.userLoggedIn){
            //getting bibleleftoffpath
            this.getbibleleftoff();
        }

            // to update all giving pages
            this.event_provider.fetchgivingdesign();               // calling event
            this.event_provider.updategivingcolor();

            // Setting title
            this.common.getSEO(this.church_id).subscribe((res: any) => {
                if(res.status && res.fields.title != ''){
                    this.titleService.setTitle(res.fields.title);
                } else {
                    this.titleService.setTitle(this.theme.church_name);
                }
            });

           // assigning church name
            this.churchnameForStory = this.theme.church_name;

            if (this.theme.app_style === '2') {
                if (this.theme.dark_logo !== '' && this.theme.dark_logo !== null) {
                    // assigning dark logo
                    this.logoURL = this.theme.dark_logo;
                } else {
                    // assigning light logo
                    this.logoURL = this.theme.logo;
                }
            } else if (this.theme.app_style === '1') {
                if (this.theme.logo !== '' && this.theme.logo !== null) {
                    // assigning light logo
                    this.logoURL = this.theme.logo;
                } else {
                    // assigning church name
                    this.churchname = this.theme.church_name;
                }
            }

            this.pwaLogoUrlWidth.width = this.theme.pwa_home_logo_size ? this.theme.pwa_home_logo_size + 'px' : '180px';

            // saving default logo
            this.default_logo = this.logoURL;

            let app_pages = this.common.appPages;
            if (app_pages && app_pages.length > 0) {

                // HOME TITLE TO SHOW ON NAV BAR
                let one_page = app_pages.find(x => x.page_type === 'home');
                if(one_page) {
                    this.home_title = one_page.title;
                }

                let result;

                // filtering active pages for browser
                result = app_pages.filter(x => x.activated === 'true' && x.show_pwa === 'true' && x.page_type !== 'home');

                // saving maximun 5 pages on header menu
                if (result.length >= 5) {
                    for (let i = 0; i < 5; i++) {
                        this.app_pages[i] = result[i];
                    }
                } else {
                    this.app_pages = result;
                }
            }

            let count = 0;
            // 3 seconds timer to stop loading after everything is loaded completely
            let interval = setInterval(() => {
                this.loader.stopLoading();
                clearInterval(interval);
            }, 4000)
    }

    logout() {
        this.userLoggedIn = false;
        this.storage.set('user', 'guest');

        // update sidebar after logout to hide logout button
        // this.events.publish('updateSidebar');
        this.event_provider.updateSideMenu();            // ------------- test
    }

    async authCheck() {
        // getting user from local storage
        this.storage.get('user').then((val: any) => {
            if (val) {
                if (val.user != null && val !== 'guest') {
                    this.userLoggedIn = true;
                    this.user = val;
                } else {
                    this.userLoggedIn = false;
                }
            }
        });
    }

    getbibleleftoff(){
        const body = JSON.stringify({
            auth: 'Bearer ' + this.user.auth.access_token,
            church_id: this.theme.ch_id,
            account_donor_id: this.user.user.id,
        });
        this.apicallService.getbibleleftoff(body).subscribe((res: any) => {
            if (res && res.status){
                if (res.data.path){
                    this.bibleleftoffpath = res.data.path;
                }
            }
        })
    }

    changeTheme(color: string) {
        // updating primary color in variable.scss file
        document.documentElement.style.setProperty('--primary-color', color);
    }

    changeThemeSecondary(color: string) {
        // updating secondary color in variable.scss file
        document.documentElement.style.setProperty('--secondary-color', color);
    }

    async openModal() {
        if (this.app_stories.length > 0) {
            let modal;
            modal = await this.modalController.create({
                component: StoryPagePage,
                cssClass: 'StoryModal',
                componentProps: {
                    stories: this.app_stories,
                    ch_id: this.theme.ch_id,
                    logoUrl: this.logoURL,
                    churchnameForStory: this.churchnameForStory,
                }
            });

            return await modal.present();
        }

    }

    // navigating Menu link
    navigateToPage(page_id, page_type, pageName, pagehtml, pageData) {
        if (page_type === 'page') {
            if (pageName === 'Audio') {
                pageName = 'audio-archive';
                this.slug.setPage_id("audio-archive",page_id);
                this.slug.setSlugName( "audio-archive" , pageData.slug );
                this.router.navigate(['audio-archive'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Community') {
                this.slug.setPage_id("community",page_id)
                this.slug.setSlugName( "community" , pageData.slug);
                this.router.navigate(['/community'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Video') {
                pageName = 'video-archive';
                this.slug.setPage_id("video-archive",page_id)
                this.slug.setSlugName( "video-archive" , pageData.slug );
                this.router.navigate(['video-archive'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Events') {
                this.slug.setPage_id("events",page_id)
                this.slug.setSlugName( "events" , pageData.slug )
                this.router.navigate(['events'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Form') {
                if(localStorage.getItem('is_wordpress_enable')) {
                    let form_page = this.app_pages.filter(x => x.id === page_id);
                    if (form_page.length > 0) {
                        this.router.navigate(['/forms/' + form_page[0].html]);
                    }
                } else {
                    this.slug.setForm(page_id);
                    this.slug.setSlugName( "form" , pageData.slug);
                    this.router.navigate(['/form'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }
            } else if (pageName === 'Campaign Registration') {
                this.router.navigate(['/campaign-registration/' + this.theme.ch_id + '/' + pagehtml]);
            } else if (pageName === 'Donations') {
                let link_page = this.app_pages.find(x => x.id === page_id);
                if (link_page) {
                    let open_external = link_page.open_external;
                    if (open_external === 'true') {
                        let url = JSON.parse(localStorage.getItem("url"));
                        let donations_url = url.origin + "/give-new/" + this.theme.ch_id;

                        if(this.platform.is("mobile") || this.platform.is("pwa")){
                            if (url.host.includes("pwabuilder.churchbase.com")) {
                                let navigationExtras: NavigationExtras = {
                                    queryParams: {
                                        url: link_page.url
                                    }
                                };
                                this.slug.setIframe(link_page.url)
                                this.slug.setSlugName( "iframeheader" , pageData.slug);
                                this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                            }
                            else{
                                this.openurlinbrowser(donations_url);
                            }
                        } else {
                            this.openurlinbrowser(donations_url);
                        }
                    } else {
                        this.router.navigateByUrl('/my-contribute/'+link_page.html);
                    }
                }
            } else if (pageName === 'Feed') {
                if ((this.home_tabs && this.home_tabs[1].status === 'true') || this.theme.ch_id === '1000'){
                    this.router.navigate(['/feed-tab']);
                } else {
                    this.router.navigate(['home-tabs/feed-tab']);
                }
            } else if (pageName === 'Chat') {
                if ((this.home_tabs && this.home_tabs[2].status === 'true') || this.theme.ch_id === '1000'){
                    this.router.navigate(['/chat']);
                } else {
                    this.router.navigate(['home-tabs/chat']);
                }
            } else if (pageName === 'iFrame') {
                this.slug.setPage_id("in-app-iframe",page_id)
                this.slug.setSlugName( "in-app-iframe/" , pageData.slug);
                this.router.navigate(['in-app-iframe/'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));

            } else if (pageName === 'Custom Post') {
                this.slug.setPage_id("custom-post",page_id)
                this.slug.setSlugName( "custom-post" , pageData.slug);
                this.router.navigate(['custom-post/'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));

            } else if (pageName === 'Live Stream') {
                this.slug.setCh_Id(this.theme.ch_id) 
                this.slug.setSlugName( "live-stream" , pageData.slug);
                this.router.navigate(['live-stream'], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else if (pageName === 'Bible') {
                if (this.theme.ch_id == '1000') {
                    this.router.navigate(['/bible-chapter']);
                } else {
                    pageName = pageName.replace(' ', '-');
                    pageName = pageName.toLowerCase();

                    this.router.navigate([pageName]);
                }
            }else if (pageName === 'Contact Us') {
                this.slug.setCh_Id(this.theme.ch_id)
                // this.slug.setSlugName( "contact-us" , pageData.slug)
                this.router.navigate(['/contact-us/' , {page_id: page_id}]);
            } 
            else if (pageName === 'Newsletter') {
                this.slug.setSlugName( "newsletter" , pageData.slug);
                this.router.navigate(['/newsletter/' ], { skipLocationChange: true, replaceUrl: true });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
            } else {
                if(pageName === 'Sermon Series'  ){
                    var data={
                        id:JSON.parse(pageData.series_id),
                        ch_id:this.church_id,
                        page_id: page_id
                    }
                    this.slug.setAllSermon(data)
                    this.event_provider.allsermonSeries(data);
                    this.slug.setSlugName( "all-sermons" , pageData.slug )
                    this.router.navigate(['all-sermons'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }else if( pageName === 'Sermons' ){
                    this.event_provider.sermonPage(page_id)
                    this.slug.setPage_id("sermons",page_id)
                    this.slug.setSlugName( "sermons" , pageData.slug)
                    this.router.navigate(['/sermons'], { skipLocationChange: true, replaceUrl: true });
                    this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                }else{
                    pageName = pageName.replace(' ', '-');
                    pageName = pageName.toLowerCase();
                    this.router.navigate([pageName]);
                }
            }
        } else if (page_type === 'folder') {
            this.slug.setFolder(page_id)
            this.slug.setSlugName( "folder" , pageData.slug);
            this.event_provider.folderPage(page_id);
            this.router.navigate(['/folder'], { skipLocationChange: true, replaceUrl: true });
            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
        } else if (page_type === 'custom') {
            this.slug.setPage_id("custom-page",page_id);
            this.event_provider.customePage(page_id);
            this.slug.setSlugName( "custom-page" , pageData.slug);
            this.router.navigate(['/custom-page'], { skipLocationChange: true, replaceUrl: true });
            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
        } else if (page_type === 'home') {
            if (this.theme.ch_id === '1000'){
                pageName = 'ism-home/' + this.theme.ch_id;
                this.router.navigate([pageName]);
            } else {
                pageName = 'home-tabs/home/' + this.theme.ch_id;
                this.router.navigate([pageName],{ skipLocationChange: true, replaceUrl:false });
                this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([''])));
            }
        } else if (page_type === 'link') {
            let link_page = this.app_pages.find(x => x.id === page_id);
            if (link_page) {
                let open_external = link_page.open_external;
                if (open_external === 'true') {
                    let url = JSON.parse(localStorage.getItem("url"));
                    if (url.host.includes("pwabuilder.churchbase.com")) {
                        let navigationExtras: NavigationExtras = {
                            queryParams: {
                                url: link_page.url
                            }
                        };
                        this.slug.setIframe(link_page.url);
                        this.slug.setSlugName( "iframeheader" , pageData.slug);
                        this.router.navigate(["/iframeheader"], { skipLocationChange: true, replaceUrl: true });
                        this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([pageData.slug])));
                    } else {
                        this.openurlinbrowser(link_page.url);
                    }
                } else {
                    this.openurlinapp(link_page.url);
                }
            }
        }

    }

    // inside app on page with done button
    openurlinapp(url){
        if(this.platform.is('android') || this.platform.is('iphone')) {
            this.iab.create(url, '_blank', this.options);
        } else {
            this.iab.create(url, '_self', this.options);
        }
    }

    // in external safari/chrome
    openurlinbrowser(url){
        this.iab.create(url, '_system');
    }

    ngOnDestroy() {
        // this.events.unsubscribe('userLogout', null);
        // this.events.unsubscribe('userLogin', null);
    }

    searching(){
            this.filterModal()
            
    }
    async filterModal() {
        const modal = await this.modalController.create({
            component: GeneralSearchComponent,
            cssClass: 'BibleModal',
            componentProps: {
            }
        });
        modal.onDidDismiss().then(res => {

        });
        return await modal.present();
    }

   
}
