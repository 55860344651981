import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {NavigationExtras, Router} from '@angular/router';

@Component({
    selector: 'app-bible-filter-modal',
    templateUrl: './bible-filter-modal.component.html',
    styleUrls: ['./bible-filter-modal.component.scss'],
})
export class BibleFilterModalComponent implements OnInit {
    public items: any = [];

    old_data;
    new_data;
    total_data;

    old_expand: any = [];
    new_expand: any = [];

    new_filter_data: any;
    old_filter_data: any;

    tab_to_open: boolean = false;

    constructor(public modalController: ModalController, private router: Router,
    ) {

        this.items = [
            {expanded: false},
            {expanded: false},
            {expanded: false},
            {expanded: false},
            {expanded: false},
            {expanded: false},
            {expanded: false},
            {expanded: false},
            {expanded: false}
        ];
    }

    expandItem(index) {
        // if (item.expanded) {
        //     item.expanded = false;
        // } else {
        //     this.items.map(listItem => {
        //         if (item == listItem) {
        //             listItem.expanded = !listItem.expanded;
        //         } else {
        //             listItem.expanded = false;
        //         }
        //         return listItem;
        //     });
        // }
    }

    dismiss() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    ngOnInit() {
        let filter_data = [];
        let obj: any = {
            name: '',
            chapters: []
        };

        // NEW testament DATA
        if (this.new_data && this.new_data.length > 0) {
            let current_obj = this.new_data[0];
            current_obj.chapters = [];
            let current_name = this.new_data[0].book_name;

            // ================= setting data with total verses chapterwise ==========================
            for (let i = 0; i < this.new_data.length; i++) {
                if (this.new_data[i].book_name === current_name) {
                    if (!current_obj.chapters.includes(this.new_data[i].chapter_id)) {
                        current_obj.chapters.push(this.new_data[i].chapter_id);
                    }
                    current_obj.book_id = this.new_data[i].book_id;
                    current_obj.dam_id = this.new_data[i].dam_id;
                } else {
                    filter_data.push(current_obj);
                    current_name = this.new_data[i].book_name;
                    current_obj = this.new_data[i];
                    current_obj.chapters = [];
                    current_obj.chapters.push(this.new_data[i].chapter_id);
                    current_obj.book_id = this.new_data[i].book_id;
                    current_obj.dam_id = this.new_data[i].dam_id;
                }
            }
            this.new_filter_data = filter_data;
            // ================= setting data with total verses chapterwise ==========================
        }


        // OLD testament DATA
        if (this.old_data && this.old_data.length > 0) {
            let filter_data_old = [];
            let current_obj_old = this.old_data[0];
            current_obj_old.chapters = [];
            let current_name_old = this.old_data[0].book_name;

            // ================= setting data with total verses chapterwise ==========================
            for (let i = 0; i < this.old_data.length; i++) {
                if (this.old_data[i].book_name === current_name_old) {
                    if (!current_obj_old.chapters.includes(this.old_data[i].chapter_id)) {
                        current_obj_old.chapters.push(this.old_data[i].chapter_id);
                    }
                    current_obj_old.book_id = this.old_data[i].book_id;
                    current_obj_old.dam_id = this.old_data[i].dam_id;
                } else {
                    filter_data_old.push(current_obj_old);
                    current_name_old = this.old_data[i].book_name;
                    current_obj_old = this.old_data[i];
                    current_obj_old.chapters = [];
                    current_obj_old.chapters.push(this.old_data[i].chapter_id);
                    current_obj_old.book_id = this.old_data[i].book_id;
                    current_obj_old.dam_id = this.old_data[i].dam_id;
                }
            }
            this.old_filter_data = filter_data_old;
            // ================= setting data with total verses chapterwise ==========================
        }

    }

    // opening the selected verse in chapter page
    selectVerse(item, chap) {
        if (item) {
            item.chapter_id = chap;

            let data: NavigationExtras = {
                queryParams: {
                    data: JSON.stringify(item),
                    type: 'search',
                    chapter: JSON.stringify(item),
                    book: JSON.stringify(item),
                }
            };
            this.dismiss();
            this.router.navigate(['/bible-chapter'], data);
        }
    }

}
