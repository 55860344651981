import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class IdService {
    loginId: any;

    constructor(private route: ActivatedRoute) {
    }

    setId(id) {
        this.loginId = id;
    }

    getId() {
        return this.loginId;
    }

}
