import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-connect-device-modal',
  templateUrl: './connect-device-modal.component.html',
  styleUrls: ['./connect-device-modal.component.scss'],
})
export class ConnectDeviceModalComponent implements OnInit {

    constructor(public modalController: ModalController) { }
    dismiss() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }

  ngOnInit() {}

}
