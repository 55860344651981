import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {Router} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {LoaderService} from "../../../services/loader.service";
import {ToastService} from "../../../services/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {Location} from "@angular/common";
import { SlugService } from 'src/services/slug.service';

@Component({
  selector: 'app-page-password-modal',
  templateUrl: './page-password-modal.component.html',
  styleUrls: ['./page-password-modal.component.scss'],
})
export class PagePasswordModalComponent implements OnInit {

  entered_password: any = '';
  page_password: any;
  alertMessage: any;

  constructor(
      public modalController: ModalController,
      private apiService: ApiService,
      private loader: LoaderService,
      private toast: ToastService,
      private translate: TranslateService,
      private location: Location,
      public slug:SlugService
  ) {
    let interval = setInterval(() => {
      this.translate.get('ALERTS').subscribe( (res: any) => {
        this.alertMessage = res;
        // this.getDesign();
        clearInterval(interval);
      });
    }, 3000);
  }

  ngOnInit() {}


  dismiss(status) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.slug.goback();
    this.modalController.dismiss({
      status: status
    });
  }

  enterPage(){
    if (this.entered_password === this.page_password){
      this.toast.presentToastWithOptions("Success", "", "success");
      this.modalController.dismiss({
        status: true
      });
    } else {
      this.toast.presentToastWithOptions("Wrong Password", "Error", "warning");
    }
  }

}

