import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EventsService {

    _headers: any;
    key : any = "AIzaSyDSu35Uz1W7h5oYIvB4M3LV0pIkOUoh_bo";

    constructor(
        private http: HttpClient
    ) {
        this._headers = new HttpHeaders();
        this._headers = this._headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    }

    getGoogleEvents(body) {
        let api = environment.api_end_point + '/event/eventgoogle';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    oneEvent(body) {
        let api = environment.api_end_point + '/event/oneevent';
        return this.http.post(api, body, {headers: this._headers});
    }

    getEvents(url, page_no?) {
        let external_link = url.includes('webcal://') ? url.replace('webcal://', 'http://'): url;
        let api = environment.api_end_point + '/event/externalLinkData';
        let body = {
            url: external_link,
            method: 'getEvents',
            page_no: page_no
        };
        let _body = JSON.stringify(body);
        return this.http.post(api, JSON.parse(_body), {headers: this._headers});
        // return this.http.get(url, {responseType: 'text'});
    }

    getDashboardEvent(body) {
        let api = environment.api_end_point + '/event/externaldata';
        return this.http.post(api, JSON.parse(body), {headers: this._headers});
    }

    getEventsFromChurchId(body) {
        let api = environment.api_end_point + '/event/churchevents/' + body;
        return this.http.get(api);
    }

    getMapLocation(street_address, postal_code) {
        let api = "https://maps.googleapis.com/maps/api/geocode/json?address=" + street_address +', '+ postal_code  + "&key=" + this.key;
        return this.http.get(api);
    }

    getCMSEvents(body) {
        let api = environment.api_end_point + '/Contentcms/getEvents';
        return this.http.post(api, body, {headers: this._headers});
    }

    getCMSEvent(body) {
        let api = environment.api_end_point + '/Contentcms/event';
        return this.http.post(api, body, {headers: this._headers});
    }
}
