import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogoServiceService {

  logo: boolean = true;

  constructor() { }

  setStatus(value){
    this.logo = value;
  }

  getStatus(){
    return this.logo;
  }

}
