import { Component, OnInit } from '@angular/core';
import {Storage} from '@ionic/storage';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent implements OnInit {

  constructor(
      private storage: Storage
  ) {
      this.isLoggedIn();
  }

  guest: any = false;

  ngOnInit() {}

  isLoggedIn(){
      this.storage.get('user').then((val) => {
          if (val.user === 'guest'){
              this.guest = true;
          }
      });
  }

}
